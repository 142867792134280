import { track, init, setUserId as amplitudeSetUserId } from "@amplitude/analytics-browser"
import { getMetaContent, getMetaContentMaybe } from "./get-meta-content"
import logger from "./logger"

const RAILS_ENV = getMetaContent("RAILS_ENV")
const AMPLITUDE_API_KEY = getMetaContentMaybe("AMPLITUDE_API_KEY")

export const EVENT_IDS = {
  SHARE_LOGO: "share_screen_logo",
  SHARE_SCREEN_SKIP_WAITLIST: "share_screen_skip_waitlist",
  GET_APP_CLICKED: "get_app_clicked",
  DOWNLOAD_APP_STORE_CLICKED: "download_app_store_clicked",
  DOWNLOAD_PLAY_STORE_CLICKED: "download_play_store_clicked",
} as const

export const EVENTS = {
  PHONE_NUMBER_ENTERED: "phone_number_entered",
  CONSENT_GIVEN: "consent_given",
  ITEM_PRESSED: "web_item_pressed",
  SCREEN_VIEWED: "web_screen_viewed",
} as const

export enum SCREENS {
  Landing = "landing",
  Share = "share",
  Download = "download",
}

const BLOCKLIST_PATHS = ["/admin/", "/auth/"]

// Initialize Amplitude with your API key
export const initializeAnalytics = (): void => {
  if (!AMPLITUDE_API_KEY) {
    logger.warn("Amplitude API key not found")
    return
  }

  try {
    init(AMPLITUDE_API_KEY, undefined, {
      logLevel: RAILS_ENV === "production" ? 0 : 2,
      autocapture: {
        sessions: true,
        pageViews: {
          trackOn: () => {
            return !BLOCKLIST_PATHS.some((path) => window.location.pathname.startsWith(path))
          },
        },
        formInteractions: true,
        fileDownloads: true,
      },
    })
  } catch (error) {
    logger.error("Error initializing Amplitude:", error)
  }
}

export const trackEvent = (eventName: string, eventProperties?: Record<string, unknown>): void => {
  if (!AMPLITUDE_API_KEY && RAILS_ENV !== "development") {
    logger.info("Amplitude API key not found; skipping event tracking")
    return
  }

  try {
    const properties = { location: window.location.pathname, ...eventProperties }
    logger.log("Tracking event:", eventName, properties)
    track(eventName, properties)
  } catch (error) {
    logger.error("Error tracking event:", error)
  }
}

// Set a stable user ID for analytics tracking
export const setAnalyticsUserId = (userId: string): void => {
  logger.log("Setting user ID:", userId)

  if (!AMPLITUDE_API_KEY && RAILS_ENV !== "development") {
    logger.info("Amplitude API key not found; skipping user identification")
    return
  }

  try {
    amplitudeSetUserId(userId)
  } catch (error) {
    logger.error("Error setting user ID:", error)
  }
}
