import { Outlet, RouteObject, createBrowserRouter } from "react-router-dom"
import { AdminAdminUsersScreen } from "~/admin/admin-admin-users-screen"
import { AdminDashboardScreen } from "~/admin/admin-dashboard-screen"
import { AdminEditRoomScreen } from "~/admin/admin-edit-room-screen/admin-edit-room-screen"
import { AdminNewUserAdminScreen } from "~/admin/admin-new-admin-user-screen"
import { AdminRoomScreen } from "~/admin/admin-room-screen/admin-room-screen"
import { AdminRoomsScreen } from "~/admin/admin-rooms-screen"
import { AdminUserDetailScreen } from "~/admin/admin-user-detail-screen"
import { AdminUsersScreen } from "~/admin/admin-users-screen"
import {
  RequireSystemAdminSignedIn,
  RequireUserSignedIn,
  RequireUserSignedOut,
} from "~/auth/auth-layouts"
import { LoginLayout } from "~/auth/login-layout"
import {
  adminAdminUsersPath,
  adminDashboardPath,
  adminEditRoomPath,
  adminLoginPath,
  adminModerationPath,
  adminNewAdminUserPath,
  adminRoomPath,
  adminRoomsPath,
  adminUserDetailPath,
  adminUsersPath,
  comingSoonPath,
  downloadPath,
  emailAuthPath,
  invitePath,
  landingPath,
  privacyPath,
  profilePath,
  rootPath,
  shareRoomCardPath,
  shareRoomPath,
  supportPath,
  termsPath,
} from "~/common/paths"
import { ComingSoonScreen } from "~/landing/coming-soon-screen"
import { LandingScreen } from "~/landing/landing-screen"
import { PrivacyScreen } from "~/landing/privacy-screen"
import { SupportScreen } from "~/landing/support-screen"
import { TermsScreen } from "~/landing/terms-screen"
import { SidebarLayout } from "~/layouts/sidebar-layout"
import { EmailAuthScreen } from "~/login/email-auth-screen"
import { EmailLoginScreen } from "~/login/email-login-screen"
import { ProfileScreen } from "~/profile/profile-screen"
import { InviteScreen } from "~/share/invite-screen"
import { ShareRoomCardScreen } from "~/share/room-card-screen"
import { ShareRoomScreen } from "~/share/room-screen"
import { DownloadScreen } from "../landing/download-screen"
import { ErrorBoundary } from "../ui/error-boundary"
import { RootLayout } from "./root-layout"
import { AdminModerationScreen } from "~/admin/admin-moderation-screen/admin-moderation-screen"

const systemAdminAuthenticatedRoutes: Array<RouteObject> = [
  {
    path: adminDashboardPath.pattern,
    element: <AdminDashboardScreen />,
  },
  {
    path: adminAdminUsersPath.pattern,
    element: <AdminAdminUsersScreen />,
  },
  {
    path: adminModerationPath.pattern,
    element: <AdminModerationScreen />,
  },
  {
    path: adminNewAdminUserPath.pattern,
    element: <AdminNewUserAdminScreen />,
  },
  {
    path: adminUsersPath.pattern,
    element: <AdminUsersScreen />,
  },
  {
    path: adminUserDetailPath.pattern,
    element: <AdminUserDetailScreen />,
  },
  {
    path: adminRoomsPath.pattern,
    element: <AdminRoomsScreen />,
  },
  {
    path: adminRoomPath.pattern,
    element: <AdminRoomScreen />,
  },
  {
    path: adminEditRoomPath.pattern,
    element: <AdminEditRoomScreen />,
  },
]

const authenticatedRoutes: Array<RouteObject> = [
  {
    path: profilePath.pattern,
    element: <ProfileScreen />,
  },
]

export const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        element: <RequireSystemAdminSignedIn />,
        children: [
          {
            element: <SidebarLayout />,
            children: [
              {
                element: <Outlet />,
                children: [...systemAdminAuthenticatedRoutes],
              },
            ],
          },
        ],
      },
      {
        element: <RequireUserSignedIn />,
        children: [
          {
            element: <SidebarLayout />,
            children: [
              {
                element: <Outlet />,
                children: [...authenticatedRoutes],
              },
            ],
          },
        ],
      },
      {
        element: <RequireUserSignedOut />,
        children: [
          {
            element: <LoginLayout />,
            children: [
              {
                path: adminLoginPath.pattern,
                element: <EmailLoginScreen />,
              },
              // {
              //   path: adminCredentialsLoginPath.pattern,
              //   element: <CredentialsLoginScreen />,
              // },
              {
                path: emailAuthPath.pattern,
                element: <EmailAuthScreen />,
              },
            ],
          },
        ],
      },
      {
        path: downloadPath.pattern,
        element: <DownloadScreen />,
      },
      {
        path: landingPath.pattern,
        element: <LandingScreen />,
      },
      {
        path: invitePath.pattern,
        element: <InviteScreen />,
      },
      {
        path: shareRoomPath.pattern,
        element: <ShareRoomScreen />,
      },
      {
        path: shareRoomCardPath.pattern,
        element: <ShareRoomCardScreen />,
      },
      {
        path: comingSoonPath.pattern,
        element: <ComingSoonScreen />,
      },
      {
        path: privacyPath.pattern,
        element: <PrivacyScreen />,
      },
      {
        path: termsPath.pattern,
        element: <TermsScreen />,
      },
      {
        path: supportPath.pattern,
        element: <SupportScreen />,
      },
      {
        path: rootPath.pattern,
        element: <LandingScreen />,
      },
    ],
  },
])
