import { useState } from "react"
import { gql } from "~/__generated__"
import { BaseRoomWaitlistCodeFragment } from "~/__generated__/graphql"
import { formatDateLong } from "~/common/dates"
import { AppText } from "~/ui/app-text"
import { Button } from "~/ui/button"
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "~/ui/dialog"
import { TableContainer } from "~/ui/table"
import { AddRoomWaitlistCodeForm } from "./add-room-waitlist-code-form"
import { EditRoomWaitlistCodeForm } from "./edit-room-waitlist-code-form"

export const RoomWaitlistCodesList = ({
  roomId,
  waitlistCodes,
  hasNextPage,
  loading,
  onLoadMore,
  onRefresh,
}: {
  roomId: string
  waitlistCodes: BaseRoomWaitlistCodeFragment[]
  hasNextPage: boolean
  loading: boolean
  onLoadMore: () => void
  onRefresh: () => void
}) => {
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false)
  const [editingCode, setEditingCode] = useState<BaseRoomWaitlistCodeFragment | null>(null)

  const handleAddSuccess = () => {
    setIsAddDialogOpen(false)
    // Force refresh from server
    onRefresh()
  }

  const handleEditSuccess = () => {
    setEditingCode(null)
    // Force refresh from server
    onRefresh()
  }

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between">
        <AppText variant="h2">Waitlist Codes</AppText>
        <Button onClick={() => setIsAddDialogOpen(true)}>Add Code</Button>
      </div>

      {waitlistCodes.length === 0 ? (
        <AppText variant="body2">No waitlist codes found</AppText>
      ) : (
        <>
          <TableContainer>
            <table className="table">
              <thead>
                <tr>
                  <th align="left">Code</th>
                  <th align="left">Created</th>
                  <th align="left">Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {waitlistCodes.map((code) => (
                  <tr key={code.id}>
                    <td>
                      <AppText variant="body3">{code.code}</AppText>
                    </td>
                    <td>
                      <AppText variant="body3">{formatDateLong(code.createdAt)}</AppText>
                    </td>
                    <td>
                      <AppText
                        variant="body3"
                        className={!code.archived ? "text-support-green" : "text-neutral-500"}
                      >
                        {!code.archived ? "Active" : "Archived"}
                      </AppText>
                    </td>
                    <td align="right">
                      <div className="flex justify-end space-x-2">
                        <Button variant="outline" size="sm" onClick={() => setEditingCode(code)}>
                          Edit
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </TableContainer>

          {hasNextPage && (
            <div className="mt-4 flex items-center justify-center">
              <Button onClick={onLoadMore} variant="ghost" disabled={loading}>
                View More
              </Button>
            </div>
          )}
        </>
      )}

      {/* Add Dialog */}
      <Dialog open={isAddDialogOpen} onOpenChange={setIsAddDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Add Waitlist Code</DialogTitle>
          </DialogHeader>
          <AddRoomWaitlistCodeForm
            roomId={roomId}
            onSuccess={handleAddSuccess}
            onCancel={() => setIsAddDialogOpen(false)}
          />
        </DialogContent>
      </Dialog>

      {/* Edit Dialog */}
      <Dialog open={!!editingCode} onOpenChange={(open) => !open && setEditingCode(null)}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Edit Waitlist Code</DialogTitle>
          </DialogHeader>
          {editingCode && (
            <EditRoomWaitlistCodeForm
              waitlistCode={editingCode}
              onSuccess={handleEditSuccess}
              onCancel={() => setEditingCode(null)}
            />
          )}
        </DialogContent>
      </Dialog>
    </div>
  )
}

export const BASEROOM_WAITLIST_CODE_FRAGMENT = gql(`
  fragment BaseRoomWaitlistCode on RoomWaitlistCode {
    id
    code
    archived
    createdAt
  }
`)
