import { Control, FieldPath, FieldValues } from "react-hook-form"
import { cn } from "~/common/cn"
import { Combobox, OptionType } from "~/ui/combobox"
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "~/ui/form"

export const SelectField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  label,
  labelClassName,
  placeholder = "Select an option",
  options = [],
  className,
  search,
}: {
  label?: string
  labelClassName?: string
  control: Control<TFieldValues> | undefined
  name: TName
  placeholder?: string
  options: OptionType[]
  className?: string
  search?: boolean
}) => {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem className={className}>
          {!!label && <FormLabel className={cn(labelClassName)}>{label}</FormLabel>}
          <div className="flex">
            <FormControl>
              <Combobox
                options={options}
                value={field.value}
                onChange={field.onChange}
                placeholder={placeholder}
                search={search}
                data-test={`${name}-field`}
              />
            </FormControl>
          </div>
          <FormMessage className="text-xs" />
        </FormItem>
      )}
    />
  )
}
