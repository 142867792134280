import React, { createContext, useContext, useEffect } from "react"
import { useViewerMaybe } from "~/auth/use-viewer"
import { initializeAnalytics, setAnalyticsUserId } from "./analytics"
import { getAnonymousId } from "./anonymous-id"

interface AnalyticsContextType {
  isInitialized: boolean
}

const AnalyticsContext = createContext<AnalyticsContextType | null>(null)

export const AnalyticsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { viewer } = useViewerMaybe()

  useEffect(() => {
    const init = async () => {
      initializeAnalytics()
      // Set anonymous ID by default
      const anonymousId = await getAnonymousId()
      setAnalyticsUserId(anonymousId)
    }

    init().catch((error) => {
      console.error("Failed to initialize analytics:", error)
    })
  }, [])

  useEffect(() => {
    const updateUserId = async () => {
      if (viewer?.id) {
        // Set user ID by default
        setAnalyticsUserId(viewer.id)
      } else {
        // Eventually, don't set user ID, and pass anonymous ID so mobile app can set user ID
        const anonymousId = await getAnonymousId()
        setAnalyticsUserId(anonymousId)
      }
    }

    updateUserId().catch((error) => {
      console.error("Failed to update user ID:", error)
    })
  }, [viewer?.id])

  return (
    <AnalyticsContext.Provider value={{ isInitialized: true }}>
      {children}
    </AnalyticsContext.Provider>
  )
}

export const useAnalytics = () => {
  const context = useContext(AnalyticsContext)
  if (!context) {
    throw new Error("useAnalytics must be used within an AnalyticsProvider")
  }
  return context
}
