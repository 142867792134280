import { useEffect } from "react"

const defaultTitle = "ChatBCC: A front row seat to epic group chats"
const defaultDescription =
  "Amazing people having real conversations about topics you love. Follow your favorites or start your own."

export const useDocumentTitleDescription = (
  title: string | null = defaultTitle,
  description: string | null = defaultDescription
) => {
  useEffect(() => {
    if (title != null) {
      document.title = title
    }
    if (description != null) {
      document.querySelector("meta[name='description']")?.setAttribute("content", description)
    }
  }, [title, description])
}
