export type ImageSize = { height: number; width: number }

export const constrainImageSize = (imageSize: ImageSize, constraint: ImageSize): ImageSize => {
  const aspectRatio = imageSize.width / imageSize.height

  if (imageSize.width <= constraint.width && imageSize.height <= constraint.height) {
    return imageSize
  }

  if (imageSize.width > constraint.width) {
    imageSize.width = constraint.width
    imageSize.height = imageSize.width / aspectRatio
  }

  if (imageSize.height > constraint.height) {
    imageSize.height = constraint.height
    imageSize.width = imageSize.height * aspectRatio
  }

  return imageSize
}
