import { useQuery } from "@apollo/client"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { gql } from "~/__generated__"
import { UserStatus } from "~/__generated__/graphql"
import { formatDateLong } from "~/common/dates"
import { adminUsersPath } from "~/common/paths"
import { useSafeMutation } from "~/common/use-safe-mutation"
import { AppText } from "~/ui/app-text"
import { Button } from "~/ui/button"
import { GraphqlError } from "~/ui/errors"
import { Label } from "~/ui/label"
import { Switch } from "~/ui/switch"
import { useToast } from "~/ui/use-toast"

const ADMIN_USER_QUERY = gql(/* GraphQL */ `
  query AdminUserDetail($id: ID!) {
    user(id: $id) {
      id
      username
      displayName
      email
      phoneNumber
      userStatus
      userRole
      createdAt
      verifiedAccount
      waitlisted
      showFullName
      bio
      webUrl
      activeFollowerCount
      activeFollowingCount
      activeRoomFollowsCount
    }
  }
`)

const UPDATE_USER_FIELDS = gql(/* GraphQL */ `
  mutation UpdateUserFields(
    $userId: ID!
    $verifiedAccount: Boolean
    $waitlisted: Boolean
    $showFullName: Boolean
  ) {
    adminUserUpdate(
      input: {
        id: $userId
        userInput: {
          verifiedAccount: $verifiedAccount
          waitlisted: $waitlisted
          showFullName: $showFullName
        }
      }
    ) {
      user {
        id
        verifiedAccount
        waitlisted
        showFullName
      }
    }
  }
`)

const UPDATE_USER_STATUS = gql(/* GraphQL */ `
  mutation UpdateUserStatus($userId: ID!, $userStatus: UserStatus!) {
    adminUserUpdate(input: { id: $userId, userInput: { userStatus: $userStatus } }) {
      user {
        id
        userStatus
      }
    }
  }
`)

export const AdminUserDetailScreen = (): JSX.Element => {
  const { id } = useParams<{ id: string }>()
  const navigate = useNavigate()
  const { toast } = useToast()

  const [formValues, setFormValues] = useState({
    verifiedAccount: false,
    waitlisted: false,
    showFullName: false,
  })

  const [isSaving, setIsSaving] = useState(false)
  const [isBlocking, setIsBlocking] = useState(false)

  const { data, loading, error, refetch } = useQuery(ADMIN_USER_QUERY, {
    variables: { id: id || "" },
    skip: !id,
  })

  const [updateUserFields] = useSafeMutation(UPDATE_USER_FIELDS)
  const [updateUserStatus] = useSafeMutation(UPDATE_USER_STATUS)

  useEffect(() => {
    if (data?.user) {
      setFormValues({
        verifiedAccount: data.user.verifiedAccount,
        waitlisted: data.user.waitlisted,
        showFullName: data.user.showFullName,
      })
    }
  }, [data])

  const handleInputChange = (field: string, value: boolean): void => {
    setFormValues((prev) => ({
      ...prev,
      [field]: value,
    }))
  }

  const handleSave = async (): Promise<void> => {
    if (!id) return

    setIsSaving(true)

    try {
      const result = await updateUserFields({
        variables: {
          userId: id,
          verifiedAccount: formValues.verifiedAccount,
          waitlisted: formValues.waitlisted,
          showFullName: formValues.showFullName,
        },
      })

      if (result.errors) {
        toast({
          title: "Error updating user",
          description: "Please try again",
          variant: "destructive",
        })
      } else {
        toast({
          title: "User updated successfully",
          variant: "default",
        })

        await refetch()
      }
    } catch (err) {
      console.error("Failed to update user:", err)
      toast({
        title: "Error updating user",
        description: "An unexpected error occurred",
        variant: "destructive",
      })
    } finally {
      setIsSaving(false)
    }
  }

  const handleBlockUser = async (): Promise<void> => {
    if (!id) return

    const confirmed = window.confirm(
      "Are you sure you want to block this user? This will prevent them from using the platform."
    )
    if (!confirmed) return

    setIsBlocking(true)

    try {
      const result = await updateUserStatus({
        variables: {
          userId: id,
          userStatus: UserStatus.Blocked,
        },
      })

      if (result.errors) {
        toast({
          title: "Error blocking user",
          description: "Please try again",
          variant: "destructive",
        })
      } else {
        toast({
          title: "User blocked successfully",
          variant: "default",
        })

        await refetch()
      }
    } catch (err) {
      console.error("Failed to block user:", err)
      toast({
        title: "Error blocking user",
        description: "An unexpected error occurred",
        variant: "destructive",
      })
    } finally {
      setIsBlocking(false)
    }
  }

  const handleUnblockUser = async (): Promise<void> => {
    if (!id) return

    const confirmed = window.confirm(
      "Are you sure you want to unblock this user? This will allow them to use the platform again."
    )
    if (!confirmed) return

    setIsBlocking(true)

    try {
      const result = await updateUserStatus({
        variables: {
          userId: id,
          userStatus: UserStatus.Active,
        },
      })

      if (result.errors) {
        toast({
          title: "Error unblocking user",
          description: "Please try again",
          variant: "destructive",
        })
      } else {
        toast({
          title: "User unblocked successfully",
          variant: "default",
        })

        await refetch()
      }
    } catch (err) {
      console.error("Failed to unblock user:", err)
      toast({
        title: "Error unblocking user",
        description: "An unexpected error occurred",
        variant: "destructive",
      })
    } finally {
      setIsBlocking(false)
    }
  }

  if (loading) {
    return <div>Loading...</div>
  }

  if (error || !data?.user) {
    return <GraphqlError error={error} />
  }

  const user = data.user

  return (
    <div className="max-w-3xl">
      <div className="mb-6 flex items-center justify-between">
        <AppText variant="h1">User Details: {user.displayName || user.username}</AppText>
        <Button variant="outline" onClick={() => navigate(adminUsersPath({}))}>
          Back to Users
        </Button>
      </div>

      <div className="mb-8 rounded-lg border border-gray-200 bg-white p-6 shadow-sm">
        <div className="mb-4 grid grid-cols-2 gap-4">
          <div>
            <AppText variant="h3" className="mb-1">
              Basic Information
            </AppText>
            <div className="mb-2">
              <AppText variant="body2" className="text-gray-500">
                ID:
              </AppText>
              <AppText variant="body2">{user.id}</AppText>
            </div>
            <div className="mb-2">
              <AppText variant="body2" className="text-gray-500">
                Username:
              </AppText>
              <AppText variant="body2">{user.username || "None"}</AppText>
            </div>
            <div className="mb-2">
              <AppText variant="body2" className="text-gray-500">
                Display Name:
              </AppText>
              <AppText variant="body2">{user.displayName || "None"}</AppText>
            </div>
            <div className="mb-2">
              <AppText variant="body2" className="text-gray-500">
                Email:
              </AppText>
              <AppText variant="body2">{user.email || "None"}</AppText>
            </div>
            <div className="mb-2">
              <AppText variant="body2" className="text-gray-500">
                Phone Number:
              </AppText>
              <AppText variant="body2">{user.phoneNumber || "None"}</AppText>
            </div>
            <div className="mb-2">
              <AppText variant="body2" className="text-gray-500">
                Status:
              </AppText>
              <AppText variant="body2">{user.userStatus.toLowerCase()}</AppText>
            </div>
            <div className="mb-2">
              <AppText variant="body2" className="text-gray-500">
                Role:
              </AppText>
              <AppText variant="body2">{user.userRole?.toLowerCase() || "none"}</AppText>
            </div>
          </div>
          <div>
            <AppText variant="h3" className="mb-1">
              Dates & Stats
            </AppText>
            <div className="mb-2">
              <AppText variant="body2" className="text-gray-500">
                Created:
              </AppText>
              <AppText variant="body2">{formatDateLong(user.createdAt)}</AppText>
            </div>
            <div className="mb-2">
              <AppText variant="body2" className="text-gray-500">
                Followers:
              </AppText>
              <AppText variant="body2">{user.activeFollowerCount}</AppText>
            </div>
            <div className="mb-2">
              <AppText variant="body2" className="text-gray-500">
                Following:
              </AppText>
              <AppText variant="body2">{user.activeFollowingCount}</AppText>
            </div>
            <div className="mb-2">
              <AppText variant="body2" className="text-gray-500">
                Followed Rooms:
              </AppText>
              <AppText variant="body2">{user.activeRoomFollowsCount}</AppText>
            </div>
            {user.webUrl && (
              <div className="mb-2">
                <AppText variant="body2" className="text-gray-500">
                  Web URL:
                </AppText>
                <AppText variant="body2">
                  <a
                    href={user.webUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 hover:underline"
                  >
                    {user.webUrl}
                  </a>
                </AppText>
              </div>
            )}
          </div>
        </div>

        {user.bio && (
          <div className="mb-4">
            <AppText variant="h3" className="mb-1">
              Bio
            </AppText>
            <div className="rounded-md border border-gray-200 bg-gray-50 p-3">
              <AppText variant="body2">{user.bio}</AppText>
            </div>
          </div>
        )}

        <div className="mt-8">
          <AppText variant="h3" className="mb-4">
            User Settings
          </AppText>

          <div className="mb-6 grid grid-cols-2 gap-6">
            <div className="flex items-center space-x-2">
              <Switch
                id="verifiedAccount"
                checked={formValues.verifiedAccount}
                onCheckedChange={(checked) => handleInputChange("verifiedAccount", checked)}
              />
              <Label htmlFor="verifiedAccount">Verified Account</Label>
            </div>

            <div className="flex items-center space-x-2">
              <Switch
                id="waitlisted"
                checked={formValues.waitlisted}
                onCheckedChange={(checked) => handleInputChange("waitlisted", checked)}
              />
              <Label htmlFor="waitlisted">Waitlisted</Label>
            </div>

            <div className="flex items-center space-x-2">
              <Switch
                id="showFullName"
                checked={formValues.showFullName}
                onCheckedChange={(checked) => handleInputChange("showFullName", checked)}
              />
              <Label htmlFor="showFullName">Show Full Name</Label>
            </div>
          </div>

          <div className="mt-6 flex justify-end">
            <Button onClick={handleSave} disabled={isSaving}>
              {isSaving ? "Saving..." : "Save Changes"}
            </Button>
          </div>
        </div>

        {user.userStatus !== UserStatus.Blocked ? (
          <div className="mt-8 border-t border-gray-200 pt-6">
            <AppText variant="h3" className="mb-4 text-red-600">
              Danger Zone
            </AppText>
            <Button variant="destructive" onClick={handleBlockUser} disabled={isBlocking}>
              {isBlocking ? "Blocking..." : "Block User"}
            </Button>
          </div>
        ) : (
          <div className="mt-8 border-t border-gray-200 pt-6">
            <AppText variant="h3" className="mb-4">
              User Status
            </AppText>
            <Button variant="outline" onClick={handleUnblockUser} disabled={isBlocking}>
              {isBlocking ? "Unblocking..." : "Unblock User"}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}
