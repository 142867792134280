import { Link, NavLink, Outlet, ScrollRestoration } from "react-router-dom"
import { ModerationResult } from "~/__generated__/graphql"
import { useLogout } from "~/auth/use-logout"
import { useViewer, useViewerMaybe } from "~/auth/use-viewer"
import { UserAvatarWithFallback } from "~/common/avatar-with-fallback"
import { cn } from "~/common/cn"
import {
  adminAdminUsersPath,
  adminDashboardPath,
  adminModerationPath,
  adminRoomsPath,
  adminUsersPath,
} from "~/common/paths"
import bubble from "~/images/bubble"
import home from "~/images/home"
import logoSm from "~/images/logo-sm"
import logoutIcon from "~/images/logout-icon"
import people from "~/images/people"
import userSquare from "~/images/user-square"
import { AppText } from "~/ui/app-text"

const sidebarItemStyles =
  "flex px-5 py-2 hover:bg-gray-100 text-sm font-medium text-gray-400 hover:text-gray-900 cursor-pointer"

const SIDEBAR_WIDTH = 200

const SidebarLink = ({
  text,
  to,
  icon,
  end,
}: {
  text: string
  to: string
  icon: React.ReactNode
  end?: boolean
}) => {
  return (
    <li className="">
      <NavLink
        to={to}
        className={({ isActive }) => {
          return cn(sidebarItemStyles, "flex", { "opacity-50": !isActive })
        }}
        end={end}
      >
        {icon}
        <AppText variant="h3" className="pl-4 uppercase">
          {text}
        </AppText>
      </NavLink>
    </li>
  )
}

export const SidebarLinks = () => {
  const { viewer } = useViewer()

  return (
    <nav className="mt-4">
      <ul className="list-none flex-col divide-y divide-gray-200 border-b border-t border-gray-200">
        {viewer.isAdmin && (
          <>
            <SidebarLink
              text="Home"
              to={adminDashboardPath({})}
              icon={<img {...home} />}
              end={true}
            />
            <SidebarLink
              text="Admin Users"
              to={adminAdminUsersPath({})}
              icon={<img {...userSquare} />}
            />
            <SidebarLink text="Users" to={adminUsersPath({})} icon={<img {...people} />} />
            <SidebarLink text="Chats" to={adminRoomsPath({})} icon={<img {...bubble} />} />
            <SidebarLink
              text="Moderation"
              to={adminModerationPath({ moderationResult: ModerationResult.Flagged })}
              icon={<img {...bubble} />}
            />
          </>
        )}
      </ul>
    </nav>
  )
}

export const SidebarLayout = () => {
  const { viewer } = useViewerMaybe()
  const logout = useLogout()

  if (!viewer) {
    return null
  }

  return (
    <div className="flex min-h-screen flex-1 grow">
      <div
        className="fixed top-0 flex h-screen flex-col justify-between gap-2 overflow-auto bg-white shadow-lg"
        style={{ width: SIDEBAR_WIDTH }}
      >
        <div>
          <div className="flex justify-center">
            <Link to={adminDashboardPath({})} className="flex pt-6">
              <img {...logoSm} alt="ChatBCC" />
            </Link>
          </div>

          <SidebarLinks />
        </div>
        <div>
          <div className="flex items-center gap-2 p-4 py-6">
            <UserAvatarWithFallback user={viewer} size="menu" />
            <AppText variant="body3-medium">{viewer.displayOrUsername}</AppText>
          </div>
          <div className={cn("border-t border-gray-200", sidebarItemStyles)}>
            <button onClick={logout} className="flex w-full items-center">
              <img {...logoutIcon} alt="" />
              <AppText variant="h3" className="pl-4 uppercase opacity-50">
                Sign Out
              </AppText>
            </button>
          </div>
        </div>
      </div>

      <div
        className="w-full"
        style={{
          marginLeft: SIDEBAR_WIDTH,
        }}
      >
        <div className="mx-auto flex max-w-screen-xl flex-1 flex-col overflow-hidden px-10 py-14">
          <Outlet />
          <ScrollRestoration />
        </div>
      </div>
    </div>
  )
}
