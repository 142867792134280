// chatbcc-api/app/frontend/fields/textarea-field.tsx
import { Control, FieldPath, FieldValues } from "react-hook-form"
import { cn } from "~/common/cn"
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "~/ui/form"
import { Textarea, TextareaProps } from "../ui/input"

export const TextareaField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  label,
  labelClassName,
  ...props
}: {
  label?: string
  labelClassName?: string
  control: Control<TFieldValues> | undefined
  name: TName
} & TextareaProps) => {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem>
          {!!label && <FormLabel className={cn(labelClassName)}>{label}</FormLabel>}
          <FormControl>
            <Textarea {...field} {...props} />
          </FormControl>
          <FormMessage className="text-xs" />
        </FormItem>
      )}
    />
  )
}
