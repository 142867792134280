import { useRef, useState } from "react"
import { Control, Controller, FieldPath, FieldValues } from "react-hook-form"
import useFileUpload from "~/hooks/use-file-upload"
import { Button } from "~/ui/button"
import { useToast } from "~/ui/use-toast"

const formatAcceptedTypes = (acceptString: string): string => {
  const types = acceptString.split(",").map((type) => {
    type = type.trim()
    if (type === "image/*") return "images"
    if (type === "video/*") return "videos"
    if (type === "audio/*") return "audio files"
    if (type.startsWith(".")) return type
    return type
  })

  return types.join(", ")
}

interface FileUploadFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> {
  name: TName
  control: Control<TFieldValues>
  buttonText?: string
  accept?: string
}

export const FileUploadField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  name,
  control,
  buttonText,
  accept,
}: FileUploadFieldProps<TFieldValues, TName>) => {
  const hiddenFileInput = useRef<HTMLInputElement>(null)
  const { toast } = useToast()
  const { uploadFile, loading } = useFileUpload()
  const [filename, setFilename] = useState("")

  const openUploadDialog = () => hiddenFileInput?.current?.click()

  const handleFileUpload = async (file: File, onChange: (value: string) => void) => {
    if (!file) {
      toast({
        title: "Missing file, please try again",
        variant: "destructive",
      })
      return
    }

    // Check if the file type is accepted
    if (accept) {
      const acceptedTypes = accept.split(",").map((type) => type.trim())
      const fileType = file.type
      const fileExtension = `.${file.name.split(".").pop()?.toLowerCase()}`

      const isAccepted = acceptedTypes.some((type) => {
        // Handle mime types (e.g., image/*)
        if (type.includes("*")) {
          const typePrefix = type.split("*")[0]
          return fileType.startsWith(typePrefix)
        }
        // Handle specific extensions (e.g., .jpg)
        if (type.startsWith(".")) {
          return fileExtension === type.toLowerCase()
        }
        // Handle specific mime types (e.g., image/jpeg)
        return fileType === type
      })

      if (!isAccepted) {
        toast({
          title: `File type not accepted. Please upload ${formatAcceptedTypes(accept)}.`,
          variant: "destructive",
        })
        return
      }

      try {
        const response = await uploadFile({ file, filename: file.name, contentType: file.type })
        if (response.uploadPresign.signedId) {
          onChange(response.uploadPresign.signedId)
        }
        setFilename(file.name)
      } catch (error) {
        console.error(error)

        toast({
          title: "File upload failed, please try again",
          variant: "destructive",
        })
      }
    }
  }

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange } }) => (
        <div className="rounded-md">
          <div className="flex items-center gap-4">
            <Button onClick={openUploadDialog} type="button" variant="outline" disabled={loading}>
              {loading ? "Uploading..." : buttonText || "Upload"}
            </Button>
            {filename}
          </div>

          <input
            type="file"
            ref={hiddenFileInput}
            onChange={(e) => {
              const file = e?.target?.files?.[0]
              if (file) {
                handleFileUpload(file, onChange)
              }
            }}
            className="hidden"
          />

          {accept && (
            <div className="text-xs my-2 text-gray-500">
              Accepted file types: {formatAcceptedTypes(accept)}
            </div>
          )}
        </div>
      )}
    />
  )
}
