import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { gql } from "~/__generated__"
import { useFormErrors } from "~/common/use-form-errors"
import { useSafeMutation } from "~/common/use-safe-mutation"
import { Button } from "~/ui/button"
import { Form } from "~/ui/form"
import { FormActions } from "~/ui/form-actions"
import { useToast } from "~/ui/use-toast"
import {
  RoomWaitlistCodeFields,
  RoomWaitlistCodeFormValues,
  roomWaitlistCodeSchema,
} from "./room-waitlist-code-fields"

export const AddRoomWaitlistCodeForm = ({
  roomId,
  onSuccess,
  onCancel,
}: {
  roomId: string
  onSuccess: () => void
  onCancel: () => void
}) => {
  const { toast } = useToast()
  const [mutate, result] = useSafeMutation(ROOM_WAITLIST_CODE_CREATE_MUTATION)

  const form = useForm<RoomWaitlistCodeFormValues>({
    resolver: zodResolver(roomWaitlistCodeSchema),
    defaultValues: {
      code: "",
      archived: false,
    },
  })

  useFormErrors(form.setError, result)

  const onSubmit = async (values: RoomWaitlistCodeFormValues) => {
    const result = await mutate({
      variables: {
        input: {
          roomId,
          code: values.code,
        },
      },
    })

    if (result.errors) {
      return
    }

    toast({
      title: "Waitlist code created successfully",
      variant: "default",
    })

    form.reset()
    onSuccess()
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
        <RoomWaitlistCodeFields control={form.control} />
        <FormActions>
          <div className="flex justify-between">
            <Button type="button" variant="outline" onClick={onCancel}>
              Cancel
            </Button>
            <Button type="submit" disabled={form.formState.isSubmitting}>
              Create Code
            </Button>
          </div>
        </FormActions>
      </form>
    </Form>
  )
}

export const ROOM_WAITLIST_CODE_CREATE_MUTATION = gql(`
  mutation WaitlistCodeCreate($input: WaitlistCodeCreateInput!) {
    waitlistCodeCreate(input: $input) {
      roomWaitlistCode {
        ...BaseRoomWaitlistCode
      }
    }
  }
`)
