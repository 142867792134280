import "./landing.css"
import type { EmblaOptionsType } from "embla-carousel"
import useEmblaCarousel from "embla-carousel-react"
import logo from "~/images/landing/logo.ts"
import star from "~/images/landing/star.ts"
import bachelor from "~/images/landing/carousel/bachelor"
import gameday from "~/images/landing/carousel/gameday"
import marketMovers from "~/images/landing/carousel/market-movers"
import { Link } from "~/ui/link"
import { useMediaQuery } from "~/hooks/use-media-query"
import { AppText } from "~/ui/app-text"
import { useState, useRef } from "react"
import { trackEvent, EVENTS, SCREENS, EVENT_IDS } from "~/common/analytics"
import { PhoneNumberInput } from "~/ui/phone-number/phone-number-input"
import { PhoneNumberValue } from "~/common/phone-number"
import { useToast } from "~/ui/use-toast"
import { useSafeMutation } from "~/common/use-safe-mutation"
import { VerificationLink } from "~/__generated__/graphql"
import { useThemeColor } from "~/hooks/use-theme-color"
import {
  GENERATE_VERIFICATION_LINK_MUTATION,
  handlePhoneSubmission,
} from "~/common/phone-submission"
import { downloadPath } from "~/common/paths"
import { useScreenTracking } from "~/hooks/use-screen-tracking"

const FINE_PRINT_CLASSES = "text-[#141010]/70 text-sm leading-tight"

const TRACKING_DATA = {
  unique_id: EVENT_IDS.GET_APP_CLICKED,
}

const TermsText = ({ className }: { className?: string }) => (
  <AppText variant="caption" className={FINE_PRINT_CLASSES + " " + className}>
    By using ChatBCC, you agree to our{" "}
    <Link className="underline" to={"../terms"}>
      Terms of Use
    </Link>{" "}
    &{" "}
    <Link className="underline" to={"../privacy"}>
      Privacy Policy.
    </Link>
  </AppText>
)

const ConsentText = ({ className }: { className?: string }) => (
  <AppText variant="caption" className={FINE_PRINT_CLASSES + " " + className}>
    By clicking 'Get The App', you consent to receive text messages from ChatBCC. Text messages may
    be autodialed, and data rates may apply. You also agree to our{" "}
    <Link className="underline" to={"../terms"}>
      Terms of Use
    </Link>{" "}
    &{" "}
    <Link className="underline" to={"../privacy"}>
      Privacy Policy.
    </Link>
  </AppText>
)

interface ConsentCheckboxProps {
  checked: boolean
  onChange: (checked: boolean) => void
  className?: string
  highlight?: boolean
}

const ConsentCheckbox = ({ checked, onChange, className, highlight }: ConsentCheckboxProps) => (
  <div
    className={`flex items-start gap-3 rounded-lg bg-white/10 p-4 ${className} ${
      highlight ? "animate-pulse ring-2 ring-[#FD905B]" : ""
    }`}
  >
    <input
      type="checkbox"
      id="consent-checkbox"
      checked={checked}
      onChange={(e) => {
        onChange(e.target.checked)
        if (e.target.checked) {
          trackEvent(EVENTS.CONSENT_GIVEN)
        }
      }}
      className="mt-1 hidden h-4 w-4 rounded border-[#141010] bg-white"
      aria-label="Consent to receive messages"
    />
    <label htmlFor="consent-checkbox">
      <ConsentText className="max-sm:hidden" />
      <TermsText className="sm:hidden" />
    </label>
  </div>
)

export const LandingScreen = () => {
  useThemeColor("#2D92FF")
  useScreenTracking(SCREENS.Landing)

  const isLargeScreen = useMediaQuery("(min-width: 1024px)")
  const [phoneValue, setPhoneValue] = useState<PhoneNumberValue>({
    number: "",
    callingCode: "1",
    countryCode: "US",
    flag: "US",
  })
  const [hasConsented, setHasConsented] = useState(false)
  const [highlightConsent, setHighlightConsent] = useState(false)
  const { toast } = useToast()
  const [generateVerificationLink] = useSafeMutation(GENERATE_VERIFICATION_LINK_MUTATION)
  const phoneInputRef = useRef<HTMLInputElement>(null)

  const phoneInputEnabled = () => {
    if (!phoneInputRef.current) return false
    const isSmallScreen = window.matchMedia("(max-width: 640px)").matches
    if (isSmallScreen) return false
    return true
  }

  const options: EmblaOptionsType = {
    axis: isLargeScreen ? "y" : "x",
    loop: true,
    dragFree: false,
    skipSnaps: false,
    align: "center",
  }

  const [emblaRef] = useEmblaCarousel(options)

  const handlePhoneChange = (value: PhoneNumberValue) => {
    setPhoneValue(value)
    if (value.number.replace(/\D/g, "").length >= 10) {
      trackEvent(EVENTS.PHONE_NUMBER_ENTERED, {
        phone_number_last_4: value.number.replace(/\D/g, "").slice(-4),
      })
    }
  }

  const handleGetAppClick = async () => {
    if (!phoneInputEnabled()) {
      trackEvent(EVENTS.ITEM_PRESSED, {
        ...TRACKING_DATA,
        success: true,
      })
      window.location.href = downloadPath({})
      return
    }

    // eslint-disable-next-line
    if (!hasConsented && false) {
      //setHasConsented(true)
      setHighlightConsent(true)
      // Remove highlight after animation
      setTimeout(() => setHighlightConsent(false), 2000)
      return
    }

    await handlePhoneSubmission({
      phoneValue,
      setPhoneValue,
      verificationLinkType: VerificationLink.DownloadApp,
      trackingData: TRACKING_DATA,
      toast,
      generateVerificationLink,
      successMessage: "Texting you a link to download the app.",
    })
  }

  const carouselImageClasses = "lg:max-w-[400px] max-lg:mx-auto lg:ml-auto"

  return (
    <section className="LandingContent">
      <div
        className="relative flex min-h-screen overflow-visible bg-[#2D92FF]"
        data-testid="landing-screen"
      >
        {/* Main content */}
        <div className="mx-auto max-w-7xl px-6 py-3 lg:my-auto lg:py-16">
          {/* Decorative stars */}
          <div className="absolute left-[49%] top-[60%] max-sm:hidden">
            <img src={star.src} alt="" className="h-[37px] w-[39px] animate-pulse" />
          </div>
          <div className="absolute left-[79%] top-[40%] hidden">
            <img src={star.src} alt="" className="h-[37px] w-[39px] animate-pulse" />
          </div>

          {/* Content grid */}
          <div className="mx-auto grid max-w-[540px] grid-cols-1 gap-6 lg:max-w-[1080px] lg:grid-cols-2 lg:gap-16">
            {/* Left column */}
            <div className="space-y-4 lg:space-y-6">
              {/* Logo section */}
              <div className="mb-6 flex justify-center lg:mb-24 lg:justify-start">
                <img
                  src={logo.src}
                  alt="ChatBCC Logo"
                  width={logo.width}
                  height={logo.height}
                  className="h-[50px] w-[124px] lg:h-[69px] lg:w-[171px]"
                  loading="eager"
                />
              </div>

              <div className="space-y-3 lg:space-y-8">
                {/* Decorative stars */}
                <div className="absolute">
                  <div className="relative -right-[20px] -top-[60px]">
                    <img src={star.src} alt="" className="h-[37px] w-[39px] animate-pulse" />
                  </div>
                </div>
                <div className="mb-3 lg:mb-8">
                  <div className="flex flex-col">
                    <AppText
                      variant="display"
                      className="px-1 text-left text-[clamp(45px,_15vw,_90px)] leading-[clamp(45px,_13vw,_90px)] text-[#141010]"
                    >
                      <span className="block">A FRONT ROW</span>{" "}
                      <span className="block">
                        SEAT TO
                        <span className="text-[#EEE642] [-webkit-text-stroke:0.8px_#141010] [text-stroke:0.8px_#141010]">
                          {" "}
                          EPIC
                        </span>
                      </span>
                      <span className="block text-[#EEE642] [-webkit-text-stroke:0.8px_#141010] [text-stroke:0.8px_#141010]">
                        GROUP CHATS
                      </span>
                    </AppText>
                  </div>
                </div>
                <AppText
                  variant={isLargeScreen ? "body1" : "body2"}
                  className="max-w-[540px] text-[#141010]"
                >
                  Amazing people having real conversations about topics you love. Follow your
                  favorites or start your own.
                </AppText>
              </div>

              {/* Phone input section */}
              <div className="grid grid-cols-1 gap-2 max-lg:max-w-[540px] sm:grid-cols-2 sm:pt-4 lg:gap-4">
                <div className="max-sm:hidden">
                  <PhoneNumberInput
                    ref={phoneInputRef}
                    value={phoneValue}
                    onChange={handlePhoneChange}
                    onSubmit={handleGetAppClick}
                    inputClassName="h-[48px]"
                  />
                </div>

                <div>
                  <Link
                    to={!phoneInputEnabled() ? downloadPath({}) : "#"}
                    onClick={(e) => {
                      e.preventDefault()
                      handleGetAppClick()
                    }}
                  >
                    <button className="flex h-[48px] w-full items-center justify-center rounded-full border border-[#141010] bg-[#FD905B] px-4 py-3 text-[#141010] transition-colors hover:bg-[#FD905B]/90 lg:w-[215px]">
                      <AppText variant="button" className="text-18">
                        <strong>GET THE APP</strong>
                      </AppText>
                    </button>
                  </Link>
                </div>
              </div>
              <ConsentCheckbox
                checked={hasConsented}
                onChange={setHasConsented}
                className="max-sm:hidden"
                highlight={highlightConsent}
              />
            </div>

            {/* Right column - Carousel */}
            <div>
              <div className="carousel-mask overflow-hidden max-lg:max-w-[540px]">
                <div className="max-lg:mx-auto max-lg:w-full max-lg:max-w-[540px]">
                  <div
                    ref={emblaRef}
                    className="embla max-lg:mx-auto max-lg:max-w-[400px] max-lg:overflow-hidden"
                  >
                    <div className="embla__container lg:h-[720px]">
                      <div className="embla__slide">
                        <img
                          src={bachelor.src}
                          alt="The Bachelor chat"
                          className={carouselImageClasses}
                        />
                      </div>
                      <div className="embla__slide">
                        <img
                          src={gameday.src}
                          alt="Gameday chat"
                          className={carouselImageClasses}
                        />
                      </div>
                      <div className="embla__slide">
                        <img
                          src={marketMovers.src}
                          alt="Market Movers chat"
                          className={carouselImageClasses}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <TermsText className="pt-3 text-center sm:hidden" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
