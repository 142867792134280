import { useQuery } from "@apollo/client"
import { zodResolver } from "@hookform/resolvers/zod"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { Link } from "react-router-dom"
import { useDebounce } from "use-debounce"
import * as z from "zod"
import { gql } from "~/__generated__"
import { RoomStatus } from "~/__generated__/graphql"
import { ROOM_STATUS_OPTIONS } from "~/admin/admin-edit-room-screen/edit-room-form"
import { useViewer } from "~/auth/use-viewer"
import { TabButton, TabContainer } from "~/ui/tabs"
import { formatDateLong } from "~/common/dates"
import { adminRoomPath } from "~/common/paths"
import { getRoomStatusName } from "~/admin/admin-edit-room-screen/edit-room-form"
import { RoomPublishButton } from "~/common/room-publish-button"
import { useSafeMutation } from "~/common/use-safe-mutation"
import { TextField } from "~/fields/text-field"
import { AppText } from "~/ui/app-text"
import { Button } from "~/ui/button"
import { GraphqlError } from "~/ui/errors"
import { Form } from "~/ui/form"
import { TableContainer } from "~/ui/table"
import { useToast } from "~/ui/use-toast"

const UPDATE_ROOM_RANKING = gql(/* GraphQL */ `
  mutation AdminRoomsUpdateRanking($roomId: ID!, $ranking: Int) {
    updateRoomRanking(input: { roomId: $roomId, ranking: $ranking }) {
      room {
        id
        ranking
      }
    }
  }
`)

const formSchema = z.object({
  search: z.string(),
  roomStatus: z
    .union([
      z.nativeEnum(RoomStatus),
      z
        .string()
        .refine((val) => val === "")
        .transform(() => undefined),
    ])
    .optional(),
})

type FormValues = z.infer<typeof formSchema>

export const AdminRoomsScreen = (): JSX.Element => {
  const { viewer } = useViewer()
  const { toast } = useToast()
  const [updateRoomRanking] = useSafeMutation(UPDATE_ROOM_RANKING)
  const [rankingChanges, setRankingChanges] = useState<Record<string, number | null>>({})
  const [isSaving, setIsSaving] = useState(false)
  const [errorRooms, setErrorRooms] = useState<string[]>([])

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      search: "",
      roomStatus: RoomStatus.PendingApproval,
    },
  })

  const search = form.watch("search")
  const [debouncedSearch] = useDebounce(search, 250)
  const roomStatus = form.watch("roomStatus")

  const result = useQuery(ADMIN_ROOMS_QUERY, {
    variables: {
      roomStatus: roomStatus,
      search: debouncedSearch,
    },
  })

  const { data: currentData, previousData, error, loading, fetchMore, refetch } = result

  const data = currentData || previousData

  // Reset ranking changes when tab changes
  useEffect(() => {
    setRankingChanges({})
  }, [roomStatus])

  const pageInfo = data?.adminRooms.pageInfo
  const rooms = data?.adminRooms?.edges?.map((edge) => edge.node)

  const handleRankingChange = (roomId: string, ranking: number | null): void => {
    setRankingChanges((prev) => ({
      ...prev,
      [roomId]: ranking,
    }))
  }

  const saveAllRankings = async (): Promise<void> => {
    setIsSaving(true)
    setErrorRooms([])
    let hasErrors = false

    try {
      // Process all ranking changes sequentially
      for (const [roomId, ranking] of Object.entries(rankingChanges)) {
        const result = await updateRoomRanking({
          variables: {
            roomId,
            ranking,
          },
        })

        // Check for GraphQL errors
        if (result.errors) {
          hasErrors = true
          setErrorRooms((prev) => [...prev, roomId])
          console.error(`Failed to update ranking for room ${roomId}:`, result.errors)
          continue
        }
      }

      // Only clear changes and refetch if there were no errors
      if (!hasErrors) {
        setRankingChanges({})
        await refetch()
        toast({
          title: "Rankings updated",
          description: "All room rankings have been successfully updated.",
          variant: "default",
        })
      } else {
        toast({
          title: "Update partially failed",
          description: `Failed to update ${errorRooms.length} room rankings. Please try again.`,
          variant: "destructive",
        })
      }
    } catch (err) {
      console.error("Failed to update rankings:", err)
      toast({
        title: "Update failed",
        description: "An unexpected error occurred while updating rankings.",
        variant: "destructive",
      })
    } finally {
      setIsSaving(false)
    }
  }

  const cancelChanges = (): void => {
    // Discard all ranking changes
    setRankingChanges({})
  }

  if (loading && !data) {
    return <div>Loading...</div>
  }

  if (error || !rooms) {
    return <GraphqlError error={error} />
  }

  const hasUnsavedChanges = Object.keys(rankingChanges).length > 0

  return (
    <div>
      <AppText variant="h1" className="mb-4">
        Admin Chats
      </AppText>

      <Form {...form}>
        <form onChange={() => {}} className="space-y-2">
          <div className="">
            <TextField control={form.control} name="search" required={true} placeholder="Search" />
          </div>

          {/* Tabs */}
          <TabContainer>
            {ROOM_STATUS_OPTIONS.map((status) => (
              <TabButton
                key={status.value}
                active={roomStatus === status.value}
                onClick={() => form.setValue("roomStatus", status.value)}
              >
                {status.label}
              </TabButton>
            ))}
          </TabContainer>
        </form>
      </Form>

      {/* Save and Cancel buttons for published tab */}
      {roomStatus === RoomStatus.Published && hasUnsavedChanges && (
        <div className="mt-4 flex justify-end space-x-4">
          <Button onClick={cancelChanges} variant="outline" disabled={isSaving}>
            Cancel Changes
          </Button>
          <Button onClick={saveAllRankings} disabled={isSaving} variant="default">
            {isSaving ? "Saving..." : "Save All Rankings"}
          </Button>
        </div>
      )}

      <TableContainer className="mt-6">
        <table className="table">
          <thead>
            <tr>
              <th align="left">Name</th>
              <th align="left" className="pr-8">
                Status
              </th>
              {roomStatus === RoomStatus.Published && <th align="left">Ranking</th>}
              <th align="right"></th>
            </tr>
          </thead>

          <tbody>
            {rooms.map((room) => (
              <tr key={room.id} className={errorRooms.includes(room.id) ? "bg-red-50" : ""}>
                <td data-test="room-name">
                  <Link to={adminRoomPath({ id: room.id })} className="inline-flex">
                    <AppText
                      variant="body3"
                      className="cursor-pointer text-blue-500 hover:underline"
                    >
                      {room.name}
                    </AppText>
                  </Link>
                  <AppText variant="caption" className="text-neutral-400">
                    Created: {formatDateLong(room.createdAt)}
                  </AppText>
                </td>
                <td data-test="room-status">
                  <AppText variant="body3">{getRoomStatusName(room.roomStatus)}</AppText>
                </td>
                {roomStatus === RoomStatus.Published && (
                  <td>
                    <input
                      type="number"
                      className={`w-20 rounded border ${
                        errorRooms.includes(room.id) ? "border-red-500" : "border-gray-300"
                      } px-2 py-1`}
                      value={
                        rankingChanges[room.id] !== undefined
                          ? (rankingChanges[room.id] ?? "")
                          : (room.ranking ?? "")
                      }
                      onChange={(e) => {
                        let value = null
                        if (e.target.value !== "") {
                          const parsedValue = parseInt(e.target.value, 10)
                          if (!isNaN(parsedValue)) {
                            value = parsedValue
                          }
                        }
                        handleRankingChange(room.id, value)
                        // Clear error state when user makes changes
                        if (errorRooms.includes(room.id)) {
                          setErrorRooms((prev) => prev.filter((id) => id !== room.id))
                        }
                      }}
                      placeholder="Rank"
                    />
                    {errorRooms.includes(room.id) && (
                      <p className="text-xs mt-1 text-red-500">Failed to update</p>
                    )}
                  </td>
                )}

                <td align="right">
                  <div className="flex flex-row items-center justify-end gap-2">
                    {roomStatus === RoomStatus.PendingApproval && room.id !== viewer.id && (
                      <RoomPublishButton id={room.id} />
                    )}
                    <Link to={adminRoomPath({ id: room.id })}>
                      <Button variant="outline" size="sm">
                        View Details
                      </Button>
                    </Link>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </TableContainer>

      {rooms.length === 0 && (
        <div className="my-8 text-center">
          <AppText variant="body2">0 rooms found</AppText>
        </div>
      )}

      {pageInfo?.hasNextPage && pageInfo.endCursor && (
        <div className="mt-4 flex items-center justify-center">
          <Button
            onClick={() => {
              fetchMore({ variables: { after: pageInfo.endCursor } })
            }}
            variant="ghost"
            disabled={loading}
          >
            View More
          </Button>
        </div>
      )}
    </div>
  )
}

export const BASE_ROOM_FRAGMENT = gql(`
  fragment BaseRoom on Room {
    id
    name
    description
    roomStatus
    roomHandle
    sharing
    webUrl
    createdAt
    updatedAt
    ranking
    vipName
    vipColor
    vipEnabled
    vipBadgeUrl
    postsEnabled
    roomMessagesPreview {
      id
      textContent
      createdAt
      user {
        id
        username
        displayName
      }
    }
  }
`)

const ADMIN_ROOMS_QUERY = gql(/* GraphQL */ `
  query AdminRooms($roomStatus: RoomStatus, $search: String, $after: String) {
    adminRooms(roomStatus: $roomStatus, search: $search, after: $after, first: 50) {
      edges {
        node {
          ...BaseRoom
        }
      }

      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`)
