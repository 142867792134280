import { ShareScreenBase } from "./share-screen-base"
import { RoomContentCard } from "./room-content-card"
import { getRoomDeeplinkUrl } from "~/common/platform"
import { getMetaContent, getMetaContentMaybe } from "~/common/get-meta-content"
import { ShareRoomFragment, VerificationLink } from "~/__generated__/graphql"
import { useDocumentTitleDescription } from "~/common/use-document-title"

export const ShareRoomScreen = (): JSX.Element => {
  const roomDataContent = getMetaContentMaybe("room-data")
  const roomData = roomDataContent ? (JSON.parse(roomDataContent) as ShareRoomFragment) : null

  const appEnvironment = getMetaContent("APP_ENVIRONMENT")
  const deeplinkUrl = roomData?.id ? getRoomDeeplinkUrl(appEnvironment, roomData.id) : undefined
  const roomTitle = roomData?.name ? [roomData.name, "on ChatBCC"].join(" ") : null
  useDocumentTitleDescription(roomTitle, roomData?.description ?? null)

  return (
    <ShareScreenBase
      verificationLinkType={VerificationLink.RoomShare}
      roomHandle={roomData?.roomHandle}
      roomId={roomData?.id}
      deeplinkUrl={deeplinkUrl}
      renderContent={() =>
        roomData && (
          <div className="flex w-full max-w-[640px] justify-center">
            <RoomContentCard roomData={roomData} className="w-full" />
          </div>
        )
      }
    />
  )
}

export default ShareRoomScreen
