import { cn } from "~/common/cn"

export const TabButton = ({
  active,
  onClick,
  children,
}: {
  active: boolean
  onClick: () => void
  children: React.ReactNode
}) => {
  return (
    <button
      type="button"
      className={cn(
        "px-1 py-4",
        active
          ? "border-b-2 border-blue-500 font-medium text-blue-600"
          : "text-gray-500 hover:border-gray-300 hover:text-gray-700"
      )}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

export const TabContainer = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="border-b border-gray-200">
      <div className="flex space-x-8">{children}</div>
    </div>
  )
}
