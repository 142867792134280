import { useCallback } from "react"
import invariant from "tiny-invariant"
import { fileChecksum } from "~/common/file-checksum"
import { gql } from "../__generated__"
import { useSafeMutation } from "~/common/use-safe-mutation"

const useFileUpload = () => {
  const [mutate, { loading }] = useSafeMutation(UPLOAD_PRESIGN_MUTATION)

  const uploadFile = useCallback(
    async ({
      file,
      filename,
      contentType,
    }: {
      file: File
      filename: string
      contentType: string
    }) => {
      const base64Checksum = await fileChecksum(file, () => {})

      const variables = {
        input: {
          filename,
          contentType,
          byteSize: file.size,
          checksum: base64Checksum,
        },
      }

      const { data, errors } = await mutate({ variables })

      if (errors) {
        console.error(errors)
        throw new Error(`Graphql errors: ${errors[0].message}`)
      }
      invariant(data)
      invariant(data.uploadPresign.url, `expected a url`)

      const uploadResult = await fetch(data.uploadPresign.url, {
        headers: {
          ...data.uploadPresign.headers,
          "Content-Type": contentType,
        },
        method: "PUT",
        body: file,
      })

      if (uploadResult.status === 200) {
        return {
          uploadPresign: data.uploadPresign,
          uploadResult,
        }
      } else {
        throw new Error("File upload failed, please try again")
      }
    },
    [mutate]
  )

  return { uploadFile, loading }
}

export default useFileUpload

export const UPLOAD_PRESIGN_MUTATION = gql(`
  mutation UploadPresign_Mutation($input: UploadPresignInput!) {
    uploadPresign(input: $input) {
      url
      headers
      signedId
    }
  }
`)
