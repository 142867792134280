import { useQuery } from "@apollo/client"
import { useState } from "react"
import { Link as RouterLink, useParams } from "react-router-dom"
import { getRoomStatusName } from "../admin-edit-room-screen/edit-room-form"
import invariant from "tiny-invariant"
import { gql } from "~/__generated__"
import { RoomWaitlistCodesList } from "~/admin/admin-room-screen/room-waitlist-codes/room-waitlist-codes-list"
import { InvitationType } from "~/__generated__/graphql"
import { RoomInvitersList } from "~/admin/admin-room-screen/room-inviters/room-inviters-list"
import { formatDateLong } from "~/common/dates"
import { adminEditRoomPath, adminRoomsPath } from "~/common/paths"
import { AppText } from "~/ui/app-text"
import { Button } from "~/ui/button"
import { GraphqlError } from "~/ui/errors"
import { PageHeader } from "~/ui/page-header"

export const AdminRoomScreen = (): JSX.Element => {
  const { id } = useParams<{ id: string }>()
  invariant(id, "Expected room ID")
  const [invitationType, setInvitationType] = useState<InvitationType>(InvitationType.Member)

  const {
    data: currentData,
    previousData,
    loading,
    error,
    refetch,
    fetchMore,
  } = useQuery(ADMIN_ROOM_QUERY, {
    variables: { id, waitlistCodesFirst: 50, roomInvitersFirst: 50, invitationType },
  })
  const data = currentData || previousData

  if (loading && !data) {
    return <div>Loading...</div>
  }

  if (error || !data?.adminRoom) {
    return <GraphqlError error={error} />
  }

  const loadMoreWaitlistCodes = () => {
    fetchMore({
      variables: {
        waitlistCodesAfter: data.adminRoom.roomWaitlistCodes.pageInfo.endCursor,
      },
    })
  }

  const loadMoreRoomInviters = () => {
    fetchMore({
      variables: {
        roomInvitersAfter: data.adminRoom.roomInviters.pageInfo.endCursor,
      },
    })
  }

  const room = data.adminRoom

  return (
    <div className="max-w-3xl">
      <div className="flex items-center justify-between">
        <PageHeader title={`Room Details: ${room.name}`} backPath={adminRoomsPath({})} />

        <div className="mt-8 flex items-center justify-between gap-2">
          <Button asChild={true} variant="outline">
            <RouterLink to={adminEditRoomPath({ id: room.id })}>Edit</RouterLink>
          </Button>
        </div>
      </div>

      <div className="mb-8 rounded-lg border border-gray-200 bg-white p-6 shadow-sm">
        <div className="mb-4 grid grid-cols-2 gap-4">
          <div className="space-y-2">
            <AppText variant="h3">Basic Information</AppText>
            <div>
              <AppText variant="body2" className="text-gray-500">
                ID:
              </AppText>
              <AppText variant="body2">{room.id}</AppText>
            </div>
            <div>
              <AppText variant="body2" className="text-gray-500">
                Name:
              </AppText>
              <AppText variant="body2">{room.name}</AppText>
            </div>
            <div>
              <AppText variant="body2" className="text-gray-500">
                Handle:
              </AppText>
              <AppText variant="body2">{room.roomHandle || "None"}</AppText>
            </div>
            <div>
              <AppText variant="body2" className="text-gray-500">
                Sharing enabled:
              </AppText>
              <AppText variant="body2">{room.sharing.toString()}</AppText>
            </div>
            <div>
              <AppText variant="body2" className="text-gray-500">
                Posts enabled:
              </AppText>
              <AppText variant="body2">{room.postsEnabled.toString()}</AppText>
            </div>
            <div>
              <AppText variant="body2" className="text-gray-500">
                Status:
              </AppText>
              <AppText variant="body2">{getRoomStatusName(room.roomStatus)}</AppText>
            </div>
            <div>
              <AppText variant="body2" className="text-gray-500">
                Ranking:
              </AppText>
              <AppText variant="body2">{room.ranking || "Not ranked"}</AppText>
            </div>
          </div>
          <div className="space-y-2">
            <AppText variant="h3">Dates</AppText>
            <div>
              <AppText variant="body2" className="text-gray-500">
                Created:
              </AppText>
              <AppText variant="body2">{formatDateLong(room.createdAt)}</AppText>
            </div>
            <div>
              <AppText variant="body2" className="text-gray-500">
                Updated:
              </AppText>
              <AppText variant="body2">{formatDateLong(room.updatedAt)}</AppText>
            </div>
            <div>
              <AppText variant="body2" className="text-gray-500">
                Web URL:
              </AppText>
              <AppText variant="body2">{room.webUrl || "None"}</AppText>
            </div>
          </div>
          <div className="space-y-2">
            <AppText variant="h3" className="mb-1">
              VIP Details
            </AppText>
            <div>
              <AppText variant="body2" className="text-gray-500">
                VIP Enabled:
              </AppText>
              <AppText variant="body2">{room.vipEnabled.toString()}</AppText>
            </div>
            <div>
              <AppText variant="body2" className="text-gray-500">
                VIP Name:
              </AppText>
              <AppText variant="body2">{room.vipName}</AppText>
            </div>
            <div>
              <AppText variant="body2" className="text-gray-500">
                VIP Color:
              </AppText>
              {room.vipColor && (
                <div
                  className="mt-2 h-6 w-6 rounded border border-gray-300"
                  style={{ backgroundColor: room.vipColor }}
                />
              )}
            </div>
            {room.vipBadgeUrl && (
              <div className="mt-4">
                <AppText variant="body2" className="mb-1 text-gray-500">
                  VIP Badge:
                </AppText>
                <div className="flex items-center">
                  <BadgeImage url={room.vipBadgeUrl} size="large" />
                  <a
                    href={room.vipBadgeUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-sm ml-3 text-blue-500 hover:underline"
                  >
                    View full size
                  </a>
                </div>
              </div>
            )}
          </div>
          <div className="space-y-2">
            <AppText variant="h3" className="mb-1">
              Message Previews
            </AppText>
            <div>
              <AppText variant="body2" className="mb-2 text-gray-500">
                Preview Messages (max 10):
              </AppText>
              {room.roomMessagesPreview && room.roomMessagesPreview.length > 0 ? (
                <div className="space-y-2 rounded-lg border bg-gray-50 p-3">
                  {room.roomMessagesPreview.map((message) => (
                    <div key={message.id} className="border-b pb-2 last:border-b-0 last:pb-0">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center">
                          <AppText variant="body3" className="font-semibold">
                            {message.user?.displayName || message.user?.username || "Unknown User"}
                          </AppText>
                          <AppText variant="caption" className="ml-2 text-gray-500">
                            {formatDateLong(message.createdAt)}
                          </AppText>
                        </div>
                        <AppText variant="caption" className="text-gray-400">
                          ID: {message.id}
                        </AppText>
                      </div>
                      <AppText variant="body3" className="mt-1 whitespace-pre-wrap">
                        {message.textContent || "[No content]"}
                      </AppText>
                    </div>
                  ))}
                </div>
              ) : (
                <AppText variant="body3" className="text-gray-400">
                  No preview messages selected
                </AppText>
              )}
            </div>
          </div>
        </div>

        {!!data.adminRoom && (
          <>
            <div className="space-y-10">
              <RoomWaitlistCodesList
                roomId={id}
                waitlistCodes={
                  data.adminRoom.roomWaitlistCodes?.edges.map((edge: { node: any }) => edge.node) ||
                  []
                }
                hasNextPage={data.adminRoom.roomWaitlistCodes?.pageInfo?.hasNextPage}
                loading={loading}
                onLoadMore={loadMoreWaitlistCodes}
                onRefresh={() => refetch()}
              />

              <RoomInvitersList
                invitationType={invitationType}
                setInvitationType={setInvitationType}
                roomInviters={
                  data.adminRoom.roomInviters?.edges.map((edge: { node: any }) => edge.node) || []
                }
                hasNextPage={data.adminRoom.roomInviters?.pageInfo?.hasNextPage}
                loading={loading}
                onLoadMore={loadMoreRoomInviters}
              />
            </div>
          </>
        )}
      </div>
    </div>
  )
}

// Simple component for displaying badge images
export const BadgeImage = ({
  url,
  className = "",
  size = "medium",
}: {
  url: string
  className?: string
  size?: "small" | "medium" | "large"
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [loadError, setLoadError] = useState(false)

  const sizeClasses = {
    small: "w-8 h-8",
    medium: "w-12 h-12",
    large: "w-16 h-16",
  }

  return (
    <div
      className={`relative ${sizeClasses[size]} flex items-center justify-center overflow-hidden rounded-md border border-gray-200 bg-gray-50 p-1 ${className}`}
    >
      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-50">
          <svg
            className="h-4 w-4 animate-spin text-blue-500"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </div>
      )}

      {loadError ? (
        <div className="flex items-center justify-center p-1 text-center">
          <span className="text-xs text-gray-400">Image unavailable</span>
        </div>
      ) : (
        <img
          src={url}
          alt="VIP Badge"
          className={`max-h-full max-w-full object-contain ${isLoading ? "opacity-0" : "opacity-100"}`}
          onLoad={() => setIsLoading(false)}
          onError={() => {
            setIsLoading(false)
            setLoadError(true)
          }}
          crossOrigin="anonymous"
        />
      )}
    </div>
  )
}

const ADMIN_ROOM_QUERY = gql(/* GraphQL */ `
  query AdminRoom(
    $id: ID!
    $waitlistCodesFirst: Int
    $waitlistCodesAfter: String
    $roomInvitersFirst: Int
    $roomInvitersAfter: String
    $invitationType: InvitationType
  ) {
    adminRoom(id: $id) {
      ...BaseRoom

      roomWaitlistCodes(first: $waitlistCodesFirst, after: $waitlistCodesAfter) {
        edges {
          node {
            ...BaseRoomWaitlistCode
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
        totalCount
      }

      roomInviters(
        invitationType: $invitationType
        first: $roomInvitersFirst
        after: $roomInvitersAfter
      ) {
        edges {
          node {
            ...BaseRoomInviter
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
        totalCount
      }
    }
  }
`)
