import { RoomContentCard } from "./room-content-card"
import { AppText } from "~/ui/app-text"
import logo from "~/images/landing/logo.ts"
import { ShareRoomFragment } from "~/__generated__/graphql"

export const ShareRoomCardScreen = (): JSX.Element => {
  const roomTag = document.querySelector("meta[name=room-data]")
  const roomDataContent = roomTag?.getAttribute("content")
  const roomData = roomDataContent ? (JSON.parse(roomDataContent) as ShareRoomFragment) : null

  return (
    <div className="mx-auto my-auto flex h-[514px] w-[370px] flex-col overflow-hidden bg-[#2D92FF] p-6">
      {/* Title Section */}
      <div className="flex h-[80px] flex-col items-center gap-[4px]">
        <AppText variant="display" className="text-[40px] leading-[40px] text-[#141010]">
          CRASH THE
        </AppText>
        <AppText
          variant="display"
          className="text-[40px] leading-[40px] text-[#EEE642] [-webkit-text-stroke:0.8px_#141010] [text-stroke:0.8px_#141010]"
        >
          GROUP CHAT
        </AppText>
      </div>

      {/* Content Card */}
      <div className="my-auto flex justify-center">
        {roomData && <RoomContentCard roomData={roomData} compact={true} />}
      </div>

      {/* Logo Section */}
      <div className="flex justify-center">
        <img src={logo.src} alt="ChatBCC Logo" className="h-[43px]" loading="eager" />
      </div>
    </div>
  )
}

export default ShareRoomCardScreen
