import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { gql } from "~/__generated__"
import { BaseRoomWaitlistCodeFragment } from "~/__generated__/graphql"
import { useFormErrors } from "~/common/use-form-errors"
import { useSafeMutation } from "~/common/use-safe-mutation"
import { Button } from "~/ui/button"
import { Form } from "~/ui/form"
import { FormActions } from "~/ui/form-actions"
import { useToast } from "~/ui/use-toast"
import {
  RoomWaitlistCodeFields,
  RoomWaitlistCodeFormValues,
  roomWaitlistCodeSchema,
} from "./room-waitlist-code-fields"

export const EditRoomWaitlistCodeForm = ({
  waitlistCode,
  onSuccess,
  onCancel,
}: {
  waitlistCode: BaseRoomWaitlistCodeFragment
  onSuccess: () => void
  onCancel: () => void
}) => {
  const { toast } = useToast()
  const [mutate, result] = useSafeMutation(ROOM_WAITLIST_CODE_UPDATE_MUTATION)

  const form = useForm<RoomWaitlistCodeFormValues>({
    resolver: zodResolver(roomWaitlistCodeSchema),
    defaultValues: {
      code: waitlistCode.code,
      archived: waitlistCode.archived || false,
    },
  })

  useFormErrors(form.setError, result)

  const onSubmit = async (values: RoomWaitlistCodeFormValues) => {
    const result = await mutate({
      variables: {
        input: {
          id: waitlistCode.id,
          archived: values.archived,
        },
      },
    })

    if (result.errors) {
      return
    }

    toast({
      title: "Waitlist code updated successfully",
      variant: "default",
    })

    onSuccess()
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
        <RoomWaitlistCodeFields control={form.control} codeDisabled={true} showArchived={true} />

        <FormActions>
          <div className="flex justify-between">
            <Button type="button" variant="outline" onClick={onCancel}>
              Cancel
            </Button>
            <Button type="submit" disabled={form.formState.isSubmitting || !form.formState.isDirty}>
              Save Changes
            </Button>
          </div>
        </FormActions>
      </form>
    </Form>
  )
}

export const ROOM_WAITLIST_CODE_UPDATE_MUTATION = gql(`
  mutation WaitlistCodeUpdate($input: WaitlistCodeUpdateInput!) {
    waitlistCodeUpdate(input: $input) {
      roomWaitlistCode {
        ...BaseRoomWaitlistCode
      }
    }
  }
`)
