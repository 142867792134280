/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  fragment UserOverview on User {\n    id\n    email\n    username\n    displayOrUsername\n    userStatus\n    phoneNumber\n    createdAt\n  }\n": types.UserOverviewFragmentDoc,
    "\n  query AdminUsers($userRoles: [UserRole!], $after: String) {\n    users(userRoles: $userRoles, after: $after, first: 50) {\n      edges {\n        node {\n          ...UserOverview\n        }\n      }\n\n      totalCount\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n": types.AdminUsersDocument,
    "\n  query AdminGetRoomEdit($id: ID!) {\n    room(id: $id) {\n      ...BaseRoom\n    }\n  }\n": types.AdminGetRoomEditDocument,
    "\n  mutation AdminRoomUpdate($input: AdminRoomUpdateInput!) {\n    adminRoomUpdate(input: $input) {\n      room {\n        ...BaseRoom\n      }\n    }\n  }\n": types.AdminRoomUpdateDocument,
    "\n  fragment ModeratedMessageItem on Message {\n    id\n    textContent\n    createdAt\n    user {\n      id\n      displayOrUsername\n    }\n    moderationResponse {\n      id\n      textResult\n      textResponseReport\n      visualResult\n      visualResponseReport\n      adminPermitted\n    }\n    contentReports {\n      id\n      description\n    }\n    attachments {\n      id\n      attachmentType\n      contentType\n      height\n      width\n      playbackUrl\n      voiceNoteUrl\n      imageUrl\n      muxPlaybackId\n      aspectRatio\n      transcodingState\n    }\n  }\n": types.ModeratedMessageItemFragmentDoc,
    "\n  query AdminModeratedMessages(\n    $moderationResult: ModerationResult!\n    $includeUserContentReports: Boolean\n    $after: String\n  ) {\n    adminModeratedMessages(\n      moderationResult: $moderationResult\n      includeUserContentReports: $includeUserContentReports\n      after: $after\n      first: 50\n    ) {\n      edges {\n        node {\n          ...ModeratedMessageItem\n        }\n      }\n\n      totalCount\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n": types.AdminModeratedMessagesDocument,
    "\n  mutation AdminModerateMessage($input: AdminModerateMessageInput!) {\n    adminModerateMessage(input: $input) {\n      message {\n        ...ModeratedMessageItem\n      }\n    }\n  }\n": types.AdminModerateMessageDocument,
    "\n  mutation AdminUserCreate($input: AdminUserCreateInput!) {\n    adminUserCreate(input: $input) {\n      user {\n        id\n      }\n    }\n  }\n": types.AdminUserCreateDocument,
    "\n  query AdminRoom(\n    $id: ID!\n    $waitlistCodesFirst: Int\n    $waitlistCodesAfter: String\n    $roomInvitersFirst: Int\n    $roomInvitersAfter: String\n    $invitationType: InvitationType\n  ) {\n    adminRoom(id: $id) {\n      ...BaseRoom\n\n      roomWaitlistCodes(first: $waitlistCodesFirst, after: $waitlistCodesAfter) {\n        edges {\n          node {\n            ...BaseRoomWaitlistCode\n          }\n        }\n        pageInfo {\n          hasNextPage\n          endCursor\n        }\n        totalCount\n      }\n\n      roomInviters(\n        invitationType: $invitationType\n        first: $roomInvitersFirst\n        after: $roomInvitersAfter\n      ) {\n        edges {\n          node {\n            ...BaseRoomInviter\n          }\n        }\n        pageInfo {\n          hasNextPage\n          endCursor\n        }\n        totalCount\n      }\n    }\n  }\n": types.AdminRoomDocument,
    "\n  fragment BaseRoomInviter on RoomInviter {\n    id\n    username\n    displayName\n    count\n  }\n": types.BaseRoomInviterFragmentDoc,
    "\n  mutation WaitlistCodeCreate($input: WaitlistCodeCreateInput!) {\n    waitlistCodeCreate(input: $input) {\n      roomWaitlistCode {\n        ...BaseRoomWaitlistCode\n      }\n    }\n  }\n": types.WaitlistCodeCreateDocument,
    "\n  mutation WaitlistCodeUpdate($input: WaitlistCodeUpdateInput!) {\n    waitlistCodeUpdate(input: $input) {\n      roomWaitlistCode {\n        ...BaseRoomWaitlistCode\n      }\n    }\n  }\n": types.WaitlistCodeUpdateDocument,
    "\n  fragment BaseRoomWaitlistCode on RoomWaitlistCode {\n    id\n    code\n    archived\n    createdAt\n  }\n": types.BaseRoomWaitlistCodeFragmentDoc,
    "\n  mutation AdminRoomsUpdateRanking($roomId: ID!, $ranking: Int) {\n    updateRoomRanking(input: { roomId: $roomId, ranking: $ranking }) {\n      room {\n        id\n        ranking\n      }\n    }\n  }\n": types.AdminRoomsUpdateRankingDocument,
    "\n  fragment BaseRoom on Room {\n    id\n    name\n    description\n    roomStatus\n    roomHandle\n    sharing\n    webUrl\n    createdAt\n    updatedAt\n    ranking\n    vipName\n    vipColor\n    vipEnabled\n    vipBadgeUrl\n    postsEnabled\n    roomMessagesPreview {\n      id\n      textContent\n      createdAt\n      user {\n        id\n        username\n        displayName\n      }\n    }\n  }\n": types.BaseRoomFragmentDoc,
    "\n  query AdminRooms($roomStatus: RoomStatus, $search: String, $after: String) {\n    adminRooms(roomStatus: $roomStatus, search: $search, after: $after, first: 50) {\n      edges {\n        node {\n          ...BaseRoom\n        }\n      }\n\n      totalCount\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n": types.AdminRoomsDocument,
    "\n  query AdminUserDetail($id: ID!) {\n    user(id: $id) {\n      id\n      username\n      displayName\n      email\n      phoneNumber\n      userStatus\n      userRole\n      createdAt\n      verifiedAccount\n      waitlisted\n      showFullName\n      bio\n      webUrl\n      activeFollowerCount\n      activeFollowingCount\n      activeRoomFollowsCount\n    }\n  }\n": types.AdminUserDetailDocument,
    "\n  mutation UpdateUserFields(\n    $userId: ID!\n    $verifiedAccount: Boolean\n    $waitlisted: Boolean\n    $showFullName: Boolean\n  ) {\n    adminUserUpdate(\n      input: {\n        id: $userId\n        userInput: {\n          verifiedAccount: $verifiedAccount\n          waitlisted: $waitlisted\n          showFullName: $showFullName\n        }\n      }\n    ) {\n      user {\n        id\n        verifiedAccount\n        waitlisted\n        showFullName\n      }\n    }\n  }\n": types.UpdateUserFieldsDocument,
    "\n  mutation UpdateUserStatus($userId: ID!, $userStatus: UserStatus!) {\n    adminUserUpdate(input: { id: $userId, userInput: { userStatus: $userStatus } }) {\n      user {\n        id\n        userStatus\n      }\n    }\n  }\n": types.UpdateUserStatusDocument,
    "\n  query AdminAllUsers($userRoles: [UserRole!], $after: String, $query: String) {\n    users(userRoles: $userRoles, after: $after, first: 50, excludeDemoUsers: true, query: $query) {\n      edges {\n        node {\n          ...UserOverview\n        }\n      }\n\n      totalCount\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n": types.AdminAllUsersDocument,
    "fragment CachedViewerContext on User {\n  id\n  userRole\n  avatarUrl\n  displayOrUsername\n  isAdmin\n}": types.CachedViewerContextFragmentDoc,
    "\n  mutation Logout($input: LogoutInput!) {\n    logout(input: $input) {\n      success\n    }\n  }\n": types.LogoutDocument,
    "\n  query Viewer {\n    viewer {\n      id\n      ...CachedViewerContext\n    }\n  }\n": types.ViewerDocument,
    "\n  mutation AdminUserUpdate($input: AdminUserUpdateInput!) {\n    adminUserUpdate(input: $input) {\n      user {\n        ...UserOverview\n      }\n    }\n  }\n": types.AdminUserUpdateDocument,
    "\n  fragment User_Avatar on User {\n    id\n    avatarUrl\n    displayOrUsername\n  }\n": types.User_AvatarFragmentDoc,
    "\n  mutation GenerateVerificationLink($input: GenerateVerificationLinkInput!) {\n    generateVerificationLink(input: $input) {\n      success\n      errors\n    }\n  }\n": types.GenerateVerificationLinkDocument,
    "\n  mutation PublishRoom($input: RoomPublishInput!) {\n    roomPublish(input: $input) {\n      room {\n        ...BaseRoom\n      }\n    }\n  }\n": types.PublishRoomDocument,
    "\n  mutation UploadPresign_Mutation($input: UploadPresignInput!) {\n    uploadPresign(input: $input) {\n      url\n      headers\n      signedId\n    }\n  }\n": types.UploadPresign_MutationDocument,
    "\n  mutation Login($input: CredentialsUserAuthInput!) {\n    login: credentialsUserAuth(input: $input) {\n      user {\n        id\n      }\n    }\n  }\n": types.LoginDocument,
    "\n  mutation EmailTokenAuth($input: EmailTokenUserAuthInput!) {\n    emailTokenUserAuth(input: $input) {\n      success\n      csrfToken\n    }\n  }\n": types.EmailTokenAuthDocument,
    "\n  mutation EmailAuthChallenge($input: EmailUserAuthChallengeInput!) {\n    emailUserAuthChallenge(input: $input) {\n      success\n    }\n  }\n": types.EmailAuthChallengeDocument,
    "\n  mutation UpdateProfile($input: UserUpdateInput!) {\n    userUpdate(input: $input) {\n      user {\n        id\n      }\n    }\n  }\n": types.UpdateProfileDocument,
    "fragment ShareAttachment on Attachment {\n  attachmentType\n  imageUrl\n  width\n  height\n  contentType\n}": types.ShareAttachmentFragmentDoc,
    "fragment ShareMessage on Message {\n  createdAt\n  textContent\n  user {\n    ...ShareRoomMember\n  }\n  childMessagesCount\n  childMessageUsers {\n    avatarUrl\n    shortDisplayName\n  }\n  reactionGroups {\n    ...ShareReactionGroup\n  }\n  attachments {\n    ...ShareAttachment\n  }\n}": types.ShareMessageFragmentDoc,
    "fragment ShareReactionGroup on ReactionGroup {\n  emoji\n  reactionCount\n}": types.ShareReactionGroupFragmentDoc,
    "fragment ShareRoomMember on User {\n  id\n  username\n  displayOrUsername\n  shortDisplayName\n  avatarUrl\n  activeFollowerCount\n}": types.ShareRoomMemberFragmentDoc,
    "fragment ShareRoom on Room {\n  id\n  name\n  description\n  roomStatus\n  roomHandle\n  activeRoomFollowsCount\n  activeRoomMembershipsCount\n  photoUrl\n  sharing\n  roomMembershipsPreview(limit: 30) {\n    user {\n      ...ShareRoomMember\n    }\n  }\n  roomMessagesPreview {\n    ...ShareMessage\n  }\n}": types.ShareRoomFragmentDoc,
    "\n  query userDetails($userId: ID!) {\n    node(id: $userId) {\n      ... on User {\n        id\n        userRole\n      }\n    }\n  }\n": types.UserDetailsDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment UserOverview on User {\n    id\n    email\n    username\n    displayOrUsername\n    userStatus\n    phoneNumber\n    createdAt\n  }\n"): (typeof documents)["\n  fragment UserOverview on User {\n    id\n    email\n    username\n    displayOrUsername\n    userStatus\n    phoneNumber\n    createdAt\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query AdminUsers($userRoles: [UserRole!], $after: String) {\n    users(userRoles: $userRoles, after: $after, first: 50) {\n      edges {\n        node {\n          ...UserOverview\n        }\n      }\n\n      totalCount\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n"): (typeof documents)["\n  query AdminUsers($userRoles: [UserRole!], $after: String) {\n    users(userRoles: $userRoles, after: $after, first: 50) {\n      edges {\n        node {\n          ...UserOverview\n        }\n      }\n\n      totalCount\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query AdminGetRoomEdit($id: ID!) {\n    room(id: $id) {\n      ...BaseRoom\n    }\n  }\n"): (typeof documents)["\n  query AdminGetRoomEdit($id: ID!) {\n    room(id: $id) {\n      ...BaseRoom\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation AdminRoomUpdate($input: AdminRoomUpdateInput!) {\n    adminRoomUpdate(input: $input) {\n      room {\n        ...BaseRoom\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation AdminRoomUpdate($input: AdminRoomUpdateInput!) {\n    adminRoomUpdate(input: $input) {\n      room {\n        ...BaseRoom\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment ModeratedMessageItem on Message {\n    id\n    textContent\n    createdAt\n    user {\n      id\n      displayOrUsername\n    }\n    moderationResponse {\n      id\n      textResult\n      textResponseReport\n      visualResult\n      visualResponseReport\n      adminPermitted\n    }\n    contentReports {\n      id\n      description\n    }\n    attachments {\n      id\n      attachmentType\n      contentType\n      height\n      width\n      playbackUrl\n      voiceNoteUrl\n      imageUrl\n      muxPlaybackId\n      aspectRatio\n      transcodingState\n    }\n  }\n"): (typeof documents)["\n  fragment ModeratedMessageItem on Message {\n    id\n    textContent\n    createdAt\n    user {\n      id\n      displayOrUsername\n    }\n    moderationResponse {\n      id\n      textResult\n      textResponseReport\n      visualResult\n      visualResponseReport\n      adminPermitted\n    }\n    contentReports {\n      id\n      description\n    }\n    attachments {\n      id\n      attachmentType\n      contentType\n      height\n      width\n      playbackUrl\n      voiceNoteUrl\n      imageUrl\n      muxPlaybackId\n      aspectRatio\n      transcodingState\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query AdminModeratedMessages(\n    $moderationResult: ModerationResult!\n    $includeUserContentReports: Boolean\n    $after: String\n  ) {\n    adminModeratedMessages(\n      moderationResult: $moderationResult\n      includeUserContentReports: $includeUserContentReports\n      after: $after\n      first: 50\n    ) {\n      edges {\n        node {\n          ...ModeratedMessageItem\n        }\n      }\n\n      totalCount\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n"): (typeof documents)["\n  query AdminModeratedMessages(\n    $moderationResult: ModerationResult!\n    $includeUserContentReports: Boolean\n    $after: String\n  ) {\n    adminModeratedMessages(\n      moderationResult: $moderationResult\n      includeUserContentReports: $includeUserContentReports\n      after: $after\n      first: 50\n    ) {\n      edges {\n        node {\n          ...ModeratedMessageItem\n        }\n      }\n\n      totalCount\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation AdminModerateMessage($input: AdminModerateMessageInput!) {\n    adminModerateMessage(input: $input) {\n      message {\n        ...ModeratedMessageItem\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation AdminModerateMessage($input: AdminModerateMessageInput!) {\n    adminModerateMessage(input: $input) {\n      message {\n        ...ModeratedMessageItem\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation AdminUserCreate($input: AdminUserCreateInput!) {\n    adminUserCreate(input: $input) {\n      user {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation AdminUserCreate($input: AdminUserCreateInput!) {\n    adminUserCreate(input: $input) {\n      user {\n        id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query AdminRoom(\n    $id: ID!\n    $waitlistCodesFirst: Int\n    $waitlistCodesAfter: String\n    $roomInvitersFirst: Int\n    $roomInvitersAfter: String\n    $invitationType: InvitationType\n  ) {\n    adminRoom(id: $id) {\n      ...BaseRoom\n\n      roomWaitlistCodes(first: $waitlistCodesFirst, after: $waitlistCodesAfter) {\n        edges {\n          node {\n            ...BaseRoomWaitlistCode\n          }\n        }\n        pageInfo {\n          hasNextPage\n          endCursor\n        }\n        totalCount\n      }\n\n      roomInviters(\n        invitationType: $invitationType\n        first: $roomInvitersFirst\n        after: $roomInvitersAfter\n      ) {\n        edges {\n          node {\n            ...BaseRoomInviter\n          }\n        }\n        pageInfo {\n          hasNextPage\n          endCursor\n        }\n        totalCount\n      }\n    }\n  }\n"): (typeof documents)["\n  query AdminRoom(\n    $id: ID!\n    $waitlistCodesFirst: Int\n    $waitlistCodesAfter: String\n    $roomInvitersFirst: Int\n    $roomInvitersAfter: String\n    $invitationType: InvitationType\n  ) {\n    adminRoom(id: $id) {\n      ...BaseRoom\n\n      roomWaitlistCodes(first: $waitlistCodesFirst, after: $waitlistCodesAfter) {\n        edges {\n          node {\n            ...BaseRoomWaitlistCode\n          }\n        }\n        pageInfo {\n          hasNextPage\n          endCursor\n        }\n        totalCount\n      }\n\n      roomInviters(\n        invitationType: $invitationType\n        first: $roomInvitersFirst\n        after: $roomInvitersAfter\n      ) {\n        edges {\n          node {\n            ...BaseRoomInviter\n          }\n        }\n        pageInfo {\n          hasNextPage\n          endCursor\n        }\n        totalCount\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment BaseRoomInviter on RoomInviter {\n    id\n    username\n    displayName\n    count\n  }\n"): (typeof documents)["\n  fragment BaseRoomInviter on RoomInviter {\n    id\n    username\n    displayName\n    count\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation WaitlistCodeCreate($input: WaitlistCodeCreateInput!) {\n    waitlistCodeCreate(input: $input) {\n      roomWaitlistCode {\n        ...BaseRoomWaitlistCode\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation WaitlistCodeCreate($input: WaitlistCodeCreateInput!) {\n    waitlistCodeCreate(input: $input) {\n      roomWaitlistCode {\n        ...BaseRoomWaitlistCode\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation WaitlistCodeUpdate($input: WaitlistCodeUpdateInput!) {\n    waitlistCodeUpdate(input: $input) {\n      roomWaitlistCode {\n        ...BaseRoomWaitlistCode\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation WaitlistCodeUpdate($input: WaitlistCodeUpdateInput!) {\n    waitlistCodeUpdate(input: $input) {\n      roomWaitlistCode {\n        ...BaseRoomWaitlistCode\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment BaseRoomWaitlistCode on RoomWaitlistCode {\n    id\n    code\n    archived\n    createdAt\n  }\n"): (typeof documents)["\n  fragment BaseRoomWaitlistCode on RoomWaitlistCode {\n    id\n    code\n    archived\n    createdAt\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation AdminRoomsUpdateRanking($roomId: ID!, $ranking: Int) {\n    updateRoomRanking(input: { roomId: $roomId, ranking: $ranking }) {\n      room {\n        id\n        ranking\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation AdminRoomsUpdateRanking($roomId: ID!, $ranking: Int) {\n    updateRoomRanking(input: { roomId: $roomId, ranking: $ranking }) {\n      room {\n        id\n        ranking\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment BaseRoom on Room {\n    id\n    name\n    description\n    roomStatus\n    roomHandle\n    sharing\n    webUrl\n    createdAt\n    updatedAt\n    ranking\n    vipName\n    vipColor\n    vipEnabled\n    vipBadgeUrl\n    postsEnabled\n    roomMessagesPreview {\n      id\n      textContent\n      createdAt\n      user {\n        id\n        username\n        displayName\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment BaseRoom on Room {\n    id\n    name\n    description\n    roomStatus\n    roomHandle\n    sharing\n    webUrl\n    createdAt\n    updatedAt\n    ranking\n    vipName\n    vipColor\n    vipEnabled\n    vipBadgeUrl\n    postsEnabled\n    roomMessagesPreview {\n      id\n      textContent\n      createdAt\n      user {\n        id\n        username\n        displayName\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query AdminRooms($roomStatus: RoomStatus, $search: String, $after: String) {\n    adminRooms(roomStatus: $roomStatus, search: $search, after: $after, first: 50) {\n      edges {\n        node {\n          ...BaseRoom\n        }\n      }\n\n      totalCount\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n"): (typeof documents)["\n  query AdminRooms($roomStatus: RoomStatus, $search: String, $after: String) {\n    adminRooms(roomStatus: $roomStatus, search: $search, after: $after, first: 50) {\n      edges {\n        node {\n          ...BaseRoom\n        }\n      }\n\n      totalCount\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query AdminUserDetail($id: ID!) {\n    user(id: $id) {\n      id\n      username\n      displayName\n      email\n      phoneNumber\n      userStatus\n      userRole\n      createdAt\n      verifiedAccount\n      waitlisted\n      showFullName\n      bio\n      webUrl\n      activeFollowerCount\n      activeFollowingCount\n      activeRoomFollowsCount\n    }\n  }\n"): (typeof documents)["\n  query AdminUserDetail($id: ID!) {\n    user(id: $id) {\n      id\n      username\n      displayName\n      email\n      phoneNumber\n      userStatus\n      userRole\n      createdAt\n      verifiedAccount\n      waitlisted\n      showFullName\n      bio\n      webUrl\n      activeFollowerCount\n      activeFollowingCount\n      activeRoomFollowsCount\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateUserFields(\n    $userId: ID!\n    $verifiedAccount: Boolean\n    $waitlisted: Boolean\n    $showFullName: Boolean\n  ) {\n    adminUserUpdate(\n      input: {\n        id: $userId\n        userInput: {\n          verifiedAccount: $verifiedAccount\n          waitlisted: $waitlisted\n          showFullName: $showFullName\n        }\n      }\n    ) {\n      user {\n        id\n        verifiedAccount\n        waitlisted\n        showFullName\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateUserFields(\n    $userId: ID!\n    $verifiedAccount: Boolean\n    $waitlisted: Boolean\n    $showFullName: Boolean\n  ) {\n    adminUserUpdate(\n      input: {\n        id: $userId\n        userInput: {\n          verifiedAccount: $verifiedAccount\n          waitlisted: $waitlisted\n          showFullName: $showFullName\n        }\n      }\n    ) {\n      user {\n        id\n        verifiedAccount\n        waitlisted\n        showFullName\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateUserStatus($userId: ID!, $userStatus: UserStatus!) {\n    adminUserUpdate(input: { id: $userId, userInput: { userStatus: $userStatus } }) {\n      user {\n        id\n        userStatus\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateUserStatus($userId: ID!, $userStatus: UserStatus!) {\n    adminUserUpdate(input: { id: $userId, userInput: { userStatus: $userStatus } }) {\n      user {\n        id\n        userStatus\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query AdminAllUsers($userRoles: [UserRole!], $after: String, $query: String) {\n    users(userRoles: $userRoles, after: $after, first: 50, excludeDemoUsers: true, query: $query) {\n      edges {\n        node {\n          ...UserOverview\n        }\n      }\n\n      totalCount\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n"): (typeof documents)["\n  query AdminAllUsers($userRoles: [UserRole!], $after: String, $query: String) {\n    users(userRoles: $userRoles, after: $after, first: 50, excludeDemoUsers: true, query: $query) {\n      edges {\n        node {\n          ...UserOverview\n        }\n      }\n\n      totalCount\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "fragment CachedViewerContext on User {\n  id\n  userRole\n  avatarUrl\n  displayOrUsername\n  isAdmin\n}"): (typeof documents)["fragment CachedViewerContext on User {\n  id\n  userRole\n  avatarUrl\n  displayOrUsername\n  isAdmin\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation Logout($input: LogoutInput!) {\n    logout(input: $input) {\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation Logout($input: LogoutInput!) {\n    logout(input: $input) {\n      success\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Viewer {\n    viewer {\n      id\n      ...CachedViewerContext\n    }\n  }\n"): (typeof documents)["\n  query Viewer {\n    viewer {\n      id\n      ...CachedViewerContext\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation AdminUserUpdate($input: AdminUserUpdateInput!) {\n    adminUserUpdate(input: $input) {\n      user {\n        ...UserOverview\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation AdminUserUpdate($input: AdminUserUpdateInput!) {\n    adminUserUpdate(input: $input) {\n      user {\n        ...UserOverview\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment User_Avatar on User {\n    id\n    avatarUrl\n    displayOrUsername\n  }\n"): (typeof documents)["\n  fragment User_Avatar on User {\n    id\n    avatarUrl\n    displayOrUsername\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation GenerateVerificationLink($input: GenerateVerificationLinkInput!) {\n    generateVerificationLink(input: $input) {\n      success\n      errors\n    }\n  }\n"): (typeof documents)["\n  mutation GenerateVerificationLink($input: GenerateVerificationLinkInput!) {\n    generateVerificationLink(input: $input) {\n      success\n      errors\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation PublishRoom($input: RoomPublishInput!) {\n    roomPublish(input: $input) {\n      room {\n        ...BaseRoom\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation PublishRoom($input: RoomPublishInput!) {\n    roomPublish(input: $input) {\n      room {\n        ...BaseRoom\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UploadPresign_Mutation($input: UploadPresignInput!) {\n    uploadPresign(input: $input) {\n      url\n      headers\n      signedId\n    }\n  }\n"): (typeof documents)["\n  mutation UploadPresign_Mutation($input: UploadPresignInput!) {\n    uploadPresign(input: $input) {\n      url\n      headers\n      signedId\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation Login($input: CredentialsUserAuthInput!) {\n    login: credentialsUserAuth(input: $input) {\n      user {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation Login($input: CredentialsUserAuthInput!) {\n    login: credentialsUserAuth(input: $input) {\n      user {\n        id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation EmailTokenAuth($input: EmailTokenUserAuthInput!) {\n    emailTokenUserAuth(input: $input) {\n      success\n      csrfToken\n    }\n  }\n"): (typeof documents)["\n  mutation EmailTokenAuth($input: EmailTokenUserAuthInput!) {\n    emailTokenUserAuth(input: $input) {\n      success\n      csrfToken\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation EmailAuthChallenge($input: EmailUserAuthChallengeInput!) {\n    emailUserAuthChallenge(input: $input) {\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation EmailAuthChallenge($input: EmailUserAuthChallengeInput!) {\n    emailUserAuthChallenge(input: $input) {\n      success\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateProfile($input: UserUpdateInput!) {\n    userUpdate(input: $input) {\n      user {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateProfile($input: UserUpdateInput!) {\n    userUpdate(input: $input) {\n      user {\n        id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "fragment ShareAttachment on Attachment {\n  attachmentType\n  imageUrl\n  width\n  height\n  contentType\n}"): (typeof documents)["fragment ShareAttachment on Attachment {\n  attachmentType\n  imageUrl\n  width\n  height\n  contentType\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "fragment ShareMessage on Message {\n  createdAt\n  textContent\n  user {\n    ...ShareRoomMember\n  }\n  childMessagesCount\n  childMessageUsers {\n    avatarUrl\n    shortDisplayName\n  }\n  reactionGroups {\n    ...ShareReactionGroup\n  }\n  attachments {\n    ...ShareAttachment\n  }\n}"): (typeof documents)["fragment ShareMessage on Message {\n  createdAt\n  textContent\n  user {\n    ...ShareRoomMember\n  }\n  childMessagesCount\n  childMessageUsers {\n    avatarUrl\n    shortDisplayName\n  }\n  reactionGroups {\n    ...ShareReactionGroup\n  }\n  attachments {\n    ...ShareAttachment\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "fragment ShareReactionGroup on ReactionGroup {\n  emoji\n  reactionCount\n}"): (typeof documents)["fragment ShareReactionGroup on ReactionGroup {\n  emoji\n  reactionCount\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "fragment ShareRoomMember on User {\n  id\n  username\n  displayOrUsername\n  shortDisplayName\n  avatarUrl\n  activeFollowerCount\n}"): (typeof documents)["fragment ShareRoomMember on User {\n  id\n  username\n  displayOrUsername\n  shortDisplayName\n  avatarUrl\n  activeFollowerCount\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "fragment ShareRoom on Room {\n  id\n  name\n  description\n  roomStatus\n  roomHandle\n  activeRoomFollowsCount\n  activeRoomMembershipsCount\n  photoUrl\n  sharing\n  roomMembershipsPreview(limit: 30) {\n    user {\n      ...ShareRoomMember\n    }\n  }\n  roomMessagesPreview {\n    ...ShareMessage\n  }\n}"): (typeof documents)["fragment ShareRoom on Room {\n  id\n  name\n  description\n  roomStatus\n  roomHandle\n  activeRoomFollowsCount\n  activeRoomMembershipsCount\n  photoUrl\n  sharing\n  roomMembershipsPreview(limit: 30) {\n    user {\n      ...ShareRoomMember\n    }\n  }\n  roomMessagesPreview {\n    ...ShareMessage\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query userDetails($userId: ID!) {\n    node(id: $userId) {\n      ... on User {\n        id\n        userRole\n      }\n    }\n  }\n"): (typeof documents)["\n  query userDetails($userId: ID!) {\n    node(id: $userId) {\n      ... on User {\n        id\n        userRole\n      }\n    }\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;