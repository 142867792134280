import { gql } from "~/__generated__"
import { UserStatus } from "~/__generated__/graphql"
import { Button } from "~/ui/button"
import { useToast } from "~/ui/use-toast"
import { useSafeMutation } from "./use-safe-mutation"

const ADMIN_USER_UPDATE_MUTATION = gql(/* GraphQL */ `
  mutation AdminUserUpdate($input: AdminUserUpdateInput!) {
    adminUserUpdate(input: $input) {
      user {
        ...UserOverview
      }
    }
  }
`)

export const BlockButton = ({ id, userStatus }: { id: string; userStatus: UserStatus }) => {
  const { toast } = useToast()
  const [mutate, result] = useSafeMutation(ADMIN_USER_UPDATE_MUTATION)

  const update = async (newStatus: UserStatus) => {
    const confirmed = window.confirm("Are you sure?")
    if (!confirmed) {
      return
    }

    const result = await mutate({
      variables: {
        input: {
          id,
          userInput: { userStatus: newStatus },
        },
      },
    })

    if (result.errors) {
      toast({
        title: "Updating user failed",
        description: "Please try again",
        variant: "destructive",
      })
      return
    }

    toast({
      title: "User updated",
      variant: "default",
    })
  }

  return (
    <Button
      variant="outline"
      size="sm"
      onClick={() => {
        if (userStatus === UserStatus.Blocked) {
          update(UserStatus.Active)
        } else {
          update(UserStatus.Blocked)
        }
      }}
      disabled={result.loading}
    >
      {userStatus !== UserStatus.Blocked ? "Block" : "Unblock"}
    </Button>
  )
}
