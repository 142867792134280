export const isAndroid = (): boolean => /android/i.test(navigator.userAgent)

export const isIOS = (): boolean => /iphone|ipad|ipod/i.test(navigator.userAgent)

export const isMobile = (): boolean => isAndroid() || isIOS()

export const getDeeplinkPrefix = (env: string): string =>
  ({
    development: "chatbcc-debug://",
    staging: "chatbcc-internal://",
    production: "chatbcc://",
  })[env] || "chatbcc://"

export const getRoomDeeplinkUrl = (env: string, roomId: string): string =>
  `${getDeeplinkPrefix(env)}r/${roomId}`

export const getDeeplinkUrl = (env: string, path: string): string =>
  `${getDeeplinkPrefix(env)}${path}`
