import { useQuery } from "@apollo/client"
import { NavLink, useParams } from "react-router-dom"
import invariant from "tiny-invariant"
import { gql } from "~/__generated__"
import { ModeratedMessageItemFragment, ModerationResult } from "~/__generated__/graphql"
import { formatDateLong } from "~/common/dates"
import { adminModerationPath } from "~/common/paths"
import { truncate } from "~/common/truncate"
import { AppText } from "~/ui/app-text"
import { Button } from "~/ui/button"
import { GraphqlError } from "~/ui/errors"
import { TableContainer } from "~/ui/table"
import { MessageModerationModal } from "./message-moderation-modal"
import { useState } from "react"

export const AdminModerationScreen = () => {
  const [focusedMessage, setFocusedMessage] = useState<ModeratedMessageItemFragment | null>(null)
  const { moderationResult } = useParams<{ moderationResult: ModerationResult }>()
  invariant(moderationResult, "moderation result required")

  const {
    data: currentData,
    previousData,
    error,
    loading,
    fetchMore,
  } = useQuery(ADMIN_MODERATED_MESSAGES_QUERY, {
    variables: {
      moderationResult,
      includeUserContentReports: moderationResult === ModerationResult.Flagged,
    },
  })
  const data = currentData || previousData
  const pageInfo = data?.adminModeratedMessages.pageInfo
  const messages = data?.adminModeratedMessages?.edges
    ?.map((edge) => edge.node)
    .filter((m) => !m.moderationResponse || m.moderationResponse?.adminPermitted === null)

  return (
    <div>
      <AppText variant="h1" className="mb-4">
        Message Moderation
      </AppText>

      <AppText variant="body3" className="mb-4">
        <NavLink
          to={adminModerationPath({ moderationResult: ModerationResult.Flagged })}
          className={({ isActive }) => (isActive ? "font-bold underline" : "")}
        >
          Flagged
        </NavLink>{" "}
        &nbsp;|&nbsp;
        <NavLink
          to={adminModerationPath({ moderationResult: ModerationResult.AutoRemoved })}
          className={({ isActive }) => (isActive ? "font-bold underline" : "")}
        >
          Auto-removed
        </NavLink>
      </AppText>

      {loading && !messages ? null : error || !messages ? (
        <GraphqlError error={error} />
      ) : (
        <>
          <TableContainer className="mb-8">
            <table className="table">
              <thead>
                <tr>
                  <th align="left">ID</th>
                  <th align="left">Text</th>
                  <th align="left">Attachment</th>
                  <th align="left">User</th>
                  <th align="left">Text Result</th>
                  <th align="left">Visual Result</th>
                  <th align="left">User Reports</th>
                  <th align="left">Created</th>
                  <th />
                </tr>
              </thead>

              <tbody>
                {messages.map((message) => (
                  <MessageRow
                    message={message}
                    key={message.id}
                    setFocusedMessage={setFocusedMessage}
                  />
                ))}
              </tbody>
            </table>
          </TableContainer>

          {pageInfo?.hasNextPage && pageInfo.endCursor && (
            <div className="mb-8 mt-4 flex items-center justify-center">
              <Button
                onClick={() => {
                  fetchMore({ variables: { after: pageInfo.endCursor } })
                }}
                variant="ghost"
                disabled={loading}
              >
                View More
              </Button>
            </div>
          )}
        </>
      )}

      {focusedMessage && (
        <MessageModerationModal
          message={focusedMessage}
          onClose={() => setFocusedMessage(null)}
          includeActions={moderationResult === ModerationResult.Flagged}
        />
      )}
    </div>
  )
}

const MessageRow = ({
  message,
  setFocusedMessage,
}: {
  message: ModeratedMessageItemFragment
  setFocusedMessage: (message: ModeratedMessageItemFragment) => void
}) => {
  return (
    <tr key={message.id}>
      <td>
        <AppText variant="body3">{message.id}</AppText>
      </td>
      <td>
        <AppText variant="caption">{truncate(message.textContent, 200)}</AppText>
      </td>
      <td>{message.attachments.at(0)?.attachmentType}</td>
      <td>
        <AppText variant="body3">{message.user?.displayOrUsername}</AppText>
        <AppText variant="caption" className="text-neutral-400">
          {message.user?.id}
        </AppText>
      </td>
      <td>
        <AppText variant="caption">{message.moderationResponse?.textResult}</AppText>
      </td>
      <td>
        <AppText variant="caption">{message.moderationResponse?.visualResult}</AppText>
      </td>
      <td>
        <AppText variant="caption">{message.contentReports.length}</AppText>
      </td>
      <td>
        <AppText variant="caption" className="text-neutral-400">
          {formatDateLong(message.createdAt)}
        </AppText>
      </td>
      <td align="right">
        <Button onClick={() => setFocusedMessage(message)}>Details</Button>
      </td>
    </tr>
  )
}

gql(/* GraphQL */ `
  fragment ModeratedMessageItem on Message {
    id
    textContent
    createdAt
    user {
      id
      displayOrUsername
    }
    moderationResponse {
      id
      textResult
      textResponseReport
      visualResult
      visualResponseReport
      adminPermitted
    }
    contentReports {
      id
      description
    }
    attachments {
      id
      attachmentType
      contentType
      height
      width
      playbackUrl
      voiceNoteUrl
      imageUrl
      muxPlaybackId
      aspectRatio
      transcodingState
    }
  }
`)

const ADMIN_MODERATED_MESSAGES_QUERY = gql(/* GraphQL */ `
  query AdminModeratedMessages(
    $moderationResult: ModerationResult!
    $includeUserContentReports: Boolean
    $after: String
  ) {
    adminModeratedMessages(
      moderationResult: $moderationResult
      includeUserContentReports: $includeUserContentReports
      after: $after
      first: 50
    ) {
      edges {
        node {
          ...ModeratedMessageItem
        }
      }

      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`)
