import { useQuery } from "@apollo/client"
import { useState } from "react"
import { Link } from "react-router-dom"
import { useDebounce } from "use-debounce"
import { gql } from "~/__generated__"
import { useViewer } from "~/auth/use-viewer"
import { BlockButton } from "~/common/block-button"
import { formatDateLong } from "~/common/dates"
import { adminUserDetailPath } from "~/common/paths"
import { AppText } from "~/ui/app-text"
import { Button } from "~/ui/button"
import { GraphqlError } from "~/ui/errors"
import { Input } from "~/ui/input"
import { TableContainer } from "~/ui/table"

const ADMIN_USERS_QUERY = gql(/* GraphQL */ `
  query AdminAllUsers($userRoles: [UserRole!], $after: String, $query: String) {
    users(userRoles: $userRoles, after: $after, first: 50, excludeDemoUsers: true, query: $query) {
      edges {
        node {
          ...UserOverview
        }
      }

      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`)

export const AdminUsersScreen = () => {
  const { viewer } = useViewer()

  const [query, setQuery] = useState("")
  const [debouncedQuery] = useDebounce(query, 250)

  const {
    data: currentData,
    previousData,
    error,
    loading,
    fetchMore,
  } = useQuery(ADMIN_USERS_QUERY, {
    variables: { query: debouncedQuery },
  })
  const data = currentData || previousData

  const pageInfo = data?.users.pageInfo

  const users = data?.users?.edges?.map((edge) => edge.node)

  return (
    <div>
      <AppText variant="h1" className="mb-4">
        Users
      </AppText>

      <Input
        type="text"
        onChange={(e) => setQuery(e.target.value)}
        placeholder="Search"
        className="mb-4"
      />

      {loading && !users ? null : error || !users ? (
        <GraphqlError error={error} />
      ) : (
        <>
          <TableContainer className="mb-8">
            <table className="table">
              <thead>
                <tr>
                  <th align="left">ID</th>
                  <th align="left">Username</th>
                  <th align="left">Display name</th>
                  <th align="left">Phone</th>
                  <th align="left">Status</th>
                  <th />
                  <th />
                </tr>
              </thead>

              <tbody>
                {users.map((user) => (
                  <tr key={user.id}>
                    <td>
                      <AppText variant="body3">{user.id}</AppText>
                    </td>
                    <td>
                      <AppText variant="body3">{user.username}</AppText>
                      <AppText variant="caption" className="text-neutral-400">
                        Created: {formatDateLong(user.createdAt)}
                      </AppText>
                    </td>
                    <td>
                      <AppText variant="body3">{user.displayOrUsername}</AppText>
                    </td>
                    <td>
                      <AppText variant="body3">{user.phoneNumber}</AppText>
                    </td>
                    <td>
                      <AppText variant="body3">{user.userStatus}</AppText>
                    </td>
                    <td align="right">
                      {user.id !== viewer.id && (
                        <BlockButton id={user.id} userStatus={user.userStatus} />
                      )}
                    </td>
                    <td align="right">
                      <Link to={adminUserDetailPath({ id: user.id })}>
                        <Button variant="outline" size="sm">
                          View Details
                        </Button>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </TableContainer>

          {pageInfo?.hasNextPage && pageInfo.endCursor && (
            <div className="mb-8 mt-4 flex items-center justify-center">
              <Button
                onClick={() => {
                  fetchMore({ variables: { after: pageInfo.endCursor } })
                }}
                variant="ghost"
                disabled={loading}
              >
                View More
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  )
}
