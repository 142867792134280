import { gql } from "~/__generated__"
import { useToast } from "~/ui/use-toast"
import { isMobile } from "~/common/platform"
import { VerificationLink } from "~/__generated__/graphql"
import { PhoneNumberValue, isValidPhoneNumber, parsedPhoneNumber } from "~/common/phone-number"
import { trackEvent, EVENTS } from "~/common/analytics"
import { downloadPath } from "~/common/paths"

export const GENERATE_VERIFICATION_LINK_MUTATION = gql(/* GraphQL */ `
  mutation GenerateVerificationLink($input: GenerateVerificationLinkInput!) {
    generateVerificationLink(input: $input) {
      success
      errors
    }
  }
`)

/**
 * Handles phone submission and sending verification links
 */
export const handlePhoneSubmission = async ({
  phoneValue,
  setPhoneValue,
  verificationLinkType,
  additionalInputData = {},
  trackingData = {},
  toast,
  generateVerificationLink,
  successTitle = "Success",
  successMessage,
}: {
  phoneValue: PhoneNumberValue
  setPhoneValue: (value: PhoneNumberValue) => void
  verificationLinkType: VerificationLink
  additionalInputData?: Record<string, unknown>
  trackingData?: Record<string, unknown>
  toast: ReturnType<typeof useToast>["toast"]
  generateVerificationLink: (options: { variables: { input: any } }) => Promise<any>
  successTitle?: string
  successMessage?: string
}): Promise<boolean> => {
  if (!isValidPhoneNumber(phoneValue)) {
    toast({
      title: "Invalid Phone Number",
      description: "Please enter a valid phone number",
      variant: "destructive",
    })
    return false
  }

  const cleanPhoneNumber = parsedPhoneNumber(phoneValue)

  const trackItemPressed = (success: boolean, label?: string) => {
    trackEvent(EVENTS.ITEM_PRESSED, {
      ...trackingData,
      phone_number: cleanPhoneNumber,
      success,
      label,
    })
  }

  const prevPhoneValue = phoneValue
  setPhoneValue({
    ...phoneValue,
    number: "",
  })

  try {
    const result = await generateVerificationLink({
      variables: {
        input: {
          phoneNumber: cleanPhoneNumber,
          type: verificationLinkType,
          ...additionalInputData,
        },
      },
    })

    if (result.data?.generateVerificationLink?.success) {
      const defaultSuccessMessage = isMobile()
        ? "Download the app to join."
        : "Check your texts for a link to download the app."

      toast({
        title: successTitle,
        description: successMessage || defaultSuccessMessage,
        duration: 15000,
      })
      trackItemPressed?.(true)

      setTimeout(() => {
        window.location.href = downloadPath({})
      }, 3000)

      return true
    } else {
      const errorMessage =
        result.data?.generateVerificationLink?.errors?.[0] ||
        "Unable to send SMS. Please try again."
      throw new Error(errorMessage)
    }
  } catch (error) {
    setPhoneValue(prevPhoneValue)
    const errorMessage = error instanceof Error ? error.message : "An error occurred"
    console.error(errorMessage)
    trackItemPressed?.(false, errorMessage)

    /* Redirect to the download screen instead of showing error toast
    toast({
      title: "Error",
      description: errorMessage,
      variant: "destructive",
    })
    */
    setTimeout(() => {
      window.location.href = downloadPath({})
    }, 1000)

    return false
  }
}
