// Copied from chatbcc-app/src/utils/phone-number.ts
// TODO(Dan): Create a shared library for common logic between web and mobile

import {
  AsYouType,
  CountryCode,
  validatePhoneNumberLength,
  ParseError,
  parsePhoneNumberWithError,
  isPossiblePhoneNumber,
} from "libphonenumber-js/min"

export const US_CALLING_CODE = "1"
export const US_COUNTRY_CODE = "US"

export const digits = (value: string) => value.replace(/\D/g, "")

export const isTestNumber = (value: string) => {
  return value.startsWith("0")
}

export const isValidPhoneNumber = (value: PhoneNumberValue) => {
  return isPossiblePhoneNumber(value.number, value.countryCode) || isTestNumber(value.number)
}

export const parsedPhoneNumber = (value: PhoneNumberValue): string => {
  const numberStartsWithCallingCode = value.number.startsWith(value.callingCode)
  const phoneNumberWithCountryCode = `+${value.callingCode}${value.number}`
  const parsedNumber = parsePhoneNumberWithError(
    // To avoid duplicating calling code - when user entered calling code as part of the number.
    numberStartsWithCallingCode ? `+${value.number}` : phoneNumberWithCountryCode,
    {
      defaultCountry: value.countryCode,
    }
  )
  return parsedNumber?.number || phoneNumberWithCountryCode
}

export const getFlagEmoji = (countryCode: string) => {
  return [...countryCode.toUpperCase()]
    .map((char) => String.fromCodePoint(127397 + char.charCodeAt(0)))
    .reduce((a, b) => `${a}${b}`)
}

export type PhoneNumberValue = {
  number: string
  callingCode: string
  countryCode?: CountryCode
  flag: string
}

export const formatPhoneNumber = ({
  value,
  callingCode,
  countryCode,
}: {
  value: string
  callingCode: string
  countryCode: CountryCode | undefined
}) => {
  // Issue with attempting to delete phone number when number contains parentheses.
  // https://github.com/catamphetamine/libphonenumber-js/issues/225
  const needsFormatting =
    value.length > 3 + (value.startsWith(callingCode) ? callingCode.length : 0) &&
    !isTestNumber(value)

  const formattedNumber = needsFormatting ? new AsYouType(countryCode).input(value) : value

  if (
    validatePhoneNumberLength(formattedNumber, countryCode) !== "TOO_LONG" ||
    isTestNumber(formattedNumber)
  ) {
    return formattedNumber
  }

  return value
}

export const formatFullPhoneNumber = (phoneNumberString: string) => {
  if (isTestNumber(phoneNumberString)) {
    return phoneNumberString
  }
  try {
    const phoneNumber = parsePhoneNumberWithError(phoneNumberString)
    const nationalFormat = phoneNumber.format("NATIONAL") // "(123) 456-7890"

    if (phoneNumber.countryCallingCode.length > 0) {
      return `+${phoneNumber.countryCallingCode} ${nationalFormat}` // "+1 (123) 456-7890"
    }

    return nationalFormat
  } catch (error) {
    if (error instanceof ParseError) {
      console.error(error.message)
      return phoneNumberString
    }
    throw error
  }
}

export const handlePhoneNumberInput = (
  inputValue: string,
  currentValue: PhoneNumberValue,
  isPasted = false
): PhoneNumberValue => {
  try {
    const defaultCountry = currentValue.countryCode || US_COUNTRY_CODE
    const parsedNumber = parsePhoneNumberWithError(inputValue, defaultCountry)
    const parsedCountry = parsedNumber?.country || defaultCountry

    if (validatePhoneNumberLength(inputValue, defaultCountry) === "TOO_LONG") {
      return handlePhoneNumberInput(inputValue.slice(0, -1), currentValue, isPasted)
    }

    if (parsedNumber && isPasted) {
      const callingCode = parsedNumber.countryCallingCode
      const formattedNumber = formatPhoneNumber({
        value: digits(parsedNumber.nationalNumber),
        callingCode,
        countryCode: parsedCountry,
      })

      return {
        flag: parsedCountry ? getFlagEmoji(parsedCountry) : currentValue.flag,
        callingCode,
        countryCode: parsedCountry,
        number: formattedNumber,
      }
    }
  } catch (e) {
    if (e instanceof Error && e.message === "TOO_LONG") {
      // If number is too long, trim and try again
      return handlePhoneNumberInput(
        inputValue.length > 15 ? inputValue.slice(0, 15) : inputValue.slice(0, -1),
        currentValue,
        isPasted
      )
    }
    if (isPasted) {
      throw e
    }
  }

  // Handle manual input or fallback for failed paste parsing
  const formattedNumber = formatPhoneNumber({
    value: digits(inputValue),
    callingCode: currentValue.callingCode,
    countryCode: currentValue.countryCode,
  })

  return {
    ...currentValue,
    number: formattedNumber,
  }
}

export const isPastedPhoneNumber = (newValue: string, previousValue: string): boolean => {
  return digits(newValue).length - digits(previousValue).length > 7
}
