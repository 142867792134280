import { Control } from "react-hook-form"
import * as z from "zod"
import { CheckboxField } from "~/fields/checkbox-field"
import { TextField } from "~/fields/text-field"
import { FieldGroup } from "~/ui/field-group"

export type RoomWaitlistCodeFormValues = {
  code: string
  archived: boolean
}

export const roomWaitlistCodeSchema = z.object({
  code: z.string().min(3, { message: "Code must be at least 3 characters" }),
  archived: z.boolean().default(false),
})

export const RoomWaitlistCodeFields = ({
  control,
  showArchived = false,
  codeDisabled,
}: {
  control: Control<RoomWaitlistCodeFormValues>
  showArchived?: boolean
  codeDisabled?: boolean
}) => {
  return (
    <FieldGroup>
      <TextField
        control={control}
        name="code"
        label="Code"
        required={true}
        disabled={codeDisabled}
        placeholder="Enter waitlist code"
      />

      {showArchived && <CheckboxField control={control} name="archived" label="Archived" />}
    </FieldGroup>
  )
}
