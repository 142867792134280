import { zodResolver } from "@hookform/resolvers/zod/src/zod.js"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import * as z from "zod"
import { gql } from "~/__generated__"
import { adminAdminUsersPath } from "~/common/paths"
import { useFormErrors } from "~/common/use-form-errors"
import { useSafeMutation } from "~/common/use-safe-mutation"
import { TextField } from "~/fields/text-field"
import { Button } from "~/ui/button"
import { Form } from "~/ui/form"
import { FormActions } from "~/ui/form-actions"
import { PageHeader } from "~/ui/page-header"
import { useToast } from "~/ui/use-toast"

const ADMIN_USER_CREATE_MUTATION = gql(/* GraphQL */ `
  mutation AdminUserCreate($input: AdminUserCreateInput!) {
    adminUserCreate(input: $input) {
      user {
        id
      }
    }
  }
`)

const formSchema = z.object({
  email: z.string().email({ message: "Invalid email address" }),
})

type FormValues = z.infer<typeof formSchema>

export const AdminNewUserAdminScreen = () => {
  const [mutate, result] = useSafeMutation(ADMIN_USER_CREATE_MUTATION)
  const { toast } = useToast()
  const navigate = useNavigate()

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
    },
  })
  useFormErrors(form.setError, result)

  const onSubmit = async (values: FormValues) => {
    const result = await mutate({
      variables: {
        input: {
          email: values.email,
        },
      },
    })

    if (result.errors) {
      return
    }

    toast({
      title: "Admin created",
      variant: "default",
    })

    navigate(adminAdminUsersPath({}))
  }

  return (
    <div data-testid="admin-new-user-admin-screen">
      <PageHeader title="Create Admin" backPath={adminAdminUsersPath({})} />

      <div className="max-w-lg">
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex flex-col items-center space-y-4"
          >
            <TextField
              control={form.control}
              name="email"
              type="email"
              label="Email*"
              required={true}
              placeholder="Enter your email"
            />
            <FormActions>
              <div className="flex w-full flex-1">
                <Button type="submit" disabled={form.formState.isSubmitting}>
                  Create Admin
                </Button>
              </div>
            </FormActions>
          </form>
        </Form>
      </div>
    </div>
  )
}
