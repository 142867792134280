import { useEffect } from "react"

/**
 * Hook to set the theme-color meta tag
 * @param color - The color to set (hex, rgb, etc.)
 */
export const useThemeColor = (color: string): void => {
  useEffect(() => {
    // Look for existing theme-color meta tag
    let metaThemeColor = document.querySelector('meta[name="theme-color"]')

    // If it doesn't exist, create it
    if (!metaThemeColor) {
      metaThemeColor = document.createElement("meta")
      metaThemeColor.setAttribute("name", "theme-color")
      document.head.appendChild(metaThemeColor)
    }

    // Set the color
    metaThemeColor.setAttribute("content", color)

    // Cleanup function to reset when component unmounts
    return () => {
      // Reset to a default color when the component unmounts
      metaThemeColor.removeAttribute("content")
    }
  }, [color])
}
