import { CountryCode, getCountryCallingCode } from "libphonenumber-js/min"
import { useRef, useState, useEffect, forwardRef } from "react"
import countryList from "react-select-country-list"
import {
  PhoneNumberValue,
  US_CALLING_CODE,
  getFlagEmoji,
  handlePhoneNumberInput,
  isPastedPhoneNumber,
} from "../../common/phone-number"
import { cn } from "../../common/cn"
import { FlagButton } from "./flag-button"
import { AppText } from "../../ui/app-text"

type PhoneNumberInputProps = {
  value: PhoneNumberValue
  onChange: (value: PhoneNumberValue) => void
  onSubmit?: () => void
  className?: string
  inputClassName?: string
  error?: boolean
  disabled?: boolean
  placeholder?: string
}

export const PhoneNumberInput = forwardRef<HTMLInputElement, PhoneNumberInputProps>(
  (
    {
      value,
      onChange,
      onSubmit,
      className,
      inputClassName,
      error,
      disabled,
      placeholder = "Phone number",
    },
    ref
  ): JSX.Element => {
    const [isOpen, setIsOpen] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState(0)
    const prevNumberRef = useRef("")
    const listRef = useRef<HTMLDivElement>(null)
    const countries = countryList().getData()

    useEffect(() => {
      if (isOpen) {
        const handleKeyDown = (e: KeyboardEvent) => {
          if (e.key === "Escape") {
            setIsOpen(false)
            return
          }

          if (!["ArrowDown", "ArrowUp", "Enter"].includes(e.key)) return
          e.preventDefault()

          if (e.key === "Enter") {
            handleCountrySelect(countries[selectedIndex].value)
            return
          }

          const newIndex =
            e.key === "ArrowDown"
              ? (selectedIndex + 1) % countries.length
              : (selectedIndex - 1 + countries.length) % countries.length

          setSelectedIndex(newIndex)

          // Scroll the selected item into view
          const items = listRef.current?.getElementsByTagName("button")
          if (items?.[newIndex]) {
            items[newIndex].scrollIntoView({ block: "nearest" })
          }
        }

        document.addEventListener("keydown", handleKeyDown)
        document.addEventListener("click", () => setIsOpen(false))
        return () => {
          document.removeEventListener("keydown", handleKeyDown)
          document.removeEventListener("click", () => setIsOpen(false))
        }
      }
    }, [isOpen, selectedIndex, countries])

    useEffect(() => {
      if (isOpen) {
        const currentIndex = countries.findIndex((c) => c.value === value.countryCode)
        setSelectedIndex(currentIndex >= 0 ? currentIndex : 0)
      }
    }, [isOpen, countries, value.countryCode])

    const handleNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = event.target.value
      const isPastedOrAutocompleted = isPastedPhoneNumber(inputValue, prevNumberRef.current)
      prevNumberRef.current = value.number

      const newValue = handlePhoneNumberInput(inputValue, value, isPastedOrAutocompleted)
      onChange(newValue)
    }

    const getCallingCode = (countryCode: string) => {
      try {
        return getCountryCallingCode(countryCode as CountryCode) || US_CALLING_CODE
      } catch (error) {
        console.error(error)
        return US_CALLING_CODE
      }
    }

    const handleCountrySelect = (countryCode: string) => {
      const country = countries.find((c) => c.value === countryCode)
      if (country) {
        onChange({
          ...value,
          flag: countryCode,
          callingCode: getCallingCode(countryCode),
          countryCode: countryCode as CountryCode,
        })
      }
      setIsOpen(false)
    }

    return (
      <div className={cn("relative", className)}>
        <form
          onSubmit={(e) => {
            e.preventDefault()
            onSubmit?.()
          }}
          id="phone-number-form"
          name="phone-number-form"
        >
          <div
            className={cn(
              "flex items-center rounded-lg border border-[#141010] bg-[#FCFFF9]",
              error && "border-red-500",
              disabled && "bg-gray-50 opacity-50",
              inputClassName
            )}
          >
            <div className="relative m-2">
              <div
                className={cn("flex cursor-pointer items-center gap-1 rounded-full bg-[#E8E8E4]")}
                onClick={(e) => {
                  e.stopPropagation()
                  setIsOpen(!isOpen)
                }}
              >
                <FlagButton
                  countryCode={value.countryCode}
                  className={cn("px-2")}
                  aria-expanded={isOpen}
                  aria-haspopup="listbox"
                />
              </div>
              {isOpen && (
                <div
                  ref={listRef}
                  className="absolute bottom-full left-0 z-10 mt-1 max-h-60 w-64 overflow-auto rounded-md border border-gray-200 bg-white shadow-lg"
                  onClick={(e) => e.stopPropagation()}
                  role="listbox"
                  aria-activedescendant={`country-option-${countries[selectedIndex].value}`}
                >
                  {countries.map((country, index) => (
                    <button
                      key={country.value}
                      type="button"
                      role="option"
                      id={`country-option-${country.value}`}
                      aria-selected={index === selectedIndex}
                      className={cn(
                        "flex w-full items-center px-4 py-2 text-left",
                        index === selectedIndex ? "bg-gray-100" : "hover:bg-gray-100"
                      )}
                      onClick={() => handleCountrySelect(country.value)}
                    >
                      <AppText variant="button" className="mr-2">
                        {getFlagEmoji(country.value)} {country.label} (+
                        {getCallingCode(country.value)})
                      </AppText>
                    </button>
                  ))}
                </div>
              )}
            </div>
            <AppText variant="button" className="flex-1">
              <input
                ref={ref}
                type="tel"
                value={value.number}
                onChange={handleNumberChange}
                placeholder={value.callingCode === US_CALLING_CODE ? "(555) 555-5555" : placeholder}
                className={cn(
                  "w-full border-none bg-transparent py-2 text-[18px] text-[#141010] placeholder-[#141010]/50 focus:outline-none",
                  error && "focus:ring-red-500"
                )}
                disabled={disabled}
                maxLength={15}
                id="phone-number-input"
                name="phone-number-input"
                autoComplete="tel"
              />
            </AppText>
          </div>
        </form>
      </div>
    )
  }
)
