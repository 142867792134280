import { BaseRoomInviterFragment } from "~/__generated__/graphql"
import { AppText } from "~/ui/app-text"
import { Button } from "~/ui/button"
import { TableContainer } from "~/ui/table"
import { gql } from "~/__generated__"
import { InvitationType } from "~/__generated__/graphql"
import { TabButton, TabContainer } from "~/ui/tabs"

export const INVITATION_TYPE_OPTIONS = [
  { value: InvitationType.Member, label: "Chatter" },
  { value: InvitationType.Follower, label: "Follower" },
]

export const RoomInvitersList = ({
  invitationType,
  setInvitationType,
  roomInviters,
  hasNextPage,
  loading,
  onLoadMore,
}: {
  invitationType: InvitationType
  setInvitationType: (type: InvitationType) => void
  roomInviters: BaseRoomInviterFragment[]
  hasNextPage: boolean
  loading: boolean
  onLoadMore: () => void
}) => {
  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between">
        <AppText variant="h2">Referrals</AppText>
      </div>

      <TabContainer>
        {INVITATION_TYPE_OPTIONS.map((type) => (
          <TabButton
            key={type.value}
            active={invitationType === type.value}
            onClick={() => setInvitationType(type.value)}
          >
            {type.label}
          </TabButton>
        ))}
      </TabContainer>

      {roomInviters.length === 0 ? (
        <AppText variant="body2">No referrals</AppText>
      ) : (
        <>
          <TableContainer>
            <table className="table">
              <thead>
                <tr>
                  <th align="left">Username</th>
                  <th align="left">Display Name</th>
                  <th align="left">Count</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {roomInviters.map((code) => (
                  <tr key={code.id}>
                    <td>
                      <AppText variant="body3">{code.username}</AppText>
                    </td>
                    <td>
                      <AppText variant="body3">{code.displayName}</AppText>
                    </td>
                    <td>
                      <AppText variant="body3">{code.count}</AppText>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </TableContainer>

          {hasNextPage && (
            <div className="mt-4 flex items-center justify-center">
              <Button onClick={onLoadMore} variant="ghost" disabled={loading}>
                View More
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export const BASE_ROOM_INVITER_FRAGMENT = gql(`
  fragment BaseRoomInviter on RoomInviter {
    id
    username
    displayName
    count
  }
`)
