import { ShareRoomFragment } from "~/__generated__/graphql"
import { cn } from "~/common/cn"
import { AppText } from "~/ui/app-text"
import { UserAvatar } from "~/ui/user-avatar"
import React, { useRef, useEffect } from "react"

interface RoomContentCardProps {
  roomData: ShareRoomFragment
  className?: string
  compact?: boolean
}

/**
 * Splits text into two parts based on a character limit per line
 * @param text The text to split
 * @param charLimit Maximum characters per line (default: 12)
 * @returns An array with two strings - first line and second line (with ellipsis if truncated)
 */
const balanceText = (text: string | null, charLimit = 12): [string, string] => {
  if (!text) return ["", ""]

  if (!text.includes(" ")) {
    if (text.length <= charLimit) return [text, ""]
    return [text.substring(0, charLimit - 3) + "...", ""]
  }

  const words = text.split(" ")
  const totalLength = text.length

  // If text can fit within two lines, balance it
  if (totalLength <= charLimit * 2) {
    // Find the best split point for balanced lines
    let bestSplitIndex = 1
    let bestDifference = Number.MAX_VALUE
    const halfLength = totalLength / 2

    for (let i = 1; i < words.length; i++) {
      const firstPartLength = words.slice(0, i).join(" ").length
      const difference = Math.abs(firstPartLength - halfLength)

      if (difference < bestDifference) {
        bestDifference = difference
        bestSplitIndex = i
      }
    }

    return [words.slice(0, bestSplitIndex).join(" "), words.slice(bestSplitIndex).join(" ")]
  }

  // Otherwise, maximize first line and truncate second if needed
  let firstLine = words[0]
  let i = 1

  // Add words to first line until we reach the limit
  while (i < words.length && firstLine.length + 1 + words[i].length <= charLimit) {
    firstLine += " " + words[i]
    i++
  }

  // If all words fit in first line
  if (i >= words.length) return [firstLine, ""]

  // Create and possibly truncate second line
  let secondLine = words.slice(i).join(" ")
  if (secondLine.length > charLimit) {
    secondLine = secondLine.substring(0, charLimit - 3) + "..."
  }

  return [firstLine, secondLine]
}

export const RoomContentCard = ({
  roomData,
  className,
  compact,
}: RoomContentCardProps): JSX.Element => {
  const scrollContainerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const slider = scrollContainerRef.current
    if (!slider) return

    let isDown = false
    let startX: number
    let scrollLeft: number
    let isDragging = false

    const handleMouseDown = (e: MouseEvent) => {
      isDown = true
      e.preventDefault()
      e.stopPropagation()
      slider.classList.add("cursor-grabbing")
      startX = e.pageX - slider.offsetLeft
      scrollLeft = slider.scrollLeft
      isDragging = false
    }

    const handleMouseLeave = (e: MouseEvent) => {
      if (!isDown) return
      isDown = false
      e.preventDefault()
      e.stopPropagation()
      slider.classList.remove("cursor-grabbing")
    }

    const handleMouseUp = (e: MouseEvent) => {
      if (!isDown) return
      isDown = false
      e.preventDefault()
      e.stopPropagation()
      slider.classList.remove("cursor-grabbing")
    }

    const handleMouseMove = (e: MouseEvent) => {
      if (!isDown) return
      e.preventDefault()
      e.stopPropagation()
      const x = e.pageX - slider.offsetLeft
      const walk = (x - startX) * 2 // Scroll speed multiplier
      slider.scrollLeft = scrollLeft - walk

      // If we've moved more than a few pixels, consider it a drag
      if (Math.abs(slider.scrollLeft - scrollLeft) > 5) {
        isDragging = true
      }
    }

    // Capture and suppress all click events during dragging
    const handleClick = (e: MouseEvent) => {
      if (isDragging) {
        e.preventDefault()
        e.stopPropagation()
        isDragging = false
      }
    }

    slider.addEventListener("mousedown", handleMouseDown)
    slider.addEventListener("mouseleave", handleMouseLeave)
    slider.addEventListener("mouseup", handleMouseUp)
    slider.addEventListener("mousemove", handleMouseMove)
    slider.addEventListener("click", handleClick, true)

    return () => {
      slider.removeEventListener("mousedown", handleMouseDown)
      slider.removeEventListener("mouseleave", handleMouseLeave)
      slider.removeEventListener("mouseup", handleMouseUp)
      slider.removeEventListener("mousemove", handleMouseMove)
      slider.removeEventListener("click", handleClick, true)
    }
  }, [])

  const chattersText = `${roomData.activeRoomMembershipsCount} ${roomData.activeRoomMembershipsCount === 1 ? "chatter" : "chatters"}`
  const membersText =
    (roomData.activeRoomFollowsCount &&
      ` · ${roomData.activeRoomFollowsCount} ${roomData.activeRoomFollowsCount === 1 ? "member" : "members"}`) ||
    ""
  const roomStats = `${chattersText}${membersText}`

  return (
    <div
      className={cn(
        "flex max-h-[297px] w-[346px] max-w-[346px] rounded-[24px] bg-gradient-to-b from-[#8400FF] via-[#1657FE] to-[#1657FE] p-[10px]",
        !compact && "md:max-h-[433px] md:w-[640px] md:max-w-[640px] md:p-[20px]",
        className
      )}
    >
      <div
        className={cn(
          "flex h-full w-full flex-col gap-4 overflow-hidden rounded-[17px] bg-[#FCFFF9] p-3",
          !compact && "md:p-4"
        )}
      >
        {/* Title and Stats and Description */}
        <div className="flex h-full flex-col gap-4">
          {/* Title and Stats */}
          <div className="flex w-full flex-row items-start gap-[11px]">
            <div className="flex w-full flex-row items-center gap-[11px]">
              {roomData.photoUrl && (
                <img
                  src={roomData.photoUrl}
                  className={cn(
                    "aspect-square w-[67px] rounded-[11px] border-[1px] object-contain",
                    !compact && "md:w-[90px]"
                  )}
                  alt="Room Avatar"
                />
              )}
              <div className="flex flex-1 flex-col justify-center">
                <AppText
                  variant="body3-tight"
                  className={cn(
                    "mb-0 text-[20px] font-bold text-[#141010]",
                    !compact && "md:text-[25px]"
                  )}
                >
                  {roomData.name}
                </AppText>
                <AppText
                  variant="caption-medium"
                  className={cn(
                    "mt-[4px] text-[#141010]",
                    !compact && "md:mt-[8px] md:text-[14px]"
                  )}
                >
                  {roomStats}
                </AppText>
              </div>
            </div>
          </div>

          {/* Description */}
          <AppText
            variant="body2-tight"
            className={cn(
              "line-clamp-2 overflow-hidden py-[2px] text-[#141010]",
              !compact && "md:text-[18px] md:leading-[22px]"
            )}
          >
            {roomData.description}
          </AppText>
        </div>

        {/* Members Grid */}
        {roomData.roomMembershipsPreview.length === 1 && (
          <div className={cn("mb-[-10px]", !compact && "md:mb-[-6px]")}>
            <AppText
              variant="body2-tight"
              className={cn(
                "line-clamp-2 overflow-hidden py-[2px] font-bold text-[#141010]",
                !compact && "md:text-[18px]"
              )}
            >
              Hosted by:
            </AppText>
          </div>
        )}
        <div className={cn("mx-[-12px] flex flex-col gap-2", !compact && "md:mx-[-16px]")}>
          <div
            ref={scrollContainerRef}
            className={cn(
              "flex cursor-grab select-none flex-row items-center justify-start overflow-x-auto scroll-smooth [-ms-overflow-style:none] [scrollbar-width:none] [&::-webkit-scrollbar]:hidden"
            )}
          >
            {roomData.roomMembershipsPreview.length === 1 &&
              roomData.roomMembershipsPreview
                .map((m) => m.user)
                .map((member, index) => (
                  <div
                    key={index}
                    className={cn(
                      "flex shrink-0 flex-row items-center gap-[11px] px-3",
                      !compact && "md:px-4"
                    )}
                  >
                    <UserAvatar
                      avatarUrl={member.avatarUrl}
                      displayName={member.displayOrUsername || ""}
                      size={"shareCardSingle"}
                      compact={compact}
                    />
                    <div className="w-full rounded-full py-1">
                      <AppText
                        variant="body3-medium"
                        className={cn(
                          "line-clamp-2 text-center leading-[18px] text-[#141010] text-misc-base-black-70",
                          !compact && "md:text-[18px] md:leading-[22px]"
                        )}
                      >
                        {member.displayOrUsername}
                      </AppText>
                    </div>
                  </div>
                ))}
            {roomData.roomMembershipsPreview.length > 1 &&
              roomData.roomMembershipsPreview
                .map((m) => m.user)
                .slice(0, 30)
                .map((member, index) => (
                  <div
                    key={index}
                    className={cn(
                      "flex w-[92px] shrink-0 flex-col items-center",
                      !compact && "md:w-[136px]"
                    )}
                  >
                    <UserAvatar
                      avatarUrl={member.avatarUrl}
                      displayName={member.displayOrUsername || ""}
                      size={"shareCard"}
                      compact={compact}
                    />
                    <div className={cn("h-[44px]", !compact && "md:h-[53px]")}>
                      <div className={cn("mt-[2px] w-full rounded-full py-1")}>
                        <AppText
                          variant="body3-tight"
                          className={cn(
                            "line-clamp-2 w-full text-center text-[12px] leading-[15px] text-misc-base-black-70",
                            !compact && "md:text-[16px] md:leading-[20px]"
                          )}
                        >
                          {(() => {
                            const charLimit = compact ? 12 : 12
                            const [firstLine, secondLine] = balanceText(
                              member.displayOrUsername,
                              charLimit
                            )
                            return secondLine ? (
                              <>
                                {firstLine}
                                <br />
                                {secondLine}
                              </>
                            ) : (
                              firstLine
                            )
                          })()}
                        </AppText>
                      </div>
                    </div>
                  </div>
                ))}
          </div>
        </div>
      </div>
    </div>
  )
}
