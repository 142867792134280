// chatbcc-api/app/frontend/fields/checkbox-field.tsx
import { InputHTMLAttributes } from "react"
import { Control, FieldPath, FieldValues } from "react-hook-form"
import { cn } from "~/common/cn"
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "~/ui/form"
import { Checkbox } from "../ui/checkbox"

export const CheckboxField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  label,
  disabled,
  className,
}: {
  control: Control<TFieldValues> | undefined
  label?: string
  name: TName
  disabled?: boolean
  className?: string
} & InputHTMLAttributes<HTMLInputElement>) => {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => {
        const checked = field.value === true

        return (
          <FormItem className={cn("flex items-center space-y-0", className)}>
            <div className="flex items-center">
              <FormControl>
                <Checkbox
                  {...field}
                  checked={checked}
                  disabled={disabled}
                  onBlur={field.onBlur}
                  onChange={(e) => {
                    field.onChange(e.target.checked)
                  }}
                />
              </FormControl>
              {!!label && <FormLabel className={cn("ml-2")}>{label}</FormLabel>}
            </div>
            <FormMessage className="text-xs" />
          </FormItem>
        )
      }}
    />
  )
}
