import { gql } from "~/__generated__"
import { Button } from "~/ui/button"
import { useToast } from "~/ui/use-toast"
import { useSafeMutation } from "./use-safe-mutation"

const ADMIN_ROOM_PUBLISH_MUTATION = gql(/* GraphQL */ `
  mutation PublishRoom($input: RoomPublishInput!) {
    roomPublish(input: $input) {
      room {
        ...BaseRoom
      }
    }
  }
`)

export const RoomPublishButton = ({ id }: { id: string }) => {
  const { toast } = useToast()
  const [mutate, result] = useSafeMutation(ADMIN_ROOM_PUBLISH_MUTATION)

  const publish = async () => {
    const confirmed = window.confirm("Are you sure?")
    if (!confirmed) {
      return
    }

    const result = await mutate({
      variables: {
        input: {
          id,
        },
      },
    })

    if (result.errors) {
      toast({
        title: "Publishing room failed",
        description: "Please try again",
        variant: "destructive",
      })
      return
    }

    toast({
      title: "Room published",
      variant: "default",
    })
  }

  return (
    <Button variant="outline" size="sm" onClick={publish} disabled={result.loading}>
      Publish
    </Button>
  )
}
