import "./share.css"
import { AppText } from "~/ui/app-text"
import logo from "~/images/landing/logo.ts"
import { useToast } from "~/ui/use-toast"
import { useState, useEffect, useRef } from "react"
import React from "react"
import { isAndroid, isIOS, isMobile } from "~/common/platform"
import {
  ShareMessageFragment,
  ShareRoomFragment,
  ShareRoomMemberFragment,
  VerificationLink,
} from "~/__generated__/graphql"
import { PhoneNumberInput } from "~/ui/phone-number/phone-number-input"
import { PhoneNumberValue, US_COUNTRY_CODE, getFlagEmoji } from "~/common/phone-number"
import { getMetaContent, getMetaContentMaybe } from "~/common/get-meta-content"
import { cn } from "~/common/cn"
import { useSafeMutation } from "~/common/use-safe-mutation"
import { UserAvatar } from "~/ui/user-avatar"
import { useThemeColor } from "~/hooks/use-theme-color"
import {
  GENERATE_VERIFICATION_LINK_MUTATION,
  handlePhoneSubmission,
} from "~/common/phone-submission"
import { SCREENS, EVENTS, EVENT_IDS, trackEvent } from "~/common/analytics"
import { useScreenTracking } from "~/hooks/use-screen-tracking"
import appStoreBadge from "~/images/store-badges/app-store-badge"
import playStoreBadge from "~/images/store-badges/play-store-badge"
import type { EmblaOptionsType } from "embla-carousel"
import useEmblaCarousel from "embla-carousel-react"
import Autoplay from "embla-carousel-autoplay"
import { useMediaQuery } from "~/hooks/use-media-query"
import { useKeyboardVisibility } from "~/hooks/use-keyboard-visibility"
import circleWavyCheck from "~/images/circle-wavy-check"
import avatar1 from "~/images/avatars/avatar1"
import avatar2 from "~/images/avatars/avatar2"
import avatar3 from "~/images/avatars/avatar3"
import avatar4 from "~/images/avatars/avatar4"
import avatar5 from "~/images/avatars/avatar5"
import chatCircleText from "~/images/chat-circle-text"

interface ShareScreenBaseProps {
  verificationLinkType: VerificationLink
  renderContent: () => React.ReactNode
  roomId?: string | null
  roomHandle?: string | null
  deeplinkUrl?: string
}

export const ShareScreenBase = ({
  verificationLinkType,
  renderContent,
  roomId,
  roomHandle,
  deeplinkUrl,
}: ShareScreenBaseProps): JSX.Element => {
  const isLargeScreen = useMediaQuery("(min-width: 1024px)")
  const [phoneNumberValue, setPhoneNumberValue] = useState<PhoneNumberValue>({
    number: "",
    callingCode: "1",
    countryCode: US_COUNTRY_CODE,
    flag: getFlagEmoji(US_COUNTRY_CODE),
  })
  const [isHighlighted, setIsHighlighted] = useState(false)
  const { isKeyboardVisible } = useKeyboardVisibility()
  const { toast } = useToast()
  const phoneInputRef = useRef<HTMLInputElement>(null)
  const hiddenPhoneInputHackRef = useRef<HTMLInputElement>(null)
  const footerRef = useRef<HTMLDivElement>(null)
  const [generateVerificationLink] = useSafeMutation(GENERATE_VERIFICATION_LINK_MUTATION)

  const autoDeeplinkRedirect = getMetaContentMaybe("auto-deeplink-redirect") === "true"
  useThemeColor("#ECFDDD")
  useScreenTracking(SCREENS.Share, {
    type: verificationLinkType,
    room_id: roomId,
    room_handle: roomHandle,
    deeplink_url: deeplinkUrl,
  })

  useEffect(() => {
    if (autoDeeplinkRedirect && deeplinkUrl && isMobile()) {
      window.location.href = deeplinkUrl
    }
  }, [autoDeeplinkRedirect, deeplinkUrl])

  const inviterDataContent = getMetaContentMaybe("inviter-data")
  const inviterData = inviterDataContent
    ? (JSON.parse(inviterDataContent) as ShareRoomMemberFragment)
    : null

  const roomDataContent = getMetaContentMaybe("room-data")
  const roomData = roomDataContent ? (JSON.parse(roomDataContent) as ShareRoomFragment) : null
  const roomMessagesPreview = roomData?.roomMessagesPreview as ShareMessageFragment[]

  const handleLogoPress = () => {
    trackEvent(EVENTS.ITEM_PRESSED, {
      unique_id: EVENT_IDS.SHARE_LOGO,
      room_id: roomId,
      room_handle: roomHandle,
    })
  }

  const handleJoinRoom = async () => {
    await handlePhoneSubmission({
      phoneValue: phoneNumberValue,
      setPhoneValue: setPhoneNumberValue,
      verificationLinkType,
      additionalInputData: {
        roomId: roomId,
        roomHandle: roomHandle,
        inviterId: inviterData?.id || null,
      },
      trackingData: {
        unique_id: EVENT_IDS.SHARE_SCREEN_SKIP_WAITLIST,
        inviter_id: inviterData?.id || undefined,
        inviter_username: inviterData?.username || undefined,
        room_id: roomId || undefined,
        deeplink_url: deeplinkUrl || undefined,
        room_handle: roomHandle || undefined,
      },
      toast,
      generateVerificationLink,
      successTitle: "You skipped the waitlist!",
      successMessage: isMobile()
        ? "Download the app to join the group chat."
        : "Check your texts for a link to download the app.",
    })
  }

  const handlePageClick = () => {
    //phoneInputRef.current?.focus()
    setIsHighlighted(true)
    setTimeout(() => setIsHighlighted(false), 500)
  }

  /**
   * Manually focus the input for Safari so it doesn't fuck up our footer:
   *
   * Works around three issues:
   * 1. Safari doesn't honor fixed positioning with the keyboard up
   * 2 .Safari doesn't honor interactive-widget=resizes-content meta tag
   *    https://developer.chrome.com/blog/viewport-resize-behavior/
   * 2. iOS will auto-scroll for inputMode="tel" to provide auto-complete
   *
   * This crazy hack does the following:
   * 1. Prevents the default focus behavior
   * 2. Focuses a fake/hidden tel input first to bring up the keyboard
   * 3. Focuses the real input after setting type=number to avoid auto-complete issue
   * 4. Sets inputMode=tel which changes the keyboard without re-focusing the input
   */
  const handleManualFocus = (e: React.MouseEvent) => {
    if (isIOS()) {
      // On iOS, we need to prevent the default focus behavior
      // and handle it manually to avoid the automatic scroll
      e.preventDefault()

      if (
        phoneInputRef.current?.focus &&
        // If the input has a value, don't interfere
        (phoneInputRef.current?.value.length > 0 ||
          // If the hidden input is already in the viewport, avoid flicker
          (hiddenPhoneInputHackRef.current &&
            isKeyboardVisible &&
            hiddenPhoneInputHackRef.current.getBoundingClientRect().bottom <=
              (window.visualViewport?.height || 0)))
      ) {
        return
      }

      // Focus the input
      if (hiddenPhoneInputHackRef.current && phoneInputRef.current) {
        hiddenPhoneInputHackRef.current.focus()
      }
      setTimeout(() => {
        if (phoneInputRef.current && hiddenPhoneInputHackRef.current) {
          phoneInputRef.current.type = "number"
          phoneInputRef.current.inputMode = "number"
          phoneInputRef.current.focus()
          phoneInputRef.current.type = "tel"
          //hiddenPhoneInputHackRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
      }, 20)
      setTimeout(() => {
        if (phoneInputRef.current && hiddenPhoneInputHackRef.current) {
          phoneInputRef.current.inputMode = "tel" // setting type=tel would trigger re-focus
        }
      }, 100)
    }
  }

  const handleStoreButtonClick = (storeType: "app_store" | "play_store") => {
    trackEvent(EVENTS.ITEM_PRESSED, {
      unique_id:
        storeType === "app_store"
          ? EVENT_IDS.DOWNLOAD_APP_STORE_CLICKED
          : EVENT_IDS.DOWNLOAD_PLAY_STORE_CLICKED,
      room_id: roomId,
      room_handle: roomHandle,
    })
  }

  // Set up embla carousel for testimonials
  const options: EmblaOptionsType = {
    axis: "x",
    loop: true,
    dragFree: false,
    skipSnaps: false,
    align: "center",
    containScroll: "trimSnaps",
  }

  const [emblaRef] = useEmblaCarousel(options, [
    Autoplay({
      delay: 3500,
      stopOnInteraction: false,
      stopOnMouseEnter: false,
    }),
  ])

  // Constants for store URLs
  const APP_STORE_URL = getMetaContent("APP_STORE_URL")
  const PLAY_STORE_URL = getMetaContent("PLAY_STORE_URL")

  // Testimonial data
  const testimonials = [
    {
      id: 1,
      name: "Syreeta L",
      initial: "S",
      bgColor: "support-orange",
      text: '"This feels so good! Whatever is happening here set-up wise is better than X / Bluesky / Reddit. Love it."',
    },
    {
      id: 2,
      name: "Elizabeth B",
      initial: "E",
      bgColor: "support-green",
      text: '"I loooove it here!!"',
    },
    {
      id: 3,
      name: "Jamie K",
      initial: "J",
      bgColor: "support-blue",
      text: '"I\'ve been waiting for something like this. Such a refreshing way to connect!"',
    },
    {
      id: 4,
      name: "Marcus T",
      initial: "M",
      bgColor: "support-lilac",
      text: '"The conversation quality here is unmatched. Finally found my online community."',
    },
  ]

  return (
    <section className="ShareContent overflow-none overscroll-none">
      <div
        className="relative mb-[88px] h-[calc(100dvh-88px)] overflow-auto overscroll-none"
        onClick={handlePageClick}
      >
        <div className="flex w-full flex-col bg-gradient-to-b from-support-soft-lime from-20% to-base-white px-4 py-8">
          <div className="mb-6 flex justify-center">
            <img
              src={logo.src}
              alt="ChatBCC Logo"
              className="h-[43px] cursor-default"
              loading="eager"
              onClick={handleLogoPress}
            />
          </div>

          <div className="flex h-full flex-col gap-6">
            {/* Title and Description */}
            <div className="mx-auto flex flex-col items-center">
              <div className="mx-auto flex flex-col justify-center lg:flex-row">
                <AppText
                  variant="display"
                  className="px-1 text-center text-[49px] leading-[49px] text-base-black lg:text-[79px] lg:leading-[79px]"
                >
                  Crash the
                </AppText>
                <AppText
                  variant="display"
                  className="px-1 text-center text-[49px] leading-[49px] text-base-blue [-webkit-text-stroke:0.8px_#141010] [text-stroke:0.8px_#141010] lg:text-[79px] lg:leading-[79px]"
                >
                  GROUP CHAT
                </AppText>
              </div>
              <AppText variant="body1" className="text-base-black max-lg:hidden">
                Amazing people having real conversations about topics you love.
              </AppText>
            </div>

            {/* Room Content Card */}
            <div className="flex flex-col items-center justify-center gap-6">
              {inviterData && inviterData.displayOrUsername && (
                <div className="mx-auto flex max-w-[300px] items-center gap-3 rounded-[300px] bg-white pr-3">
                  <UserAvatar
                    avatarUrl={inviterData.avatarUrl}
                    displayName={inviterData.displayOrUsername}
                    size="sm"
                  />
                  <AppText variant="body3" className="py-1 text-[#141010]">
                    {inviterData.shortDisplayName} invited you to skip the waitlist
                  </AppText>
                </div>
              )}
              {renderContent()}
            </div>
          </div>
        </div>

        {/* Value Prop Section */}
        <div className="border-y border-neutral-200 bg-base-white">
          <div className="mx-auto w-full max-w-[640px] rounded-none p-8 lg:py-16">
            <div className="flex flex-col gap-6">
              {/* Community Section */}
              <div className="flex flex-col gap-6">
                <div className="flex flex-col items-center text-center">
                  <AppText
                    variant={isLargeScreen ? "display" : "h1"}
                    className="uppercase text-base-black lg:pb-4"
                  >
                    Join the community
                  </AppText>
                </div>

                {/* Member Avatars */}
                {roomData?.activeRoomFollowsCount && roomData?.activeRoomFollowsCount >= 100 && (
                  <div className="flex justify-center">
                    <div className="flex items-center rounded-[300px] bg-base-blue-200 px-3 py-2">
                      <div className="mr-2 flex -space-x-2">
                        <img
                          src={avatar1.src}
                          alt="Avatar 1"
                          width={24}
                          height={24}
                          className="h-[24px] w-[24px] object-cover"
                        />
                        <img
                          src={avatar2.src}
                          alt="Avatar 2"
                          width={24}
                          height={24}
                          className="h-[24px] w-[24px] object-cover"
                        />
                        <img
                          src={avatar3.src}
                          alt="Avatar 3"
                          width={24}
                          height={24}
                          className="h-[24px] w-[24px] object-cover"
                        />
                        <img
                          src={avatar4.src}
                          alt="Avatar 4"
                          width={24}
                          height={24}
                          className="h-[24px] w-[24px] object-cover"
                        />
                        <img
                          src={avatar5.src}
                          alt="Avatar 5"
                          width={24}
                          height={24}
                          className="h-[24px] w-[24px] object-cover"
                        />
                      </div>
                      <AppText variant="body3" className="text-base-black opacity-80">
                        {roomData?.activeRoomFollowsCount} members
                      </AppText>
                    </div>
                  </div>
                )}
              </div>

              {/* Feature List */}
              <div className="flex lg:w-[640px]">
                <div className="flex flex-col gap-7 px-4 lg:grid lg:w-[640px] lg:grid-cols-2 lg:px-0">
                  <AppText variant={isLargeScreen ? "body1" : "body2"} className="text-base-black">
                    ✅ &nbsp;Get insider access to the chat
                  </AppText>
                  <AppText variant={isLargeScreen ? "body1" : "body2"} className="text-base-black">
                    ✅ &nbsp;Share comments & reactions
                  </AppText>
                  <AppText variant={isLargeScreen ? "body1" : "body2"} className="text-base-black">
                    ✅ &nbsp;Interact with the{" "}
                    {roomData?.activeRoomMembershipsCount === 1 ? "host" : "chatters"}
                  </AppText>
                  <AppText variant={isLargeScreen ? "body1" : "body2"} className="text-base-black">
                    ✅ &nbsp;Connect with the community
                  </AppText>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Chat Highlights Section */}
        <div className={cn("w-full bg-[#F0F8FF]", roomMessagesPreview?.length > 0 || "hidden")}>
          <div className="mx-auto flex max-w-[640px] flex-col px-4 py-8 lg:py-16">
            <AppText
              variant={isLargeScreen ? "display" : "h1"}
              className="pb-6 text-center uppercase text-base-black lg:pb-10"
            >
              🔥 Chat Highlights 🔥
            </AppText>

            <div className="flex flex-col lg:items-center">
              <div className="flex flex-col gap-6">
                {roomMessagesPreview && roomMessagesPreview.length > 0 ? (
                  roomMessagesPreview.map((message, index) => {
                    // Clean up string values that might be double-encoded JSON strings
                    const textContent =
                      typeof message.textContent === "string"
                        ? message.textContent.replace(/^"(.*)"$/, "$1") // Remove outer quotes if present
                        : ""

                    const user = message.user || {}
                    const avatarUrl =
                      user &&
                      typeof user === "object" &&
                      "avatarUrl" in user &&
                      typeof user.avatarUrl === "string"
                        ? user.avatarUrl.replace(/^"(.*)"$/, "$1")
                        : null

                    const displayName =
                      user &&
                      typeof user === "object" &&
                      "displayOrUsername" in user &&
                      typeof user.displayOrUsername === "string"
                        ? user.displayOrUsername.replace(/^"(.*)"$/, "$1")
                        : "User"

                    return (
                      <React.Fragment key={index}>
                        {index > 0 && (
                          <div className="h-[1px] w-full bg-base-black opacity-20"></div>
                        )}
                        <div className="flex flex-col gap-2">
                          <div className="flex">
                            <div className="mb-10 mr-1 mt-auto h-8 w-8 flex-shrink-0 overflow-hidden rounded-full">
                              <UserAvatar
                                avatarUrl={avatarUrl}
                                displayName={displayName}
                                size="sm"
                              />
                            </div>
                            <div className="flex w-full flex-col">
                              <div className="relative ml-1">
                                <div className="w-full max-w-[360px] rounded-md bg-white px-4 py-2">
                                  <div className="mb-2">
                                    <div className="flex items-center">
                                      <AppText
                                        variant="hairline"
                                        className="mr-1 text-15 text-base-black opacity-80"
                                      >
                                        {displayName}
                                      </AppText>
                                      <img
                                        src={circleWavyCheck.src}
                                        width={13}
                                        height={13}
                                        alt="Verified"
                                      />
                                    </div>
                                    <AppText variant="body2" className="mt-2 text-base-black">
                                      {textContent?.split("\n").map((line, i) => (
                                        <React.Fragment key={i}>
                                          {line}
                                          {i < textContent.split("\n").length - 1 && <br />}
                                        </React.Fragment>
                                      ))}
                                    </AppText>
                                    {message?.attachments?.length > 0 && (
                                      <div className="mt-2 flex">
                                        {message.attachments
                                          .filter((attachment) => attachment.imageUrl?.length)
                                          .slice(0, 1)
                                          .map((attachment, index) => (
                                            <img
                                              key={`attachment-${index}`}
                                              src={attachment.imageUrl || ""}
                                              alt="attachment"
                                            />
                                          ))}
                                      </div>
                                    )}
                                  </div>
                                  <div className="flex justify-end">
                                    <AppText variant="tiny" className="text-base-black opacity-60">
                                      {message.createdAt
                                        ? (() => {
                                            const date = new Date(message.createdAt)
                                            date.setHours(date.getHours())
                                            return date.toLocaleTimeString("en-US", {
                                              hour: "numeric",
                                              minute: "2-digit",
                                              hour12: true,
                                            })
                                          })()
                                        : ""}
                                    </AppText>
                                  </div>
                                </div>
                              </div>

                              {/* Reactions */}
                              <div className="mt-2 flex flex-wrap gap-1 pl-2">
                                {message.childMessagesCount > 0 &&
                                  message.childMessageUsers &&
                                  message.childMessageUsers.length > 0 && (
                                    <div className="flex h-8 items-center rounded-full bg-white px-3 shadow-sm">
                                      <div className="flex -space-x-1.5">
                                        {message.childMessageUsers
                                          .slice(0, isLargeScreen ? 3 : 2)
                                          .map((user, userIndex) => {
                                            const userAvatarUrl =
                                              typeof user.avatarUrl === "string"
                                                ? user.avatarUrl.replace(/^"(.*)"$/, "$1")
                                                : null

                                            const userDisplayName =
                                              typeof user.shortDisplayName === "string"
                                                ? user.shortDisplayName.replace(/^"(.*)"$/, "$1")
                                                : "User"

                                            return (
                                              <div
                                                key={userIndex}
                                                className="h-5 w-5 overflow-hidden rounded-full border border-white"
                                              >
                                                <UserAvatar
                                                  avatarUrl={userAvatarUrl}
                                                  displayName={userDisplayName}
                                                  size="xsm"
                                                />
                                              </div>
                                            )
                                          })}
                                        <img
                                          src={chatCircleText.src}
                                          alt="Chat Bubble"
                                          className="h-5 w-5"
                                        />
                                      </div>
                                      <AppText variant="body3" className="ml-1 text-base-black">
                                        {message.childMessagesCount}
                                      </AppText>
                                    </div>
                                  )}
                                {message.reactionGroups && message.reactionGroups.length > 0
                                  ? message.reactionGroups
                                      .slice(0, isLargeScreen ? 4 : 3)
                                      .map((reaction, reactionIndex) => {
                                        const emoji =
                                          typeof reaction.emoji === "string"
                                            ? reaction.emoji.replace(/^"(.*)"$/, "$1")
                                            : "👍"

                                        return (
                                          <div
                                            key={reactionIndex}
                                            className="flex h-8 items-center rounded-full bg-white px-2 shadow-sm"
                                          >
                                            <AppText variant="body3" className="text-base-black">
                                              {emoji} {reaction.reactionCount}
                                            </AppText>
                                          </div>
                                        )
                                      })
                                  : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    )
                  })
                ) : (
                  <div className="flex justify-center">
                    <AppText variant="body2" className="text-base-black opacity-70">
                      No messages to display
                    </AppText>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* You'll Love it Here Section */}
        <div className="bg-gradient-to-b from-base-blue from-20% to-base-white pt-8 lg:pt-16">
          {/* Testimonials */}
          <div className="mx-auto w-full max-w-[640px]">
            <div className="mb-4 flex flex-col items-center lg:pb-4">
              <AppText
                variant={isLargeScreen ? "display" : "h1"}
                className="uppercase text-base-black"
              >
                You'll love it here!
              </AppText>
            </div>
            <div className="relative">
              <div className="embla" ref={emblaRef}>
                <div className="embla__container">
                  {testimonials.map((testimonial) => (
                    <div key={testimonial.id} className="embla__slide">
                      <div className="mx-auto flex h-full max-w-[340px] flex-col gap-4 rounded-[20px] bg-base-white p-4 shadow-sm">
                        <div className="flex items-center gap-2">
                          <UserAvatar
                            displayName={testimonial.name}
                            size="sm"
                            className={`bg-${testimonial.bgColor}`}
                          />
                          <AppText variant="body3-medium" className="text-black">
                            {testimonial.name}
                          </AppText>
                        </div>
                        <AppText variant="body2" className="text-black">
                          {testimonial.text}
                        </AppText>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* App Store Reviews Section */}
          <div className="space-y-6 pb-6 pt-6 lg:pt-4">
            <div className="flex flex-col items-center space-y-4">
              {/* Five Star Rating */}
              <div className="flex justify-center space-x-1">
                {[...Array(5)].map((_, i) => (
                  <svg
                    key={i}
                    width={isLargeScreen ? "32" : "24"}
                    height={isLargeScreen ? "32" : "24"}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 1.5L15.09 7.76L22 8.77L17 13.64L18.18 20.52L12 17.27L5.82 20.52L7 13.64L2 8.77L8.91 7.76L12 1.5Z"
                      fill="#FFCE31"
                    />
                  </svg>
                ))}
              </div>

              {/* Store badges */}
              <div className="flex flex-row items-center justify-center gap-3">
                <a
                  href={APP_STORE_URL}
                  className="transition-transform hover:scale-105 active:scale-95"
                  aria-label="Download on App Store"
                  onClick={() => handleStoreButtonClick("app_store")}
                >
                  <img
                    src={appStoreBadge.src}
                    alt="Download on App Store"
                    height={40}
                    className="h-[40px] object-contain lg:h-[50px]"
                  />
                </a>
                <a
                  href={PLAY_STORE_URL}
                  className="transition-transform hover:scale-105 active:scale-95"
                  aria-label="Get it on Google Play"
                  onClick={() => handleStoreButtonClick("play_store")}
                >
                  <img
                    src={playStoreBadge.src}
                    alt="Get it on Google Play"
                    height={40}
                    className="h-[40px] object-contain lg:h-[50px]"
                  />
                </a>
              </div>

              {/* Join Now Section */}
              <div className="flex flex-col items-center space-y-2 pb-4 pt-4">
                <AppText
                  variant={isLargeScreen ? "h1" : "h2"}
                  className="font-medium uppercase text-base-black opacity-80"
                >
                  Join the chat now
                </AppText>
                <div className="flex justify-center">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10 3.125V16.875"
                      stroke="#141010"
                      strokeOpacity="0.8"
                      strokeWidth="2.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M4.375 11.25L10 16.875L15.625 11.25"
                      stroke="#141010"
                      strokeOpacity="0.8"
                      strokeWidth="2.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Footer Bar */}
      <div
        ref={footerRef}
        className={cn(
          "overflow-none fixed bottom-0 left-0 right-0 touch-none overscroll-none border-t border-neutral-200 bg-base-blue transition-all duration-500",
          isHighlighted && "bg-support-purple duration-500",
          isIOS() && "pb-[env(safe-area-inset-bottom)]",
          isAndroid() && !isKeyboardVisible && "pb-[env(safe-area-inset-bottom)]"
        )}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="mx-auto flex h-[88px] max-w-[360px] items-center gap-[11px] px-4">
          <div className="flex-1" onClick={handleManualFocus}>
            <PhoneNumberInput
              ref={phoneInputRef}
              value={phoneNumberValue}
              onChange={setPhoneNumberValue}
              onSubmit={handleJoinRoom}
              inputClassName="max-w-[240px]"
            />
          </div>

          <button
            onClick={handleJoinRoom}
            className="flex h-[48px] items-center gap-[6px] rounded-full border border-base-black bg-base-yellow px-4"
          >
            <AppText variant="button" className="text-base-black">
              Skip Waitlist
            </AppText>
          </button>
        </div>
        <input
          ref={hiddenPhoneInputHackRef}
          type="tel"
          id="hidden-phone-number-input"
          name="hidden-phone-number-input"
          autoComplete="tel"
          className="fixed bottom-0 left-0 h-1 w-1 bg-transparent"
        />
      </div>
    </section>
  )
}
