import React from "react"
import { AppText } from "~/ui/app-text"

export const AutoModerationDetails = ({ title, report }: { title: string; report: string }) => {
  return (
    <div>
      <AppText variant="body3-medium">{title}</AppText>

      <div>
        <AppText variant="caption">
          {report.split("\n").map((text, i) => (
            <React.Fragment key={i}>
              {text}
              <br />
            </React.Fragment>
          ))}
        </AppText>
      </div>
    </div>
  )
}
