import { useState, useEffect } from "react"
import { isIOS, isMobile } from "~/common/platform"

/**
 * A React hook that detects mobile keyboard visibility using viewport changes
 *
 * @returns An object containing isKeyboardVisible boolean
 */
export const useKeyboardVisibility = (): { isKeyboardVisible: boolean } => {
  const [isKeyboardVisible, setIsKeyboardVisible] = useState(false)

  // Effect for detecting keyboard visibility through viewport changes
  useEffect(() => {
    // Only run on mobile devices
    if (!isMobile()) return

    // Function to detect keyboard visibility based on viewport dimensions
    const detectKeyboard = (): void => {
      const viewportHeight = isIOS()
        ? window.innerHeight
        : window.visualViewport?.height || window.innerHeight
      const windowHeight = isIOS() ? window.outerHeight : window.screen.height
      const heightThreshold = windowHeight * 0.7 // 70% of screen height
      // If viewport height is significantly smaller than screen height, keyboard is likely visible
      setIsKeyboardVisible(viewportHeight < heightThreshold)
    }

    // Set up appropriate event listener based on platform
    if (!isIOS() && window.visualViewport) {
      // For non-iOS devices, prefer visualViewport when available
      window.visualViewport.addEventListener("resize", detectKeyboard)
    } else {
      // Fallback to window resize for iOS and older browsers
      window.addEventListener("resize", detectKeyboard)
    }

    // Initial detection
    detectKeyboard()

    // Clean up event listeners
    return () => {
      if (!isIOS() && window.visualViewport) {
        window.visualViewport.removeEventListener("resize", detectKeyboard)
      } else {
        window.removeEventListener("resize", detectKeyboard)
      }
    }
  }, [])

  return { isKeyboardVisible }
}
