import React from "react"
import { cn } from "../../common/cn"
import { getFlagEmoji } from "../../common/phone-number"

type FlagButtonProps = {
  countryCode?: string
  onPress?: () => void
  className?: string
}

export const FlagButton = ({ countryCode, onPress, className }: FlagButtonProps): JSX.Element => {
  return (
    <button type="button" onClick={onPress} className={cn("flex items-center gap-1", className)}>
      <span className="text-lg">{countryCode ? getFlagEmoji(countryCode) : "🌐"}</span>
      <svg
        className="h-4 w-4 text-gray-500"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
          clipRule="evenodd"
        />
      </svg>
    </button>
  )
}
