import { ProfilePictureColor } from "~/__generated__/graphql"
import { cn } from "~/common/cn"
import { AppText } from "./app-text"

const bgColorMap: Record<ProfilePictureColor, string> = {
  [ProfilePictureColor.Blue]: "bg-support-blue text-base-white",
  [ProfilePictureColor.Clay]: "bg-support-clay text-base-black",
  [ProfilePictureColor.Cream]: "bg-support-cream text-base-black",
  [ProfilePictureColor.Green]: "bg-support-green text-base-black",
  [ProfilePictureColor.Lime]: "bg-support-lime text-base-black",
  [ProfilePictureColor.Magenta]: "bg-support-magenta text-base-black",
  [ProfilePictureColor.Midnight]: "bg-support-midnight text-base-black",
  [ProfilePictureColor.Moss]: "bg-support-moss text-base-black",
  [ProfilePictureColor.Orange]: "bg-support-orange text-base-black",
  [ProfilePictureColor.Pink]: "bg-support-pink text-base-black",
  [ProfilePictureColor.Purple]: "bg-support-purple text-white",
  [ProfilePictureColor.Red]: "bg-support-red text-base-black",
  [ProfilePictureColor.Sky]: "bg-support-sky text-base-black",
  [ProfilePictureColor.SoftLime]: "bg-support-soft-lime text-base-black",
  [ProfilePictureColor.Yellow]: "bg-support-yellow text-base-black",
}

const keyFromString = (input: string): number => {
  let hash = 0

  for (let i = 0; i < input.length; i++) {
    hash = (hash << 5) - hash + input.charCodeAt(i)
    hash &= hash // Convert to 32bit integer
  }

  const min = 0
  const max = 14
  const range = max - min + 1
  const randomNumber = (((hash % range) + range) % range) + min

  return randomNumber
}

interface UserAvatarProps {
  avatarUrl?: string | null
  displayName: string
  size?: "xsm" | "sm" | "md" | "lg" | "shareCard" | "shareCardSingle"
  compact?: boolean
  className?: string
  imgClassName?: string
  textClassName?: string
}

export const UserAvatar = ({
  avatarUrl,
  displayName,
  size = "md",
  compact = false,
  className,
  imgClassName,
  textClassName,
}: UserAvatarProps): JSX.Element => {
  const sizeClasses = {
    xsm: "h-[20px] w-[20px]",
    sm: "h-[36px] w-[36px]",
    md: "h-[73px] w-[73px]",
    lg: "h-[99px] w-[99px]",
    shareCard: "w-[73px]",
    shareCardSingle: "w-[50px]",
  }

  const textSizeClasses = {
    xsm: "text-[10px]",
    sm: "text-[14px]",
    md: "text-[29px]",
    lg: "text-[40px]",
    shareCard: "text-[29px]",
    shareCardSingle: "text-[29px]",
  }

  if (avatarUrl) {
    return (
      <img
        src={avatarUrl}
        alt={displayName}
        className={cn(
          sizeClasses[size],
          size === "shareCard" && !compact && "md:w-[99px]",
          size === "shareCardSingle" && !compact && "md:w-[73px]",
          "aspect-square rounded-full border-[1px] object-cover",
          imgClassName
        )}
        loading="eager"
      />
    )
  }

  return (
    <div
      className={cn(
        "flex items-center justify-center rounded-full",
        sizeClasses[size],
        size === "shareCard" && !compact && "md:w-[99px]",
        "aspect-square rounded-full border-[1px]",
        bgColorMap[Object.values(ProfilePictureColor)[keyFromString(displayName)]],
        className
      )}
    >
      <AppText
        variant="body3"
        className={cn(
          "font-md-nichrome font-bold opacity-70",
          textSizeClasses[size],
          size === "shareCard" && !compact && "md:text-[40px]",
          textClassName
        )}
      >
        {displayName.charAt(0).toUpperCase()}
      </AppText>
    </div>
  )
}
