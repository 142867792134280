import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import { EVENTS, trackEvent } from "~/common/analytics"
import type { SCREENS } from "~/common/analytics"

const TRACKED_PARAMS = ["ref", "u", "r", "join"]

export const useScreenTracking = (
  name: SCREENS,
  additionalProperties?: Record<string, unknown>
): void => {
  const location = useLocation()

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const trackedParams = TRACKED_PARAMS.reduce<Record<string, string>>((acc, param) => {
      const value = searchParams.get(param)
      if (value) {
        acc[param] = value
      }
      return acc
    }, {})

    trackEvent(EVENTS.SCREEN_VIEWED, {
      name,
      params: location.search || undefined,
      url: window.location.href,
      ...trackedParams,
      ...additionalProperties,
    })
    // should only trigger on first render
  }, [])
}
