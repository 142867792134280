import invariant from "tiny-invariant"
import {
  AdminModerationAction,
  AttachmentType,
  ModeratedMessageItemFragment,
  TranscodingState,
} from "~/__generated__/graphql"
import { constrainImageSize } from "~/common/constrain-image-size"
import { Button } from "~/ui/button"
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "~/ui/dialog"
import ReactPlayer from "react-player"
import { AppText } from "~/ui/app-text"
import { AutoModerationDetails } from "./auto-moderation-details"
import { gql } from "~/__generated__"
import { useSafeMutation } from "~/common/use-safe-mutation"
import { toast } from "~/ui/use-toast"
import React from "react"
import { isNotNull } from "~/common/is-not-null"

const CONTENT_MAX_SIZE = 400

export const MessageModerationModal = ({
  message,
  includeActions,
  onClose,
}: {
  message: ModeratedMessageItemFragment
  includeActions: boolean
  onClose: () => void
}) => {
  const attachment = message.attachments.at(0)
  const [execModerateMessage] = useSafeMutation(ADMIN_MODERATE_MESSAGE_MUTATION)

  const moderate = async (moderationAction: AdminModerationAction) => {
    const result = await execModerateMessage({
      variables: {
        input: {
          messageId: message.id,
          moderationAction: moderationAction,
        },
      },
    })

    if (result.errors) {
      toast({
        title: "Error moderating message",
        variant: "destructive",
      })
      return
    }

    onClose()
    toast({
      title: `Message ${moderationAction === AdminModerationAction.Delete ? "Deleted" : "Permitted"}`,
      variant: "default",
    })
  }

  return (
    <Dialog open onOpenChange={(open) => !open && onClose()}>
      <DialogContent className="max-h-[90%] overflow-y-scroll sm:rounded-none">
        <DialogHeader>
          <DialogTitle>Message Details</DialogTitle>
        </DialogHeader>

        {message.textContent}

        <MessageImage attachment={attachment} />
        <MessageVideo attachment={attachment} />

        {includeActions && (
          <div>
            <Button variant="default" onClick={() => moderate(AdminModerationAction.Permit)}>
              Permit
            </Button>
            <Button variant="destructive" onClick={() => moderate(AdminModerationAction.Delete)}>
              Delete
            </Button>
          </div>
        )}

        <ContentReports
          descriptions={message.contentReports
            .map((report) => report.description)
            .filter(isNotNull)}
        />

        {message.moderationResponse?.textResponseReport && (
          <AutoModerationDetails
            title={`Text moderation response: ${message.moderationResponse.textResult}`}
            report={message.moderationResponse.textResponseReport}
          />
        )}
        {message.moderationResponse?.visualResponseReport && (
          <AutoModerationDetails
            title={`Visual moderation response: ${message.moderationResponse.visualResult}`}
            report={message.moderationResponse.visualResponseReport}
          />
        )}
      </DialogContent>
    </Dialog>
  )
}

const ContentReports = ({ descriptions }: { descriptions: string[] }) => {
  if (descriptions.length === 0) return null

  return (
    <div>
      <AppText variant="body3-medium">User Reports</AppText>

      <div>
        <AppText variant="caption">
          {descriptions.map((text, i) => (
            <React.Fragment key={i}>
              {text}
              <br />
            </React.Fragment>
          ))}
        </AppText>
      </div>
    </div>
  )
}

const MessageImage = ({
  attachment,
}: {
  attachment?: ModeratedMessageItemFragment["attachments"][number] | null
}) => {
  if (!attachment || attachment.attachmentType !== AttachmentType.Image) return null

  const imageUrl = attachment.imageUrl
  invariant(imageUrl, "expected image url")

  const imageSize = constrainImageSize(
    { height: attachment.height || 100, width: attachment.width || 100 },
    { height: CONTENT_MAX_SIZE, width: CONTENT_MAX_SIZE }
  )

  return <img src={imageUrl} height={imageSize.height} width={imageSize.width} />
}

const MessageVideo = ({
  attachment,
}: {
  attachment?: ModeratedMessageItemFragment["attachments"][number] | null
}) => {
  if (!attachment || attachment.attachmentType !== AttachmentType.Video) return null

  if (attachment.transcodingState === TranscodingState.Error)
    return <AppText variant="body2">Video transcoding error</AppText>
  if (attachment.transcodingState !== TranscodingState.Completed)
    return <AppText variant="body2">Video transcoding in progress</AppText>

  const playbackUrl = attachment.playbackUrl
  invariant(playbackUrl, "expected playback url")

  const [width, height] = aspectRatioToSize(attachment.aspectRatio || "1:1", CONTENT_MAX_SIZE)

  return <ReactPlayer url={playbackUrl} controls width={width} height={height} />
}

const aspectRatioToSize = (aspectRatio: string, maxSize: number) => {
  const [aspectWidth, aspectHeight] = (aspectRatio || "1:1").split(":").map((s) => parseFloat(s))

  let height = 100
  let width = 100

  if (aspectHeight > aspectWidth) {
    height = maxSize
    width = (maxSize * aspectWidth) / aspectHeight
  } else {
    width = maxSize
    height = (maxSize * aspectHeight) / aspectWidth
  }

  return [width, height]
}

export const ADMIN_MODERATE_MESSAGE_MUTATION = gql(`
  mutation AdminModerateMessage($input: AdminModerateMessageInput!) {
    adminModerateMessage(input: $input) {
      message {
        ...ModeratedMessageItem
      }
    }
  }
`)
