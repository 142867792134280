// type safe paths
// https://github.com/garybernhardt/static-path
//
// tip: unless you're adding the route to the router, always call these as a
// function even if they don't take parameters
// e.g.  <Link to={rootPath({})}> and not <Link to={rootPath.pattern}>

import { path } from "static-path"

export const rootPath = path("/")
export const adminLoginPath = path("/admin/login")
export const adminCredentialsLoginPath = path("/admin/login/credentials")
export const adminDashboardPath = path("/admin")
export const adminAdminUsersPath = path("/admin/admin-users")
export const adminNewAdminUserPath = path("/admin/admin-users/new")
export const adminUsersPath = path("/admin/users")
export const adminUserDetailPath = path("/admin/user/:id")
export const adminRoomsPath = path("/admin/chats")
export const adminUploadVipBadgePath = path("/admin/upload-vip-badge")
export const adminRoomPath = path("/admin/chats/:id")
export const adminEditRoomPath = path("/admin/chats/:id/edit")
export const adminModerationPath = path("/admin/moderation/:moderationResult")
export const detailsPath = path("/details")
export const profilePath = path("/profile")
export const downloadPath = path("/download")
export const landingPath = path("/landing")
export const comingSoonPath = path("/coming-soon")
export const privacyPath = path("/privacy")
export const termsPath = path("/terms")
export const supportPath = path("/support")
export const shareRoomPath = path("/:room_handle")
export const shareRoomCardPath = path("/:room_handle/card")
export const invitePath = path("/invite")

export const emailAuthPath = path("/auth/email/:email/:token/:clientAuthCode")
