import { useQuery } from "@apollo/client"
import { Link } from "react-router-dom"
import { gql } from "~/__generated__"
import { UserRole } from "~/__generated__/graphql"
import { useViewer } from "~/auth/use-viewer"
import { ArchiveButton } from "~/common/archive-button"
import { formatDateLong } from "~/common/dates"
import { adminNewAdminUserPath } from "~/common/paths"
import { AppText } from "~/ui/app-text"
import { Button } from "~/ui/button"
import { GraphqlError } from "~/ui/errors"
import { TableContainer } from "~/ui/table"

export const ADMIN_USER_FRAGMENT = gql(`
  fragment UserOverview on User {
    id
    email
    username
    displayOrUsername
    userStatus
    phoneNumber
    createdAt
  }
`)

const ADMIN_USERS_QUERY = gql(/* GraphQL */ `
  query AdminUsers($userRoles: [UserRole!], $after: String) {
    users(userRoles: $userRoles, after: $after, first: 50) {
      edges {
        node {
          ...UserOverview
        }
      }

      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`)

export const AdminAdminUsersScreen = () => {
  const { viewer } = useViewer()
  const { data, error, loading, fetchMore } = useQuery(ADMIN_USERS_QUERY, {
    variables: {
      userRoles: [UserRole.SystemAdmin, UserRole.SuperAdmin],
    },
  })
  const pageInfo = data?.users.pageInfo

  const users = data?.users?.edges?.map((edge) => edge.node)

  if (loading) {
    return null
  }

  if (error || !users) {
    return <GraphqlError error={error} />
  }

  return (
    <div>
      <AppText variant="h1">Admin Users</AppText>

      <div className="mb-4 mt-8 flex justify-end">
        <Button asChild={true}>
          <Link to={adminNewAdminUserPath({})}>Create New</Link>
        </Button>
      </div>

      <TableContainer>
        <table className="table">
          <thead>
            <tr>
              <th align="left">Email</th>
              <th />
            </tr>
          </thead>

          <tbody>
            {users.map((user) => (
              <tr key={user.id}>
                <td>
                  <AppText variant="body3">{user.email}</AppText>
                  <AppText variant="caption" className="text-neutral-400">
                    Created: {formatDateLong(user.createdAt)}
                  </AppText>
                </td>
                <td align="right">{user.id !== viewer.id && <ArchiveButton id={user.id} />}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </TableContainer>
      {pageInfo?.hasNextPage && pageInfo.endCursor && (
        <div className="mt-4 flex items-center justify-center">
          <Button
            onClick={() => {
              fetchMore({ variables: { after: pageInfo.endCursor } })
            }}
            variant="ghost"
            disabled={loading}
          >
            View More
          </Button>
        </div>
      )}
    </div>
  )
}
