// chatbcc-api/app/frontend/ui/input.tsx
import * as React from "react"
import TextareaAutosize, { TextareaAutosizeProps } from "react-textarea-autosize"
import { cn } from "~/common/cn"

// Base shared styling function
const getBaseStyles = (hasIcon: boolean) =>
  cn(
    "text-sm flex w-full border border-base-black bg-background px-5 py-2 ring-offset-background",
    "file:text-sm file:border-0 file:bg-transparent file:font-medium",
    "placeholder:text-muted-foreground",
    "focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring focus-visible:ring-offset-0",
    "disabled:cursor-not-allowed disabled:opacity-50",
    { "pl-12": hasIcon }
  )

// Input component props
export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  icon?: React.ReactNode
}

// TextArea component props
export interface TextareaProps extends Omit<TextareaAutosizeProps, "ref"> {
  icon?: React.ReactNode
}

// Input component
const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, icon, ...props }, ref) => {
    return (
      <div className="relative flex items-center">
        {icon && <div className="absolute left-5 z-10">{icon}</div>}
        <input
          type={type}
          className={cn(getBaseStyles(!!icon), "h-12 rounded-full", className)}
          ref={ref}
          {...props}
        />
      </div>
    )
  }
)

Input.displayName = "Input"

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, icon, minRows = 3, maxRows = 10, ...props }, ref) => {
    return (
      <div className="relative flex items-start">
        {icon && <div className="absolute left-5 top-3 z-10">{icon}</div>}
        <TextareaAutosize
          className={cn(getBaseStyles(!!icon), "resize-none rounded-2xl", className)}
          ref={ref}
          minRows={minRows}
          maxRows={maxRows}
          {...props}
        />
      </div>
    )
  }
)

Textarea.displayName = "Textarea"

export { Input, Textarea }
