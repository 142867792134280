import { v4 as uuidv4 } from "uuid"

const ANONYMOUS_ID_KEY = "chatbcc_anon_id"
const COOKIE_MAX_AGE = 365 * 24 * 60 * 60 * 1000 // 1 year in milliseconds

// Cookie helper functions
const setCookie = (value: string): void => {
  const expires = new Date(Date.now() + COOKIE_MAX_AGE).toUTCString()
  document.cookie = `${ANONYMOUS_ID_KEY}=${value}; expires=${expires}; path=/; SameSite=Strict`
}

const getCookie = (): string | null => {
  const match = document.cookie.match(new RegExp(`(^| )${ANONYMOUS_ID_KEY}=([^;]+)`))
  return match ? match[2] : null
}

const clearCookie = (): void => {
  document.cookie = `${ANONYMOUS_ID_KEY}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`
}

export const getAnonymousId = async (): Promise<string> => {
  // Try localStorage first
  let anonymousId = localStorage.getItem(ANONYMOUS_ID_KEY)

  if (!anonymousId) {
    // Try cookie next
    anonymousId = getCookie()
  }

  if (!anonymousId) {
    // Generate new ID if none found
    anonymousId = uuidv4()

    // Save to all storage mechanisms
    try {
      localStorage.setItem(ANONYMOUS_ID_KEY, anonymousId)
    } catch (error) {
      console.warn("Failed to save to localStorage:", error)
    }

    setCookie(anonymousId)
  } else {
    // Ensure ID exists in all storage mechanisms
    try {
      localStorage.setItem(ANONYMOUS_ID_KEY, anonymousId)
    } catch (error) {
      console.warn("Failed to save to localStorage:", error)
    }

    setCookie(anonymousId)
  }

  return anonymousId
}

export const clearAnonymousId = async (): Promise<void> => {
  localStorage.removeItem(ANONYMOUS_ID_KEY)
  clearCookie()
}
