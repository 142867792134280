import { useQuery } from "@apollo/client"
import { useParams } from "react-router-dom"
import invariant from "tiny-invariant"
import { gql } from "~/__generated__"
import { adminRoomPath } from "~/common/paths"
import { GraphqlError } from "~/ui/errors"
import { PageHeader } from "~/ui/page-header"
import { EditRoomForm } from "./edit-room-form"

export const AdminEditRoomScreen = () => {
  const { id } = useParams<{ id: string }>()
  invariant(id, "Expected room ID")

  const { data, loading, error } = useQuery(GET_ROOM_EDIT_QUERY, {
    variables: { id },
  })

  if (loading && !data) {
    return null
  }

  if (error || !data) {
    return <GraphqlError error={error} />
  }

  return (
    <div data-testid="admin-edit-room-screen">
      <PageHeader title="Edit Room" backPath={adminRoomPath({ id })} />
      {!!data.room && <EditRoomForm room={data.room} />}
    </div>
  )
}

const GET_ROOM_EDIT_QUERY = gql(/* GraphQL */ `
  query AdminGetRoomEdit($id: ID!) {
    room(id: $id) {
      ...BaseRoom
    }
  }
`)
