import { ShareScreenBase } from "./share-screen-base"
import { getDeeplinkUrl } from "~/common/platform"
import { getMetaContent } from "~/common/get-meta-content"
import { VerificationLink } from "~/__generated__/graphql"
import appPreviewImage from "~/images/landing/app-preview-image"
import { useDocumentTitleDescription } from "~/common/use-document-title"

export const InviteScreen = (): JSX.Element => {
  const appEnvironment = getMetaContent("APP_ENVIRONMENT")
  const deeplinkUrl = getDeeplinkUrl(appEnvironment, "home")
  useDocumentTitleDescription(
    "ChatBCC: You're invited to skip the waitlist",
    "Amazing people having real conversations about topics you love. Follow your favorites or start your own."
  )

  return (
    <ShareScreenBase
      verificationLinkType={VerificationLink.InviteFriend}
      deeplinkUrl={deeplinkUrl}
      renderContent={() => (
        <img src={appPreviewImage.src} alt="App Preview" className="w-[346px] md:w-[640px]" />
      )}
    />
  )
}

export default InviteScreen
