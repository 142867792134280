import { zodResolver } from "@hookform/resolvers/zod"
import { Plus, Trash2 } from "lucide-react"
import { useForm, useFieldArray } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import * as z from "zod"
import { gql } from "~/__generated__"
import { BaseRoomFragment, RoomStatus } from "~/__generated__/graphql"
import { BadgeImage } from "~/admin/admin-room-screen/admin-room-screen"
import { adminRoomPath } from "~/common/paths"
import { useFormErrors } from "~/common/use-form-errors"
import { useSafeMutation } from "~/common/use-safe-mutation"
import { CheckboxField } from "~/fields/checkbox-field"
import { FileUploadField } from "~/fields/file-upload-field"
import { SelectField } from "~/fields/select-field"
import { TextField } from "~/fields/text-field"
import { TextareaField } from "~/fields/textarea-field"
import { AppText } from "~/ui/app-text"
import { Button } from "~/ui/button"
import { FieldGroup } from "~/ui/field-group"
import { Form } from "~/ui/form"
import { FormActions } from "~/ui/form-actions"
import { Label } from "~/ui/label"
import { useToast } from "~/ui/use-toast"

const formSchema = z.object({
  name: z.string().min(1, { message: "Name is required" }),
  description: z.string().optional(),
  roomStatus: z.nativeEnum(RoomStatus),
  sharing: z.boolean().optional(),
  roomHandle: z.string().optional(),
  webUrl: z.string().url({ message: "Please enter a valid URL" }).optional().or(z.literal("")),
  roomWaitlistCodeName: z.string().optional(),
  vipName: z.string().optional(),
  vipColor: z.string().optional(),
  vipEnabled: z.boolean().optional(),
  vipBadge: z.string().optional(),
  postsEnabled: z.boolean().optional(),
  roomMessagesPreview: z.array(
    z.object({
      id: z.string().optional(),
    })
  ),
})

type FormValues = z.infer<typeof formSchema>

export const ROOM_STATUS_OPTIONS = [
  { value: RoomStatus.Draft, label: "Draft" },
  { value: RoomStatus.Published, label: "Published" },
  { value: RoomStatus.Unpublished, label: "Unpublished" },
  { value: RoomStatus.PendingApproval, label: "Pending Approval" },
  { value: RoomStatus.Archived, label: "Archived" },
]

export const getRoomStatusName = (status: RoomStatus) => {
  return ROOM_STATUS_OPTIONS.find((option) => option.value === status)?.label || "Unknown"
}

export const EditRoomForm = ({ room }: { room: BaseRoomFragment }) => {
  const navigate = useNavigate()
  const { toast } = useToast()
  const [mutate, result] = useSafeMutation(ROOM_UPDATE_MUTATION)

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: room.name,
      description: room.description || "",
      roomStatus: room.roomStatus,
      sharing: room.sharing,
      webUrl: room.webUrl || "",
      roomHandle: room.roomHandle || "",
      vipBadge: "",
      vipName: room.vipName || "",
      vipColor: room.vipColor || "",
      vipEnabled: room.vipEnabled,
      postsEnabled: room.postsEnabled,
      roomMessagesPreview: room.roomMessagesPreview || [],
    },
  })

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "roomMessagesPreview",
  })

  useFormErrors(form.setError, result)
  const vipColor = form.watch("vipColor")
  const vipBadge = form.watch("vipBadge")

  const deleteVipBadge = async () => {
    const confirmDelete = window.confirm("Are you sure you want to remove the VIP badge?")
    if (!confirmDelete) return

    const result = await mutate({
      variables: {
        input: {
          id: room.id,
          roomInput: {
            vipBadge: null,
          },
        },
      },
    })

    if (result.errors) {
      toast({
        title: "VIP badge was not removed, please try again",
        variant: "destructive",
      })
      return
    }

    toast({
      title: "VIP badge removed successfully",
      variant: "default",
    })

    form.setValue("vipBadge", "")
  }

  const onSubmit = async (values: FormValues) => {
    if (values.roomHandle !== (room.roomHandle || "")) {
      const confirmation = window.confirm(
        "Changing the room handle will break any links that use the previous handle. Are you sure?"
      )
      if (!confirmation) return
    }

    // Extract message IDs from roomMessagesPreview for submission
    const messagesPreviewIds = values.roomMessagesPreview
      .filter((msg) => msg.id) // Only include messages with IDs
      .map((msg) => msg.id as string)

    const result = await mutate({
      variables: {
        input: {
          id: room.id,
          roomInput: {
            name: values.name,
            description: values.description || null,
            roomStatus: values.roomStatus,
            sharing: values.sharing,
            webUrl: values.webUrl || null,
            roomHandle: values.roomHandle || null,
            vipName: values.vipName || null,
            vipColor: values.vipColor || null,
            vipBadge: values.vipBadge || undefined,
            vipEnabled: values.vipEnabled,
            postsEnabled: values.postsEnabled,
            messagesPreviewIds: messagesPreviewIds.length > 0 ? messagesPreviewIds : null,
          },
        },
      },
    })

    if (result.errors) {
      toast({
        title: "Room update failed",
        variant: "destructive",
      })
      return
    }

    toast({
      title: "Room updated successfully",
      variant: "default",
    })

    navigate(adminRoomPath({ id: room.id }))
  }

  return (
    <div className="max-w-lg">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-10">
          <FieldGroup>
            <AppText variant="h2">Room Details</AppText>

            <TextField
              control={form.control}
              name="name"
              label="Name"
              required={true}
              placeholder="Enter room name"
            />

            <SelectField
              control={form.control}
              name="roomStatus"
              label="Room Status"
              options={ROOM_STATUS_OPTIONS}
              placeholder="Select a status"
              search={false}
            />

            <TextareaField
              control={form.control}
              name="description"
              label="Description"
              placeholder="Enter a description"
            />

            <TextField
              control={form.control}
              name="webUrl"
              label="Web URL"
              placeholder="https://example.com"
            />

            <TextField
              control={form.control}
              name="roomHandle"
              label="Room Handle"
              placeholder="Enter room handle"
            />

            <CheckboxField control={form.control} name="sharing" label="Enable sharing" />
            <CheckboxField control={form.control} name="postsEnabled" label="Enable posts" />
          </FieldGroup>

          <FieldGroup>
            <AppText variant="h2" className="mb-4">
              Messages Preview
            </AppText>

            <div className="space-y-4">
              {fields.map((field, index) => (
                <div key={field.id} className="space-y-3 rounded-md border border-gray-200 p-4">
                  <div className="flex justify-between">
                    <Label>Message {index + 1}</Label>
                    <button
                      type="button"
                      onClick={() => remove(index)}
                      className="text-xs inline-flex items-center text-red-500 hover:text-red-700"
                    >
                      <Trash2 className="mr-1 h-3.5 w-3.5" />
                      Remove
                    </button>
                  </div>
                  <TextField
                    control={form.control}
                    name={`roomMessagesPreview.${index}.id`}
                    label="Message ID"
                    placeholder="Enter message ID"
                  />
                </div>
              ))}

              <Button
                type="button"
                variant="outline"
                size="sm"
                onClick={() => append({ id: "" })}
                className="flex items-center"
              >
                <Plus className="mr-1 h-3.5 w-3.5" />
                Add Message
              </Button>
            </div>
          </FieldGroup>

          <FieldGroup>
            <AppText variant="h2" className="mb-4">
              VIP Settings
            </AppText>

            <CheckboxField control={form.control} name="vipEnabled" label="VIP Enabled" />

            <TextField
              control={form.control}
              name="vipName"
              label="VIP name"
              placeholder="Enter VIP name"
            />

            <TextField
              control={form.control}
              name="vipColor"
              label="VIP Color (hex)"
              placeholder="#RRGGBB"
            />
            {vipColor && (
              <div
                className="mt-2 h-6 w-6 rounded border border-gray-300"
                style={{ backgroundColor: vipColor }}
              />
            )}

            <div className="space-y-4">
              <Label>VIP Badge</Label>
              <FileUploadField
                name="vipBadge"
                control={form.control}
                buttonText={room.vipBadgeUrl ? "Replace Badge" : "Upload Badge"}
                accept="image/svg+xml,image/jpeg,image/png,image/gif,image/webp"
              />

              {vipBadge && (
                <div className="text-sm text-green-600">
                  <svg
                    className="mr-1 inline-block h-4 w-4"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    ></path>
                  </svg>
                  Badge ready to be saved
                </div>
              )}

              <p className="text-xs text-gray-500">
                Note: Upload the badge first, then click "Save Changes" to apply it to the room.
              </p>

              {room.vipBadgeUrl && (
                <div className="flex items-center">
                  <BadgeImage url={room.vipBadgeUrl} size="large" />
                  <div className="flex flex-row gap-4">
                    <a
                      href={room.vipBadgeUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-sm ml-3 text-blue-500 hover:underline"
                    >
                      View full size
                    </a>
                    <button
                      onClick={deleteVipBadge}
                      disabled={form.formState.isSubmitting}
                      className="text-xs inline-flex items-center text-red-500 hover:text-red-700"
                      title="Delete badge"
                      type="button"
                    >
                      <Trash2 className="mr-1 h-3.5 w-3.5" />
                      Remove
                    </button>
                  </div>
                </div>
              )}
            </div>
          </FieldGroup>

          <FormActions>
            <div className="flex justify-between">
              <Button
                type="button"
                variant="outline"
                onClick={() => navigate(adminRoomPath({ id: room.id }))}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                disabled={form.formState.isSubmitting || !form.formState.isDirty}
              >
                Save Changes
              </Button>
            </div>
          </FormActions>
        </form>
      </Form>
    </div>
  )
}

const ROOM_UPDATE_MUTATION = gql(`
  mutation AdminRoomUpdate($input: AdminRoomUpdateInput!) {
    adminRoomUpdate(input: $input) {
      room {
        ...BaseRoom
      }
    }
  }
`)
