import CryptoJS from "crypto-js"
import invariant from "tiny-invariant"

/* eslint-disable */
// Source: https://stackoverflow.com/a/39112502

function readChunked(
  file: File,
  chunkCallback: (blob: string | ArrayBuffer, off: number, total: number) => void,
  endCallback: (err: unknown) => void
) {
  var fileSize = file.size
  var chunkSize = 10 * 1024 * 1024 // 4MB
  var offset = 0

  var reader = new FileReader()
  reader.onload = function () {
    if (reader.error) {
      endCallback(reader.error || {})
      return
    }
    invariant(reader.result, "Expected reader.result")
    // @ts-expect-error
    offset += reader.result.length
    chunkCallback(reader.result, offset, fileSize)
    if (offset >= fileSize) {
      endCallback(null)
      return
    }
    readNext()
  }

  reader.onerror = function (err) {
    endCallback(err || {})
  }

  function readNext() {
    var fileSlice = file.slice(offset, offset + chunkSize)
    reader.readAsBinaryString(fileSlice)
  }
  readNext()
}

function getMD5(blob: File, cbProgress: (progress: number) => void) {
  return new Promise((resolve, reject) => {
    var md5 = CryptoJS.algo.MD5.create()
    readChunked(
      blob,
      (chunk: string | ArrayBuffer, offs: number, total: number) => {
        // @ts-expect-error
        md5.update(CryptoJS.enc.Latin1.parse(chunk))
        if (cbProgress) {
          cbProgress(offs / total)
        }
      },
      (err: unknown) => {
        if (err) {
          reject(err)
        } else {
          // TODO: Handle errors
          var hash = md5.finalize()
          var hashHex = hash.toString(CryptoJS.enc.Hex)
          resolve(hashHex)
        }
      }
    )
  })
}

function hexToBase64(hexstring: string) {
  const match = hexstring.match(/\w{2}/g)
  invariant(match, "Expected match")

  return btoa(
    match
      .map((a) => {
        return String.fromCharCode(parseInt(a, 16))
      })
      .join("")
  )
}

export const fileChecksum = async (file: File, onProgress: (progress: number) => void) => {
  const md5 = await getMD5(file, onProgress)
  // @ts-ignore
  const checksum = hexToBase64(md5)
  return checksum
}
