import { cva, VariantProps } from "class-variance-authority"

export const textVariant = {
  "display": "font-md-nichrome font-bold text-44 tracking-3 uppercase",
  "h1": "font-md-nichrome font-bold text-32 tracking-3 uppercase",
  "h2": "font-md-nichrome font-bold text-24 tracking-3",
  "h3": "font-md-nichrome font-bold text-20 tracking-3",
  "h4": "font-sans font-medium text-24 tracking-n2",
  "body1": "font-sans text-18 tracking-n2",
  "body2": "font-sans text-15 tracking-n1-5",
  "body2-medium": "font-sans font-medium text-15 tracking-n1-5",
  "body2-tight": "font-sans text-15 tracking-tight",
  "body3": "font-sans text-13 tracking-n1",
  "body3-medium": "font-sans font-medium text-13 tracking-n1",
  "body3-tight": "font-sans text-13 tracking-tight",
  "button": "uppercase font-md-nichrome font-medium text-15 leading-[32px] tracking-4",
  "hairline": "uppercase font-md-nichrome font-medium text-14 leading-[18px] tracking-3", // Bumped leading from 16 to 18 in to prevent emoji cutoff
  "caption": "font-sans text-12 tracking-n2",
  "caption-medium": "font-sans text-12 font-medium tracking-n2",
  "tiny": "font-sans text-10 tracking-n2",
}

export type AppTextVariant = keyof typeof textVariant

export const textVariants = cva("text-base", {
  variants: {
    variant: textVariant,
  },
  defaultVariants: {
    variant: "body1",
  },
})

export interface AppTextProps extends VariantProps<typeof textVariants> {
  className?: string
  children: React.ReactNode
}

export const AppText = ({ variant, className, children }: AppTextProps) => {
  return <div className={textVariants({ variant, className })}>{children}</div>
}
